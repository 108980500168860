import React, { useEffect } from 'react';

import link from '../../../assets/ui/link.svg';

function Description({ sector, category, index, description, descriptionRef }) {
  useEffect(() => {
    const div = document.getElementById(`description-${category.id}`);
    if (div) {
      if (
        description && description[index]?.sectorId === sector.level[0].id
        && description[index]?.id === category.id
        && div.style.maxHeight === '0px'
      ) {
        const animation = div.animate(
          [
            { maxHeight: '0px', overflow: 'hidden' },
            { maxHeight: `${div.scrollHeight}px`, overflow: 'hidden' },
          ],
          {
            duration: 350,
            fill: 'forwards',
            easing: 'ease',
          },
        );

        animation.onfinish = () => {
          div.style.maxHeight = '100%';
        };
      } else if (
        // description[index]?.sectorId !== sector.level[0].id
        description[index]?.id !== category.id
        && div.style.maxHeight === '100%'
      ) {
        const animation = div.animate(
          [
            { maxHeight: `${div.scrollHeight}px`, overflow: 'hidden' },
            { maxHeight: '0px', overflow: 'hidden' },
          ],
          {
            duration: 350,
            fill: 'forwards',
            easing: 'ease',
          },
        );

        animation.onfinish = () => {
          div.style.maxHeight = '0px';
        };
      } else if (description[index]?.sectorId !== sector.level[0].id) {
        div.style.maxHeight = '0px';
      }
    }
  }, [description, index, sector, category]);
  return (
    <div ref={descriptionRef} id={`description-${category.id}`} className={`tw-overflow-hidden ${description && description[index]?.sectorId === sector.level[0].id && description[index]?.id === category.id ? '' : 'tw-max-h-0'}`}>
      <div className="sector-description-section tw-mt-6 pb-0">
        <div className="md:tw-flex tw-mt-3.5">
          {category.description ? (
            <div className="tw-text-sm tw-text-greyDark md:tw-mr-6">
              {category.description}
            </div>
          ) : ('')}
          {category.resources && category.resources.length > 0 ? (
            <div className="tw-content-center">
              <div className="sources-link-container tw-mt-6 md:tw-mt-0">
                {category.resources && category.resources.map((resource, index) => (
                  <div className="sources-link tw-flex" key={index}>
                    <a key={index} href={resource.url} className={`tw-w-full tw-flex ${index + 1 < description.resources?.length ? 'tw-mb-4' : ''} md:tw-mb-0 ${index > 0 ? 'md:tw-ml-6' : ''}`} target="blank">
                      <img src={link} alt="File lines" />
                      <div className="font-14 fw-500 tw-ml-1.5 tw-text-primaryNewBlue tw-decoration-primaryNewBlue tw-underline">
                        {resource.name}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ) : ('')}
        </div>
      </div>
    </div>
  );
}

export default Description;
