const getTabsQuestionsCount = async (get, response, profileType, partnerId, typologyId, locale, profileId) => {
  try {
    await get(`/profiles/${profileId}/tabs?pt=${profileType}&pi=${partnerId}&ti=${typologyId}&locale=${locale}`);

    if (response.status === 200) {
      const result = await response.json();

      return result;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default getTabsQuestionsCount;
