import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';

import getUserLocal from '../../../../../utils/getUserLocal';
import getQuestions from '../../../../../utils/getQuestions';
import TableOfContents from './TableOfContents/TableOfContents';
import ContentQuestionForm from '../../Modal/Forms/ContentQuestionForm';
import ContentQuestionsTypeCarbonModelForm from '../../Modal/Forms/ContentQuestionsTypeCarbonModelForm';
import Category from './Category';
import getVisibility from '../../../../../utils/getVisibility';

function QuestionContainer({ profile, partner, questionType, view, hasWriteAccess, isPublished, setIsPublished }) {
  const [categories, setCategories] = useState();
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [formContext, setFormContext] = useState({});

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });

  const getAnsweredQuestionsCount = (categories) => {
    let answeredQuestions = 0;
    categories.forEach((category) => {
      category.individualQuestions.forEach((question) => {
        if (question.answer) {
          answeredQuestions += 1;
        }
      });
    });
    return answeredQuestions;
  };

  const fetchData = async () => {
    try {
      const response = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), questionType, profile.typology_id, partner.id, hasWriteAccess);

      if (api.response.ok) {
        setCategories(response.categories);
        setIsPublished(response.is_published_tab);
        setSummary(response.table_contents);
        const answeredNormal = getAnsweredQuestionsCount(response.table_contents);
        setNumberOfAnsweredQuestions(answeredNormal);
        let totalQuestions = 0;
        response.categories.forEach((category) => {
          totalQuestions += category.category[0].questions.length;
        });
        setNumberOfQuestions(totalQuestions);
        const progressionBar = (answeredNormal * 100) / totalQuestions;
        setProgressionBar(parseInt(progressionBar, 10));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid container-lg mt-3 pb-5">
      <div className="row">
        <div className="col-12 col-md-4 px-md-3">
          <TableOfContents
            hasWriteAccess={hasWriteAccess}
            progressionBar={progressionBar}
            numberOfQuestions={numberOfQuestions}
            numberOfAnsweredQuestions={numberOfAnsweredQuestions}
            summary={summary}
            isPublished={isPublished}
          />
        </div>
        <div className="col-12 col-md-8 px-md-3">
          {categories && categories.map((category, index) => {
            const show = getVisibility(category, hasWriteAccess, isPublished);

            return (
              show
              && (
              <Category
                key={index}
                category={category.category[0]}
                profile={profile}
                isPublished={isPublished}
                partner={partner}
                view={view}
                hasWriteAccess={hasWriteAccess}
                setFormContext={setFormContext}
                fetchData={fetchData}
              />
              )
            );
          })}
        </div>
        {hasWriteAccess ? (
          <>
            <ContentQuestionForm formContext={formContext} />
            <ContentQuestionsTypeCarbonModelForm formContext={formContext} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default QuestionContainer;
