import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';

import pen from '../../../../assets/ui/pen.svg';

import ProfileStatusForm from '../Modal/Forms/ProfileStatusForm';
import ProfileTags from './ProfileTags';
import StatusListModal from './StatusListModal';
import StatusTemplate from './StatusTemplate';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import Features from '../../../../features.json';
import useModal from '../../../../hooks/useModal';

function Status({ partner, profile, hasWriteAccess }) {
  const [status, setStatus] = useState({
    id: profile.current_status_id,
    status_id: profile.status_id,
    name: profile.status_name,
    description: profile.status_description,
    color: profile.status_color,
  });
  const [profileStatusList, setProfileStatusList] = useState([]);
  const [formContext, setFormContext] = useState({});
  const { features } = useOutletContext();

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });
  const { t } = useTranslation();

  const { open } = useModal();

  const getProfileStatusList = async () => {
    await get(`/partner/${partner.id}/profile-status`);
    if (response.ok) {
      setProfileStatusList(response.data);
    }
  };

  useEffect(() => {
    try {
      getProfileStatusList();
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    setStatus({
      id: profile?.current_status_id,
      status_id: profile?.status_id,
      name: profile?.status_name,
      description: profile?.status_description,
      color: profile?.status_color,
    });
  }, [profile]);

  return (
    <>
      <div className="white-bg p-4 mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
        <div className="mb-3 tw-flex tw-justify-between">
          <h2 className="tw-text-greyOne tw-font-moskauGrotesk tw-text-base">
            {t('STATUS_SUPPLIER')}
          </h2>
          <div className="tw-flex tw-items-center tw-gap-x-2.5">
            {features.includes(Features.profileTags) && <ProfileTags partnerId={partner.id} profileId={profile.id} />}
            {hasWriteAccess ? (
              <button
                type="button"
                className="profile-modal-button border-0 bg-white"
                onClick={() => {
                  setFormContext({
                    action: status.id ? 'edit' : 'create',
                    modalTitle: t('EDIT_STATUS'),
                    profileId: profile.id,
                    partnerId: partner.id,
                    currentStatusId: status.id,
                    statusList: profileStatusList,
                    setStatus,
                    data: {
                      status: status.status_id,
                    },
                  });
                  open('profile-status-modal');
                }}
              >
                <div className="action-svg" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
              </button>
            ) : ('')}
          </div>

        </div>
        {status.id && status.color && status.description ? (
          <StatusTemplate
            title={status.name}
            description={status.description}
            color={status.color}
            backgroundColor={getHexColorWithOpacity(status.color, 0.1)}
          />
        ) : (
          <div className="cloudy-grey-color status-empty-state">
            <h4 className="fw-700 font-14">
              {t('NO_STATUS_SELECTED')}
            </h4>
            <p className="fw-400 font-14 mb-0">
              {t('NO_STATUS')}
            </p>
          </div>
        )}
        <button type="button" className="tw-text-greyFour font-14 d-block mt-4 bg-white border-0 text-decoration-underline" onClick={() => open('status-list-modal')}>
          {t('SHOW_ALL_STATUS')}
        </button>
      </div>
      <StatusListModal statusList={profileStatusList} />
      {hasWriteAccess ? (
        <ProfileStatusForm
          formContext={formContext}
        />
      ) : ('')}
    </>
  );
}

export default Status;
