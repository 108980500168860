/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Content from './Content';

function TableOfContents({ hasWriteAccess, progressionBar, numberOfQuestions, numberOfAnsweredQuestions, summary, isPublished }) {
  const { t } = useTranslation();

  return (
    <div className="summary bg-white black-color sticky-top tw-rounded tw-border tw-border-solid tw-border-greySix">
      <h3 className="summary-title tw-text-base">
        {t('TABLE_OF_CONTENTS_TITLE')}
      </h3>
      <div className="summary-second-question mt-3">
        {hasWriteAccess && (
          <div className="d-flex justify-content-between pb-3">
            <div className="summary-progression-bar-container position-relative align-self-center">
              <div className="summary-progression-bar position-absolute" />
              <div className="summary-progression-bar-fill position-absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 font-12">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
        )}
        {summary && summary.map((category, index) => (
          <Content
            key={index}
            category={category}
            hasWriteAccess={hasWriteAccess}
            isPublished={isPublished}
          />
        ))}
      </div>
    </div>
  );
}

export default TableOfContents;
