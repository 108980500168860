import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { useSearchParams } from 'react-router-dom';

import Big from './Templates/Big';
import Medium from './Templates/Medium';

import './Search.scss';

function Search({ partner, navbarTemplate }) {
  const [profiles, setProfiles] = useState([]);
  const [query, setQuery] = useState('');
  const [typingTimer, setTypingTimer] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API);

  const doneTypingInterval = 500;

  useEffect(() => {
    if (typingTimer) {
      clearTimeout(typingTimer);
      setTypingTimer(null);
    }
    if (query.length > 0) {
      searchParams.set('q', query);
      setSearchParams(searchParams);
      setTypingTimer(
        setTimeout(async () => {
          await get(`/partner/profiles/search?query=${query}&domain=${partner.domain}`);

          if (response.ok) {
            setProfiles(response.data);
            clearTimeout(typingTimer);
          }
        }, doneTypingInterval),
      );
    }
  }, [query]);

  const handleClick = () => {
    setQuery('');
  };

  useEffect(() => {
    if (searchParams.has('q')) {
      setQuery(searchParams.get('q'));
    }
  }, []);

  return navbarTemplate
    ? <Medium profiles={profiles} query={query} setQuery={setQuery} handleClick={handleClick} />
    : <Big profiles={profiles} query={query} setQuery={setQuery} handleClick={handleClick} />;
}

export default Search;
