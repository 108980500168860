import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import chevronDown from '../../../../assets/ui/chevron-down-grey.svg';
import categoryIcons from '../../../../utils/categoryIcons';

function IconSelector({ selectedIcon, setSelectedIcon }) {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event, icon) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      setSelectedIcon(icon);
      setShowDropdown(false);
    }
  };

  const selectIcon = (icon) => {
    setSelectedIcon(icon);
    setShowDropdown(false);
  };

  return (
    <div ref={dropdownRef}>
      <label className="tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-mt-9">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ICON')}
      </label>
      <div className="tw-relative">
        <button
          type="button"
          className=" w-h-11 tw-w-full tw-bg-white tw-text-left tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-greyHeavy tw-border tw-border-solid tw-border-greyLight focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-p-2 tw-flex tw-items-center tw-justify-between"
          onClick={toggleDropdown}
          aria-haspopup="listbox"
          aria-expanded={showDropdown}
        >
          <span className="tw-flex tw-items-center tw-grow">
            {selectedIcon && selectedIcon.icon ? (
              <img
                src={selectedIcon.icon}
                alt=""
                className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-mr-2"
              />
            ) : (
              <span
                className="tw-block tw-truncate tw-text-greyDark tw-font-moskauGrotesk tw-font-normal"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ICON_INFO')}
              </span>
            )}
          </span>
          <img
            src={chevronDown}
            alt="chevron down icon"
            className="tw-ml-4 tw-h-5 tw-w-5"
          />
        </button>
        {showDropdown && (
          <div
            className="tw-absolute tw-z-10 tw-w-full tw-max-h-72 tw-flex tw-flex-col tw-overflow-auto tw-rounded-md tw-bg-white tw-py-4 tw-pl-0 tw-shadow-customDropdown tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-text-sm tw-border tw-border-solid tw-border-greyLight"
            tabIndex="-1"
            role="listbox"
          >
            <p className="tw-text-sm tw-text-greyFive tw-font-moskauGrotesk tw-font-normal tw-pl-4 tw-mb-2">
              {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ICON')}s
            </p>
            <div className="tw-w-full tw-flex tw-flex-wrap tw-p-2">
              <div className=" tw-w-16 tw-h-16 tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-2 hover:tw-bg-secondaryBlueBg tw-rounded">
                <button
                  type="button"
                  tabIndex="0"
                  onClick={() => selectIcon(null)}
                  onKeyDown={handleKeyDown}
                  className=" tw-text-sm tw-font-normal tw-font-moskauGrotesk tw-text-greyFive tw-truncate tw-whitespace-normal tw-text-center tw-border-none tw-bg-white/0"
                >
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NO_ICON')}
                </button>
              </div>
              {categoryIcons.map((icon) => (
                <div
                  key={icon.value}
                  className="tw-w-16 tw-h-16 tw-flex tw-items-center tw-justify-center tw-p-2 hover:tw-bg-secondaryBlueBg tw-rounded tw-cursor-pointer tw-text-greyFive tw-font-moskauGroteskNormal hover:tw-text-greyTwo hover:tw-font-moskauGrotesk hover:tw-font-semibold"
                  role="option"
                  onClick={() => selectIcon(icon)}
                  onKeyDown={(event) => handleKeyDown(event, icon)}
                  tabIndex="0"
                  aria-selected={
                    selectedIcon && selectedIcon.value === icon.value
                  }
                >
                  <img
                    src={icon.icon}
                    alt=""
                    className="tw-h-5 tw-w-5 tw-flex-shrink-0"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IconSelector;
