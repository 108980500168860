import React from 'react';
import { useTranslation } from 'react-i18next';

import threeDots from '../../../../../assets/ui/three-dots.svg';

function RenderTagOptions({ tag, handleToggleMenu, openMenuTagId, handleEditTag, handleDeleteTag }) {
  const { t } = useTranslation();

  return (
    <div className="tw-relative">
      <button
        type="button"
        onClick={() => handleToggleMenu(tag.id)}
        className="tw-w-[26px] tw-h-[26px] tw-rounded-full tw-bg-greyEight tw-border-none tw-flex tw-items-center tw-justify-center tw-ml-3 tw-cursor-pointer"
      >
        <img src={threeDots} alt="edit tag" />
      </button>
      {openMenuTagId === tag.id && (
      <>
        <div className="tw-absolute tw-left-12 tw-top-0 tw-flex tw-flex-col tw-p-[3px] tw-rounded tw-shadow-tooltipShadow tw-bg-white">
          <button
            type="button"
            className="tw-text-greyThree tw-text-sm tw-font-medium tw-font-moskauGrotesk tw-bg-white hover:tw-bg-greyEight tw-border-none tw-text-left tw-px-[7px] tw-py-[2px] tw-rounded-sm tw-transition-transform tw-duration-150 tw-z-10"
            onClick={() => handleEditTag(tag)}
          >
            {t('EDIT_TAG_B')}
          </button>
          <button
            type="button"
            className="tw-text-greyThree tw-text-sm tw-font-medium tw-font-moskauGrotesk tw-mt-2 tw-bg-white hover:tw-bg-greyEight tw-border-none tw-text-left tw-px-[7px] tw-py-[2px] tw-rounded-sm tw-transition-transform tw-duration-150"
            onClick={() => handleDeleteTag(tag)}
          >
            {t('DELETE_TAG')}
          </button>
        </div>
        <div className="tw-absolute tw-left-[42px] tw-top-2 tw-w-3 tw-h-3 tw-rotate-45 tw-bg-white tw-shadow-tooltipShadow" />
      </>

      )}
    </div>
  );
}

export default RenderTagOptions;
