import appleIcon from '../assets/ui/foodIcons/apple-icon.svg';
import bananaIcon from '../assets/ui/foodIcons/banana-icon.svg';
import beanIcon from '../assets/ui/foodIcons/bean-icon.svg';
import beefIcon from '../assets/ui/foodIcons/beef-icon.svg';
import candyIcon from '../assets/ui/foodIcons/candy-icon.svg';
import carrotIcon from '../assets/ui/foodIcons/carrot-icon.svg';
import candyCaneIcon from '../assets/ui/foodIcons/candy-cane-icon.svg';
import chefHatIcon from '../assets/ui/foodIcons/chef-hat-icon.svg';
import cherryIcon from '../assets/ui/foodIcons/cherry-icon.svg';
import drumStick from '../assets/ui/foodIcons/drumstick-icon.svg';
import cookieIcon from '../assets/ui/foodIcons/cookie-icon.svg';
import croissantIcon from '../assets/ui/foodIcons/croissant-icon.svg';
import dessertIcon from '../assets/ui/foodIcons/dessert-icon.svg';
import donutIcon from '../assets/ui/foodIcons/donut-icon.svg';
import eggFriedIcon from '../assets/ui/foodIcons/egg-fried-icon.svg';
import grapeIcon from '../assets/ui/foodIcons/grape-icon.svg';
import hamIcon from '../assets/ui/foodIcons/ham-icon.svg';
import iceCreamIcon from '../assets/ui/foodIcons/ice-cream-icon.svg';
import iceCreamConeIcon from '../assets/ui/foodIcons/ice-cream-cone-icon.svg';
import lollipopIcon from '../assets/ui/foodIcons/lollipop-icon.svg';
import nutIcon from '../assets/ui/foodIcons/nut-icon.svg';
import pizzaIcon from '../assets/ui/foodIcons/pizza-icon.svg';
import popsicleIcon from '../assets/ui/foodIcons/popsicle-icon.svg';
import potIcon from '../assets/ui/foodIcons/pot-icon.svg';
import saladIcon from '../assets/ui/foodIcons/salad-icon.svg';
import sandwichIcon from '../assets/ui/foodIcons/sandwich-icon.svg';
import soupeIcon from '../assets/ui/foodIcons/soupe-icon.svg';
import utensilsIcon from '../assets/ui/foodIcons/utensils-icon.svg';
import beerIcon from '../assets/ui/foodIcons/beer-icon.svg';
import cakeIcon from '../assets/ui/foodIcons/cake-icon.svg';
import cakeSliceIcon from '../assets/ui/foodIcons/cake-slice-icon.svg';
import citrusIcon from '../assets/ui/foodIcons/citrus-icon.svg';
import cocktailIcon from '../assets/ui/foodIcons/cocktail-icon.svg';
import coffeeIcon from '../assets/ui/foodIcons/coffee-icon.svg';
import fishIcon from '../assets/ui/foodIcons/fish-icon.svg';
import fridgeIcon from '../assets/ui/foodIcons/fridge-icon.svg';
import hopIcon from '../assets/ui/foodIcons/hop-icon.svg';
import leafIcon from '../assets/ui/foodIcons/leaf-icon.svg';
import milkIcon from '../assets/ui/foodIcons/milk-icon.svg';
import platterIcon from '../assets/ui/foodIcons/platter-icon.svg';
import popcornIcon from '../assets/ui/foodIcons/popcorn-icon.svg';
import sodaIcon from '../assets/ui/foodIcons/soda-icon.svg';
import tracteurIcon from '../assets/ui/foodIcons/tracteur-icon.svg';
import veganIcon from '../assets/ui/foodIcons/vegan-icon.svg';
import wheatIcon from '../assets/ui/foodIcons/wheat-icon.svg';
import wineIcon from '../assets/ui/foodIcons/wine-icon.svg';
import storeIcon from '../assets/ui/foodIcons/store-icon.svg';
import babyIcon from '../assets/ui/foodIcons/baby-icon.svg';
import carIcon from '../assets/ui/foodIcons/car-icon.svg';
import dicesIcon from '../assets/ui/foodIcons/dices-icon.svg';
import dropletIcon from '../assets/ui/foodIcons/droplet-icon.svg';
import flowerIcon from '../assets/ui/foodIcons/flower-icon.svg';
import homeIcon from '../assets/ui/foodIcons/home-icon.svg';
import pillBottleIcon from '../assets/ui/foodIcons/pill-bottle-icon.svg';
import plugIcon from '../assets/ui/foodIcons/plug-icon.svg';
import spraycanIcon from '../assets/ui/foodIcons/spraycan-icon.svg';
import stickyNoteIcon from '../assets/ui/foodIcons/sticky-note-icon.svg';
import wrenchIcon from '../assets/ui/foodIcons/wrench-icon.svg';
import planeIcon from '../assets/ui/foodIcons/plane-icon.svg';
import buildingIcon from '../assets/ui/foodIcons/building-icon.svg';
import zapIcon from '../assets/ui/foodIcons/zap-icon.svg';
import gemIcon from '../assets/ui/foodIcons/gem-icon.svg';
import routerIcon from '../assets/ui/foodIcons/router-icon.svg';
import rocketIcon from '../assets/ui/foodIcons/rocket-icon.svg';

const categoryIcons = [
  { label: 'Pomme', value: 'apple-icon', icon: appleIcon },
  { label: 'Banane', value: 'banana-icon', icon: bananaIcon },
  { label: 'Haricot', value: 'bean-icon', icon: beanIcon },
  { label: 'Boeuf', value: 'beef-icon', icon: beefIcon },
  { label: 'Bonbon', value: 'candy-icon', icon: candyIcon },
  { label: 'Carotte', value: 'carrot-icon', icon: carrotIcon },
  { label: 'Bonbon', value: 'candy-cane-icon', icon: candyCaneIcon },
  { label: 'Chef', value: 'chef-hat-icon', icon: chefHatIcon },
  { label: 'Cerises', value: 'cherry-icon', icon: cherryIcon },
  { label: 'Pilon', value: 'drumStick-icon', icon: drumStick },
  { label: 'Cookie', value: 'cookie-icon', icon: cookieIcon },
  { label: 'Croissant', value: 'croissant-icon', icon: croissantIcon },
  { label: 'Dessert', value: 'dessert-icon', icon: dessertIcon },
  { label: 'Donut', value: 'donut-icon', icon: donutIcon },
  { label: 'Oeufs', value: 'egg-fried-icon', icon: eggFriedIcon },
  { label: 'Raisin', value: 'grape-icon', icon: grapeIcon },
  { label: 'Jambon', value: 'ham-icon', icon: hamIcon },
  { label: 'Glace', value: 'ice-cream-icon', icon: iceCreamIcon },
  { label: 'Glace', value: 'ice-cream-cone-icon', icon: iceCreamConeIcon },
  { label: 'Sucette', value: 'lollipop-icon', icon: lollipopIcon },
  { label: 'Noisette', value: 'nut-icon', icon: nutIcon },
  { label: 'Pizza', value: 'pizza-icon', icon: pizzaIcon },
  { label: 'Glace', value: 'popsicle-icon', icon: popsicleIcon },
  { label: 'Marmite', value: 'pot-icon', icon: potIcon },
  { label: 'Salade', value: 'salad-icon', icon: saladIcon },
  { label: 'Sandwich', value: 'sandwich-icon', icon: sandwichIcon },
  { label: 'Soupe', value: 'soupe-icon', icon: soupeIcon },
  { label: 'Bière', value: 'beer-icon', icon: beerIcon },
  { label: 'Gâteau', value: 'cake-icon', icon: cakeIcon },
  { label: 'Gâteau', value: 'cake-slice-icon', icon: cakeSliceIcon },
  { label: 'Citron', value: 'citrus-icon', icon: citrusIcon },
  { label: 'Cocktail', value: 'cocktail-icon', icon: cocktailIcon },
  { label: 'Café', value: 'coffee-icon', icon: coffeeIcon },
  { label: 'Poisson', value: 'fish-icon', icon: fishIcon },
  { label: 'Réfrigirateur', value: 'fridge-icon', icon: fridgeIcon },
  { label: 'Houblon', value: 'hop-icon', icon: hopIcon },
  { label: 'Feuille', value: 'leaf-icon', icon: leafIcon },
  { label: 'Lait', value: 'milk-icon', icon: milkIcon },
  { label: 'Plateau', value: 'platter-icon', icon: platterIcon },
  { label: 'Popcorn', value: 'popcorn-icon', icon: popcornIcon },
  { label: 'Soda', value: 'soda-icon', icon: sodaIcon },
  { label: 'Tracteur', value: 'tracteur-icon', icon: tracteurIcon },
  { label: 'Vegan', value: 'vegan-icon', icon: veganIcon },
  { label: 'Blé', value: 'wheat-icon', icon: wheatIcon },
  { label: 'Vin', value: 'wine-icon', icon: wineIcon },
  { label: 'Couverts', value: 'utensils-icon', icon: utensilsIcon },
  { label: 'Bébé', value: 'baby-icon', icon: babyIcon },
  { label: 'Voiture', value: 'car-icon', icon: carIcon },
  { label: 'Magasin', value: 'store-icon', icon: storeIcon },
  { label: 'Dés', value: 'dices-icon', icon: dicesIcon },
  { label: 'Goutte', value: 'droplet-icon', icon: dropletIcon },
  { label: 'Fleur', value: 'flower-icon', icon: flowerIcon },
  { label: 'Maison', value: 'home-icon', icon: homeIcon },
  { label: 'Flacon', value: 'pill-bottle-icon', icon: pillBottleIcon },
  { label: 'Prise', value: 'plug-icon', icon: plugIcon },
  { label: 'Aérosol', value: 'spraycan-icon', icon: spraycanIcon },
  { label: 'Note', value: 'sticky-note-icon', icon: stickyNoteIcon },
  { label: 'Clé', value: 'wrench-icon', icon: wrenchIcon },
  { label: 'Avion', value: 'plane-icon', icon: planeIcon },
  { label: 'Immeuble', value: 'building-icon', icon: buildingIcon },
  { label: 'Zap', value: 'zap-icon', icon: zapIcon },
  { label: 'Gem', value: 'gem-icon', icon: gemIcon },
  { label: 'Router', value: 'router-icon', icon: routerIcon },
  { label: 'Rocket', value: 'rocket-icon', icon: rocketIcon },
];

export default categoryIcons;
