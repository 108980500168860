import React from 'react';

function Tooltip({ text, customClass }) {
  return (
    <div className={`tw-hidden group-hover:tw-block tw-absolute tw-left-14 tw-bg-greyTwo tw-text-white tw-text-sm tw-py-2.5 tw-px-3 tw-rounded tw-z-50 tw-transition-all tw-duration-50 tw-text-nowrap ${customClass}`}>
      <div className=" tw-absolute -tw-left-[4px] tw-bottom-[14px] tw-w-3 tw-h-3 tw-bg-greyTwo tw-rotate-45 tw-rounded-sm" />
      {text}
    </div>
  );
}

export default Tooltip;
