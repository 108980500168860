/**
 * Finds the parent category of a given category within a list of sectors
 *
 * @param {Object} category - The category for which to find the parent
 * @param {Array} sectors - The list of sectors to search for the parent category
 * @returns {Object|null} The parent category if found, or null if not found or if sectors is not provided
 */
function findParentCategory(category, sectors) {
  if (!sectors) return null;

  return sectors.reduce((foundParent, sector) => {
    if (foundParent) return foundParent;

    const parentCategory = sector.level.reduce((foundInLevel, level) => {
      if (foundInLevel) return foundInLevel;

      const categoryFound = level.categories.find((cat) => cat.sub_categories && cat.sub_categories.find((subCat) => subCat.id === category.id));
      return categoryFound ? { ...categoryFound, sectorId: sector.level[0].id } : null;
    }, null);

    return parentCategory;
  }, null);
}

export default findParentCategory;
