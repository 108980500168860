import { toast } from 'react-toastify';

const handleResponse = (response) => {
  if (response.status === 200) {

    // TODO

  } else if (response.status === 201) {

    // TODO

  } else if (response.status >= 400 && response.status < 500) {
    if (response.status === 403) {
      toast('Action non autorisée');
    } else {
      toast('Une erreur interne est survenue');
    }
  } else if (response.status >= 500) {
    if (response.status === 503) {
      toast('Service indisponible');
    } else {
      toast('Une erreur interne est survenue');
    }
  }
};

export default handleResponse;
