import React, { useEffect, useRef, useState } from 'react';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../tailwind.config';

import plusCircle from '../../../assets/ui/plus-circle.svg';
import pencilSimple from '../../../assets/ui/pencil-simple.svg';
import check from '../../../assets/ui/check-categegory.svg';
import trash from '../../../assets/ui/trash-bin.svg';

import CategoryCard from './CategoryCard';
import CategoryForm from './Form/CategoryForm';
import ToastMessage from './ToastMessage';
import handleResponse from '../../../utils/handleResponse';
import DeleteConfirmationModal from './Modal/DeleteConfirmationModal';

import 'react-toastify/dist/ReactToastify.css';

function Category() {
  const [levelInfo, setLevelInfo] = useState();
  const [action, setAction] = useState();
  const [categoriesLevel, setCategoriesLevel] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [deleteModalFromCategory, setDeleteModalFromCategory] = useState(false);
  const [hasCategories, setHasCategories] = useState(false);
  const [editingLevelId, setEditingLevelId] = useState(null);

  const levelInput = useRef();
  const deleteModalRef = useRef(null);
  const backgroundDeleteModalRef = useRef(null);

  const { partner, session } = useOutletContext();

  const { t } = useTranslation();

  const api = useFetch(
    `${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}`,
    {
      cachePolicy: 'no-cache',
      credentials: 'include',
    },
  );

  const getCategories = async () => {
    try {
      const response = await api.get('custom-categories');
      if (api.response.ok) {
        setCategoriesLevel(response);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      handleResponse(api.response);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const toggleFormVisibility = (action, levelCheck) => {
    if (action === 'new' && levelCheck) {
      setAction('new');
    } else if (action === 'new') {
      setLevelInfo({ levelId: null, position: 0 });
      setAction('new');
    } else {
      setAction('edit');
    }
    setShowForm(!showForm);
  };

  const handleLevelEditButton = async (level) => {
    if (editingLevelId === (level?.id || 'new')) {
      try {
        if (inputValue.trim()) {
          if (!level || (level.position === 0 && !level.id)) {
            // Créer nouveau niveau 0
            await api.post('category-level', {
              name: inputValue,
              user_id: session.id,
              level_position: 0,
            });
          } else {
            await api.put(`category-level/${level.id}`, {
              name: inputValue,
              user_id: session.id,
            });
          }
          if (api.response.ok) {
            toast(
              <ToastMessage text={t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.LEVEL.UPDATE')} />,
              { style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg } },
            );
            getCategories();
          }
        }
        setEditingLevelId(null);
      } catch (error) {
        handleResponse(api.response);
        throw new Error(error);
      }
    } else {
      setEditingLevelId(level?.id || 'new');
      setInputValue(level?.name ?? '');
    }
  };

  const handleLevelDeleteButton = async (level) => {
    try {
      await api.delete(`category-level/${level.id}`);
      if (api.response.ok) {
        toast(
          <ToastMessage
            text={t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.LEVEL.DELETE')}
          />,
          {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          },
        );

        getCategories();
      }
    } catch (error) {
      handleResponse(api.response);
      throw new Error(error);
    }
  };

  const toggleModal = async (shouldDelete = false) => {
    document.getElementById('delete-modal').classList.toggle('tw-hidden');
    if (shouldDelete && deleteInfo !== null) {
      try {
        await api.delete(`custom-categories/${deleteInfo.id}`);
        if (api.response.ok) {
          const { categoryToDeleteLevelInfos } = deleteInfo;

          if (deleteModalFromCategory) {
            setDeleteModalFromCategory(false);
            setShowForm(false);
          }

          // Supprimer le niveau supérieur si c'est la dernière catégorie et que le niveau supérieur est vide
          if (categoryToDeleteLevelInfos) {
            const levelCategories = categoryToDeleteLevelInfos.categories;
            if (levelCategories?.length === 1 && levelCategories[0].id === deleteInfo.id) {
              const upperLevelIndex = categoriesLevel.findIndex(
                (lvl) => lvl.level[0].position === categoryToDeleteLevelInfos.position + 1,
              );
              if (upperLevelIndex !== -1) {
                const upperLevel = categoriesLevel[upperLevelIndex].level[0];
                if (!upperLevel.categories || upperLevel.categories.length === 0) {
                  await api.delete(`category-level/${upperLevel.id}`);
                  if (api.response.ok) {
                    toast(
                      <ToastMessage text={t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.LEVEL.DELETE')} />,
                      { style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg } },
                    );
                  }
                }
              }
            }
          }

          toast(
            <ToastMessage text={<>{t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.DELETE.PART_A')}<span className="tw-font-moskauGrotesk tw-font-bold">{deleteInfo.name}</span>{t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.DELETE.PART_B')}</>} />,
            { style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg } },
          );

          await getCategories();
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        handleResponse(api.response);
      }
      setDeleteInfo(null);
    }
  };

  const handleNewPartnerLevel = async () => {
    try {
      await api.post('category-level', {
        user_id: session.id,
        level_position: categoriesLevel[0] ? categoriesLevel.length : null,
      });
      if (api.response.ok) {
        toast(
          <ToastMessage
            text={t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.LEVEL.CREATE')}
          />,
          {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          },
        );
        getCategories();
      }
    } catch (error) {
      throw error;
    } finally {
      handleResponse(api.response);
    }
  };

  useEffect(() => {
    const lastLevelCategory = categoriesLevel[0]?.level[0]?.categories;
    setHasCategories(lastLevelCategory?.length > 0);
  }, [categoriesLevel]);

  return (
    <>
      <div className="tw-col-span-full md:tw-col-span-4 lg:tw-col-span-5 md:tw:col-start-3 lg:tw-col-start-2 tw-p-[20px] 2xl:tw-p-12 tw-bg-whiteGrey tw-min-h-screen">
        <h2 className="tw-text-xl">
          {t('PARTNER_DASHBOARD.CATEGORIES.TITLE')}
        </h2>
        <p className="tw-text-base tw-text-greyDark">
          {t('PARTNER_DASHBOARD.CATEGORIES.SUBTITLE')}
        </p>
        {hasCategories ? (
          <button
            type="button"
            className="tw-border-0 tw-px-0 tw-content-center tw-flex tw-bg-whiteGrey tw-mt-11 -tw-left-4 tw-relative"
            onClick={() => handleNewPartnerLevel()}
          >
            <img
              src={plusCircle}
              alt="Plus circle"
              className="tw-self-center"
            />
            <span className="tw-ml-1 tw-self-center tw-text-sm tw-text-primaryNewBlue">
              {t('PARTNER_DASHBOARD.CATEGORIES.CTA_LEVEL')}
            </span>
          </button>
        ) : (
          ''
        )}
        <div className="tw-border-dashed tw-border-0 tw-border-l tw-mt-2.5 tw-py-14">
          <div className="tw-relative">
            {categoriesLevel.length > 0 ? (
              categoriesLevel.map((categoryLevel, index) => (
                <div
                  key={index}
                  className={`tw-pl-6 tw-relative ${
                    index > 0 ? 'tw-mt-14' : ''
                  }`}
                >
                  <div
                    className={`tw-absolute tw-rounded-full tw-min-h-[13px] tw-min-w-[13px] tw-bg-white tw-border-solid tw-border tw-border-orange -tw-left-[7px] ${
                      categoryLevel.level[0].name
                        ? 'tw-top-[13px]'
                        : 'tw-top-[4px]'
                    } `}
                  />
                  {categoryLevel.level[0].name ? (
                    <div className="tw-text-xs tw-text-greyMuted tw-mb-1">
                      Niveau {categoryLevel.level[0].position}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="tw-flex">
                    <div className="tw-mr-2.5 tw-font-moskauGrotesk tw-font-bold">
                      {editingLevelId === categoryLevel.level[0].id ? (
                        <input
                          ref={levelInput}
                          type="text"
                          className="form-control-sm form-control"
                          placeholder="Nom du niveau"
                          value={inputValue || ''}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      ) : (
                        <span
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            setEditingLevelId(categoryLevel.level[0].id);
                            setInputValue(categoryLevel.level[0].name);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              setEditingLevelId(categoryLevel.level[0].id);
                              setInputValue(categoryLevel.level[0].name);
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {categoryLevel.level[0].name
                            ? categoryLevel.level[0].name
                            : `Niveau ${categoryLevel.level[0].position}`}
                        </span>
                      )}
                    </div>
                    <button
                      type="button"
                      className="tw-border-0 tw-p-0 tw-flex tw-self-center tw-bg-whiteGrey"
                      onClick={() => handleLevelEditButton(categoryLevel.level[0])}
                    >
                      <img
                        src={
                          editingLevelId === categoryLevel.level[0].id
                            ? check
                            : pencilSimple
                        }
                        alt="Edit"
                      />
                    </button>
                    {categoryLevel.level[0].categories === null
                    && index === 0 ? (
                      <button
                        type="button"
                        className="tw-border-0 tw-p-0 tw-flex tw-self-center tw-bg-whiteGrey tw-ml-1.5"
                        onClick={() => handleLevelDeleteButton(categoryLevel.level[0])}
                      >
                        <img src={trash} alt="Delete" />
                      </button>
                      ) : (
                        ''
                      )}
                  </div>
                  <div className="tw-w-full tw-flex tw-flex-col">
                    <div className="tw-w-full tw-flex tw-gap-3  max-lg:tw-flex-col tw-flex-wrap tw-mt-5">
                      {categoryLevel.level[0].categories?.length > 0
                        ? categoryLevel.level[0].categories
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((category, index) => (
                            <CategoryCard
                              key={index}
                              level={categoriesLevel}
                              category={category}
                              setLevelInfo={setLevelInfo}
                              toggleFormVisibility={toggleFormVisibility}
                              toggleModal={toggleModal}
                              setDeleteInfo={setDeleteInfo}
                              getCategories={getCategories}
                              categoryLevel={categoryLevel.level[0]}
                              categoriesLevel={categoriesLevel}
                            />
                          ))
                        : categoryLevel.level[0].position === 0 && (
                          <p className="tw-text-greyDark tw-text-sm tw-w-2/3 tw-mt-4">
                            {t('PARTNER_DASHBOARD.CATEGORIES.INFO')}
                          </p>
                        )}
                    </div>
                    <div className="tw-flex">
                      <button
                        type="button"
                        className="tw-border-0 tw-px-0 tw-content-center tw-flex tw-bg-whiteGrey tw-mt-5"
                        onClick={() => {
                          const currentLevelIndex = categoriesLevel.findIndex(
                            (lvl) => lvl.level[0].id === categoryLevel.level[0].id,
                          );

                          const nextLevel = currentLevelIndex < categoriesLevel.length - 1
                            ? categoriesLevel[currentLevelIndex + 1]
                            : null;

                          setLevelInfo({
                            levelId: categoryLevel.level[0].id,
                            levelName: categoryLevel.level[0].name,
                            category: 0,
                            position: categoryLevel.level[0].position,
                            subCategories: nextLevel
                              ? nextLevel.level[0].categories
                              : null,
                            subCategoriesLevelName: nextLevel
                              ? nextLevel.level[0].name
                              : null,
                          });

                          toggleFormVisibility('new', true);
                        }}
                      >
                        <img
                          src={plusCircle}
                          alt="Plus circle"
                          className="tw-self-center"
                        />
                        <span className="tw-ml-1 tw-self-center tw-text-sm tw-text-primaryNewBlue">
                          {t('PARTNER_DASHBOARD.CATEGORIES.CTA_CATEGORY')}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="tw-pl-6">
                <div className="tw-absolute tw-rounded-full tw-min-h-[13px] tw-min-w-[13px] tw-bg-white tw-border-solid tw-border tw-border-orange -tw-left-[7px] tw-top-[5px]" />
                <div className="tw-flex">
                  <div className="tw-mr-2.5 tw-font-moskauGrotesk tw-font-bold">
                    {editingLevelId === 'new' ? (
                      <input
                        ref={levelInput}
                        type="text"
                        className="form-control-sm form-control"
                        placeholder="Nom du niveau"
                        value={inputValue || ''}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    ) : (
                      <span
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setEditingLevelId('new');
                          setInputValue('');
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            setEditingLevelId('new');
                            setInputValue('');
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {t('PARTNER_DASHBOARD.CATEGORIES.PLACEHOLDER')}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    className="tw-border-0 tw-p-0 tw-flex tw-self-center tw-bg-whiteGrey"
                    onClick={() => handleLevelEditButton({ position: 0 })}
                  >
                    <img
                      src={editingLevelId === 'new' ? check : pencilSimple}
                      alt="Edit"
                      className="tw-ml-2.5"
                    />
                  </button>
                </div>
                <div className="tw-my-5 xl:tw-w-2/3">
                  <p className="tw-text-greyDark tw-text-sm xl:tw-w-2/3">
                    {t('PARTNER_DASHBOARD.CATEGORIES.INFO')}
                  </p>
                  <button
                    type="button"
                    className="tw-border-0 tw-px-0 tw-content-center tw-flex tw-bg-whiteGrey"
                    onClick={() => toggleFormVisibility('new')}
                  >
                    <img
                      src={plusCircle}
                      alt="Plus circle"
                      className="tw-self-center"
                    />
                    <span className="tw-ml-1 tw-self-center tw-text-sm tw-text-primaryNewBlue">
                      {t('PARTNER_DASHBOARD.CATEGORIES.CTA_CATEGORY')}
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showForm && (
        <CategoryForm
          action={action}
          showForm={showForm}
          setShowForm={setShowForm}
          level={levelInfo}
          getCategories={getCategories}
          deleteInfo={deleteInfo}
          setDeleteInfo={setDeleteInfo}
          toggleModal={toggleModal}
          setDeleteModalFromCategory={setDeleteModalFromCategory}
          deleteModalRef={deleteModalRef}
          backgroundDeleteModalRef={backgroundDeleteModalRef}
        />
      )}
      <DeleteConfirmationModal
        deleteModalRef={deleteModalRef}
        deleteInfo={deleteInfo}
        setDeleteInfo={setDeleteInfo}
        toggleModal={toggleModal}
        backgroundDeleteModalRef={backgroundDeleteModalRef}
      />
    </>
  );
}

export default Category;
