import getLocalLink from './getLocalLink';

const getLogout = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/logout`, {
      credentials: 'include',
    });

    if (response.status === 200) {
      window.location = getLocalLink(window.origin);
    }
  } catch (error) {
    throw error;
  }
};

export default getLogout;
