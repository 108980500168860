const questionType = {
  1: 'approach',
  2: 'actions',
  3: 'approach',
  4: 'other_esg',
  5: 'co2_by_product',
  6: 'private_questions',
  7: 'actions',
  8: 'climat',
  9: 'environment',
  10: 'governance',
};

export default questionType;
