import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { isBrowser } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './CategoryFilter.scss';

import handleResponse from '../../../utils/handleResponse';
import getPartnerCategories from '../../../utils/getPartnerCategories';
import getSectorFromParam from './getSectorFromParam';
import CategoryCard from './CategoryCard';
import findParentCategory from '../../../utils/findParentCategory';
import CategoryCardMobile from './CategoryCardMobile';

function CategoryFilter({ partner, setFilteredCategory }) {
  const [allLevels, setAllLevels] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selected, setSelected] = useState([]);
  const [description, setDescription] = useState([]);
  const [loading, setLoading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const QUERY_NAME = 'cf';

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/categories`, {
    cachePolicy: 'no-cache',
  });

  const getCategories = async () => {
    try {
      const result = await getPartnerCategories(api.get);
      if (result) {
        if (result[0].level[0].categories) {
          setSectors([result[0]]);
          setAllLevels(result);
        } else {
          setSectors([result[1]]);
          setAllLevels(result.filter((item, index) => index !== 0));
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      handleResponse(api.response);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getSectorFromParam(
      QUERY_NAME,
      allLevels,
      setSectors,
      setDescription,
      setSelected,
      setFilteredCategory,
      findParentCategory,
    );
  }, [allLevels]);

  return (
    <div className={`container ${isBrowser ? 'mb-5' : 'mb-4'}`}>
      <div id="category-filter" />
      <h3 className="font-24 fw-600 mb-4">
        {t('CATEGORIES_FILTER_HEADER')}
      </h3>
      <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-border tw-border-greySeven tw-border-solid">
        {allLevels && allLevels.map((sector, index) => {
          return (
            <CategoryCard
              key={index}
              sector={sector}
              index={index}
              sectors={sectors}
              loading={loading}
              setLoading={setLoading}
              QUERY_NAME={QUERY_NAME}
              searchParams={searchParams}
              setFilteredCategory={setFilteredCategory}
              allLevels={allLevels}
              setDescription={setDescription}
              setSearchParams={setSearchParams}
              setSelected={setSelected}
              description={description}
              setSectors={setSectors}
              selected={selected}
            />
          );
        })}
        <div className="sector-list-section-mobile">
          {sectors && sectors.map((sector, index) => {
            return index < selected.length + 1 && (
              <CategoryCardMobile
                key={index}
                sector={sector}
                index={index}
                sectors={sectors}
                setSelected={setSelected}
                description={description}
                setDescription={setDescription}
                setFilteredCategory={setFilteredCategory}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                allLevels={allLevels}
                setSectors={setSectors}
                QUERY_NAME={QUERY_NAME}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryFilter;
