import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { isBrowser } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './SectorFilter.scss';

import fileLines from '../../../assets/ui/file-lines.svg';
import pen from '../../../assets/ui/pen.svg';
import trashBin from '../../../assets/ui/trash.svg';
import blankSector from '../../../assets/ui/blank-sector.svg';

import getPartnerSectors from '../../../utils/getPartnerSectors';
import SectorResourcesForm from '../../Public/Profile/Modal/Forms/SectorResourcesForm';
import deletePartnerSectorResource from '../../../utils/deletePartnerSectorResource';
import useModal from '../../../hooks/useModal';

function SectorFilter({ partner, hasWriteAccess, session }) {
  const [sectors, setSectors] = useState([]);
  const [selected, setSelected] = useState();
  const [description, setDescription] = useState();
  const [formContext, setFormContext] = useState({});
  const [loading, setLoading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const { open } = useModal();

  const QUERY_NAME = 'sf';

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/sectors`, {
    cachePolicy: 'no-cache',
  });

  const deleteResourceApi = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/sector/resource`, {
    credentials: 'include',
  });

  const handleClick = (sector) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 350);

    const params = new URLSearchParams(document.location.search);
    const id = params.get('sf');

    if (id && id === sector.id) {
      setSelected(null);
      searchParams.delete('sf');
      setSearchParams(searchParams);
    } else {
      setSelected(sector.name);
      searchParams.set(QUERY_NAME, sector.id);
      setSearchParams(searchParams);
      setDescription({
        name: sector.name,
        description: sector.description,
        svg: (sector.logo_name && sector.logo_path) && sector.logo_path + sector.logo_name,
        resources: sector.resources,
        id: sector.id,
      });
    }
  };

  const handleChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    setSelected(selectedOption.text);
    searchParams.set(QUERY_NAME, e.target.value);
    setSearchParams(searchParams);
    setDescription({
      name: selectedOption.text,
      description: selectedOption.getAttribute('data-description'),
      svg: selectedOption.getAttribute('data-svg'),
      resources: JSON.parse(selectedOption.getAttribute('data-resources')),
      id: e.target.value,
    });
  };

  const getSectorFromParam = () => {
    const params = new URLSearchParams(document.location.search);
    const id = params.get('sf');

    function isSector(sector) {
      return sector.id === id;
    }
    const currentSector = sectors && sectors.find(isSector);
    if (currentSector) {
      document.getElementById('sector-filter').scrollIntoView({ behavior: 'smooth' });
      setSelected(currentSector.name);
      setDescription({
        name: currentSector.name,
        description: currentSector.description,
        svg: (currentSector.logo_name && currentSector.logo_path) && currentSector.logo_path + currentSector.logo_name,
        resources: currentSector.resources,
        id: currentSector.id,
      });
    }
  };

  const getSectors = async () => {
    try {
      const result = await getPartnerSectors(api);
      if (result) {
        setSectors(result);
        getSectorFromParam();
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getSectors();
  }, []);

  useEffect(() => {
    getSectorFromParam();
  }, [window.location.search.search('sf') && sectors]);

  return (
    <div className={`container ${isBrowser ? 'mb-5' : 'mb-4'}`}>
      <div id="sector-filter" />
      <h3 className="font-24 fw-600 mb-4">
        {t('FIND_A_COMPANY_PARTNER_FILTER')}
      </h3>
      <div>
        <div className="wrapper sector-list-section">
          {sectors && sectors.map((sector) => (
            <button
              type="button"
              key={sector.id}
              id={sector.name}
              className={`sector-div ${selected && selected === sector.name ? 'active-sector' : ''}`}
              onClick={() => !loading && handleClick(sector)}
              data-bs-toggle={(selected && selected === sector.name) || !selected ? 'collapse' : ''}
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <div className="align-self-center svg-container" style={{ backgroundColor: selected && selected === sector.name ? partner.primary_color : 'white' }}>
                <div className="sector-logo" style={{ WebkitMaskImage: `url(${sector.logo_path && sector.logo_name ? process.env.REACT_APP_FILES_DOMAIN + sector.logo_path + sector.logo_name : ''})`, maskImage: `url(${sector.logo_path && sector.logo_name ? process.env.REACT_APP_FILES_DOMAIN + sector.logo_path + sector.logo_name : ''})`, backgroundColor: selected && selected === sector.name ? 'white' : partner.primary_color }} />
              </div>
              <div className="align-self-center">
                {sector.name}
              </div>
            </button>
          ))}
        </div>
        <div className="sector-list-section-mobile">
          <select name="sector-select" id="sector-select" value={description ? description.id : 0} style={{ backgroundImage: `url(${description && description.svg ? process.env.REACT_APP_FILES_DOMAIN + description.svg : blankSector})` }} onChange={(e) => handleChange(e)}>
            <option value={0} disabled>CHOISIR UN SECTEUR</option>
            {sectors && sectors.map((sector) => (
              <option
                key={sector.id}
                data-description={sector.description}
                data-svg={(sector.logo_name && sector.logo_path) && sector.logo_path + sector.logo_name}
                data-resources={JSON.stringify(sector.resources)}
                value={sector.id}
              >
                {sector.name}
              </option>
            ))}
          </select>
        </div>
        <div id="collapseOne" className={`accordion-collapse collapse ${description && 'show'}`} aria-labelledby="headingOne">
          {description ? (
            <div className={`sector-description-section px-4 pt-4 ${description && description.resources[0].length > 0 ? 'pb-0' : 'pb-4'}`}>
              <div className="sector-description-container">
                <div className="sector-description-logo-container">
                  <div className="sector-description-logo" style={{ WebkitMaskImage: `url(${description.svg ? process.env.REACT_APP_FILES_DOMAIN + description.svg : ''})`, maskImage: `url(${description.svg ? process.env.REACT_APP_FILES_DOMAIN + description.svg : ''})`, backgroundColor: partner.primary_color }} />
                </div>
                <div className="w-100">
                  <h4 className="sector-description-title">
                    <div>
                      {description.name}
                    </div>
                  </h4>
                  <div className="sector-description font-14 fw-400 dark-L-color">
                    {description.description}
                  </div>
                </div>
              </div>
              {(description.resources[0] && description.resources[0].length > 0) || hasWriteAccess ? (
                <>
                  <hr className="hr" />
                  <div>
                    <h5 className="font-16 fw-600 dark-L-color d-flex justify-content-between">
                      <div>
                        Ressources
                      </div>
                      {hasWriteAccess && description.resources[0].length < 3 ? (
                        <button
                          type="button"
                          className="btn btn-sm white-color"
                          onClick={() => {
                            setFormContext({
                              action: 'create',
                              modalTitle: `${t('ADD_A_RESSOURCE_FOR')} ${description.name}`,
                              sectorId: description.id,
                              partnerId: partner.id,
                              userId: session.id,
                              size: 'modal-lg',
                              showUrlForm: true,
                              data: {
                                name: null,
                                url: null,
                                resourceId: null,
                                getSectors,
                              },
                            });
                            open('sector-resources-modal');
                          }}
                          style={{ backgroundColor: partner.primary_color }}
                        >
                          Ajouter une ressource
                        </button>
                      ) : hasWriteAccess && (
                        <div className="text-end d-flex">
                          <div className="text-danger text-end d-flex align-self-center me-3">
                            <small>Vous ne pouvez pas renseigner plus de 3 ressources.</small>
                          </div>
                          <button type="button" className="btn btn-sm disabled white-color" style={{ backgroundColor: partner.primary_color }}>
                            Ajouter une ressource
                          </button>
                        </div>
                      )}
                    </h5>
                    <div className="sources-link-container">
                      {description.resources[0].map((resource, index) => (
                        <div className="sources-link" key={index}>
                          <a key={index} href={resource.url} className="sources-link-div" target="blank">
                            <img src={fileLines} alt="File lines" />
                            <div className="font-14 fw-500 ms-2">
                              {resource.name}
                            </div>
                          </a>
                          {hasWriteAccess ? (
                            <div className="me-5">
                              <button
                                type="button"
                                className="border-0 bg-white me-1 p-0 action-button"
                                onClick={() => {
                                  setFormContext({
                                    action: 'edit',
                                    primaryButtonTitle: 'Enregistrer',
                                    modalTitle: `${t('ADD_A_RESSOURCE_FOR')} ${description.name}`,
                                    sectorId: description.id,
                                    partnerId: partner.id,
                                    userId: session.id,
                                    size: 'modal-lg',
                                    showUrlForm: true,
                                    data: {
                                      name: resource.name,
                                      url: resource.url,
                                      resourceId: resource.id,
                                      getSectors,
                                    },
                                  });
                                  open('sector-resources-modal');
                                }}
                              >
                                <div className="action-svg" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
                              </button>
                              <button type="button" className="ms-2 profile-modal-button border-0 bg-white action-button p-0" onClick={() => deletePartnerSectorResource(resource.id, deleteResourceApi, getSectors)}>
                                <div className="action-svg" style={{ WebkitMaskImage: `url(${trashBin})`, maskImage: `url(${trashBin})` }} />
                              </button>
                            </div>
                          ) : ('')}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : ('')}
            </div>
          ) : ('')}
        </div>
      </div>
      {hasWriteAccess ? (
        <SectorResourcesForm formContext={formContext} />
      ) : ('')}
    </div>
  );
}

export default SectorFilter;
