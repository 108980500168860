const getSectorFromParam = (
  QUERY_NAME,
  allLevels,
  setSectors,
  setDescription,
  setSelected,
  setFilteredCategory,
  findParentCategory,
) => {
  const params = new URLSearchParams(document.location.search);
  const id = params.get(QUERY_NAME);

  function isCategory(category) {
    return category.id === id;
  }

  const currentCategory = allLevels && allLevels.reduce((foundCategory, sector) => {
    if (foundCategory) return foundCategory;

    return sector.level.reduce((foundInLevel, level) => {
      if (foundInLevel) return foundInLevel;

      return level.categories?.find(isCategory);
    }, null);
  }, null);

  if (currentCategory) {
    document.getElementById('category-filter').scrollIntoView({ behavior: 'smooth' });

    let parentCategory = currentCategory;
    const parentCategories = [];

    setSectors([]);
    // Add the parent category to the sectors state
    const currentLevelIndex = allLevels.findIndex((level) => level.level[0].categories.some((cat) => cat.id === currentCategory.id));

    const currentLevel = allLevels[currentLevelIndex];

    if (currentLevel.level[0].position > 0) {
      setSectors((prevSectors) => {
        const newSectors = [...prevSectors];
        newSectors.unshift({
          level: [
            {
              categories: currentCategory.sub_categories,
              name: currentLevel.level[0].name ? currentLevel.level[0].name : `Niveau ${currentLevel.level[0].position}`,
              id: currentLevel.level[0].id,
            },
          ],
        });
        return newSectors;
      });
    }

    // Find all parent categories
    for (let i = 0; i < allLevels.length; i += 1) {
      parentCategory = findParentCategory(parentCategory, allLevels);

      if (parentCategory) {
        const currentParentCategory = { ...parentCategory }; // Create a copy of parentCategory
        parentCategories.unshift(currentParentCategory); // Add to the beginning of the array

        setDescription((prevState) => {
          // Check if the category is already in the prevState
          const existingCategory = prevState.find((cat) => cat.id === currentParentCategory.id);

          // If the category is not in the prevState, add it
          if (!existingCategory) {
            return [
              {
                name: currentParentCategory.name,
                description: currentParentCategory.description,
                svg: currentParentCategory.icon,
                resources: currentParentCategory.resources,
                id: currentParentCategory.id,
                sectorId: currentLevel.level[0].id,
              },
              ...prevState,
            ];
          }

          // If the category is already in the prevState, return the prevState as is
          return prevState;
        });
      } else {
        break;
      }
    }

    setSelected((prevSelected) => {
      // Create a copy of the array to avoid mutating the state directly
      const newSelected = [...prevSelected];
      // Add all parent categories to the selected array
      parentCategories.forEach((category, index) => {
        newSelected.splice(index, 1, {
          name: category.name,
          position: index,
          id: category.id,
        });
      });
      // Replace the current category at the correct position
      newSelected.splice(parentCategories.length, 1, {
        name: currentCategory.name,
        position: parentCategories.length,
        id: currentCategory.id,
      });

      const constructSectors = (data, selected) => {
        const newObject = [];

        selected.forEach((selectedCategory, index) => {
          const level = data.find((sector) => sector.level[0].categories.some((cat) => cat.id === selectedCategory.id));
          let subCategories = null;

          if (index === 0) {
            newObject.push(level);
          }

          if (index > 0) {
            subCategories = level.level[0].categories.filter((cat) => cat.parent_category_id === selected[index - 1].id);

            const levelCopy = JSON.parse(JSON.stringify(level));
            // Replace the categories with the subcategories
            levelCopy.level[0].categories = subCategories;
            newObject.push(levelCopy);
          }

          if (index === selected.length - 1 && index < data.length - 1) {
            const level = data[index + 1];

            subCategories = level.level[0].categories.filter((cat) => cat.parent_category_id === selectedCategory.id);

            const levelCopy = JSON.parse(JSON.stringify(level));
            // Replace the categories with the subcategories
            levelCopy.level[0].categories = subCategories;
            newObject.push(levelCopy);
          }
        });

        return newObject;
      };

      const newSectors = constructSectors(allLevels, newSelected);

      setSectors(newSectors);

      return newSelected;
    });

    setDescription((prevState) => {
      // Check if the category is already in the prevState
      const existingCategory = prevState.find((cat) => cat.id === currentCategory.id);

      // If the category is not in the prevState, add it
      if (!existingCategory) {
        return [
          ...prevState,
          {
            name: currentCategory.name,
            description: currentCategory.description,
            svg: currentCategory.icon,
            resources: currentCategory.resources,
            id: currentCategory.id,
            sectorId: currentLevel.level[0].id,
          },
        ];
      }

      // If the category is already in the prevState, return the prevState as is
      return prevState;
    });

    setFilteredCategory(currentCategory.name);
  }
};

export default getSectorFromParam;
