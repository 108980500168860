import isProductionEnvironement from './isProductionEnvironement';

const getSession = async (partner) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/login/ping${isProductionEnvironement() ? '' : `?domain=${partner}`}`, {
      credentials: 'include',
    });

    if (response.status === 200) {
      const result = await response.json();
      return result;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default getSession;
