import React, { useState, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../../../tailwind.config';
import handleResponse from '../../../../../utils/handleResponse';

import arrowLeft from '../../../../../assets/ui/arrow-left.svg';

const tagsSelector = [
  {
    tailwindClassColor: 'tw-bg-tagsGrey',
    colorValue: tailwindConfig.theme.colors.tagsGrey,
  },
  {
    tailwindClassColor: 'tw-bg-tagsLightPink',
    colorValue: tailwindConfig.theme.colors.tagsLightPink,
  },
  {
    tailwindClassColor: 'tw-bg-tagsYellow',
    colorValue: tailwindConfig.theme.colors.tagsYellow,
  },
  {
    tailwindClassColor: 'tw-bg-tagsGreen',
    colorValue: tailwindConfig.theme.colors.tagsGreen,
  },
  {
    tailwindClassColor: 'tw-bg-tagsBlue',
    colorValue: tailwindConfig.theme.colors.tagsBlue,
  },
  {
    tailwindClassColor: 'tw-bg-tagdBlueGrey',
    colorValue: tailwindConfig.theme.colors.tagdBlueGrey,
  },
  {
    tailwindClassColor: 'tw-bg-tagsPurple',
    colorValue: tailwindConfig.theme.colors.tagsPurple,
  },
  {
    tailwindClassColor: 'tw-bg-tagsPink',
    colorValue: tailwindConfig.theme.colors.tagsPink,
  },
  {
    tailwindClassColor: 'tw-bg-tagsOrange',
    colorValue: tailwindConfig.theme.colors.tagsOrange,
  },
];

function CreateTagForm({ partner, onCancel, onCreate, partnerTags, getPartnerTags, tagToEdit }) {
  const [tagName, setTagName] = useState(tagToEdit ? tagToEdit.name : '');
  const [error, setError] = useState('');
  const [selectedColor, setSelectedColor] = useState(tagToEdit ? tagToEdit.color : tagsSelector[0].colorValue);
  const { t } = useTranslation();

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/tags`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    setError('');
  }, [tagName]);

  const handleInputChange = (event) => {
    setTagName(event.target.value);
  };

  const isTagNameExists = (name) => {
    return partnerTags.some((tag) => tag.name.toLowerCase() === name.toLowerCase() && tag.id !== (tagToEdit?.id));
  };

  const handleSubmit = async () => {
    if (isTagNameExists(tagName)) {
      setError(t('TAG_NAME_EXISTS'));
      return;
    }

    const body = {
      name: tagName,
      color: selectedColor,
    };

    try {
      let result;
      if (tagToEdit) {
        result = await put(`/${tagToEdit.id}`, body);
      } else {
        result = await post(body);
      }

      if (response.ok && result?.id) {
        onCreate({ id: result.id, name: tagName, color: selectedColor });
        setTagName('');
        setError('');
        if (tagToEdit) {
          getPartnerTags();
        }
      }
    } catch (error) {
      handleResponse(response);
    }
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-border tw-border-solid tw-border-greySix tw-rounded-[4px] tw-p-4 tw-mt-5">
      <div className="tw-flex tw-items-center tw-mb-4">
        <button
          type="button"
          onClick={onCancel}
          className="tw-border-none tw-bg-white tw-p-0"
        >
          <img src={arrowLeft} alt="back" className="tw-w-6 tw-h-6 tw-mr-3" />
        </button>
        <span className="tw-font-medium tw-text-greyOne tw-font-moskauGrotesk">{tagToEdit ? t('EDIT_TAG_A') : t('CREATE_TAG')}</span>
      </div>
      <input
        id="tagName"
        type="text"
        placeholder="Nom de l'étiquette"
        className={`tw-rounded-t-md tw-rounded-b-none placeholder:tw-text-greyFour tw-text-greyOne tw-font-moskauGrotesk tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full tw-border tw-border-solid tw-border-greySix ${error ? 'tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border' : 'tw-border-greySix'} focus:tw-border-2 focus:tw-outline-none focus:tw-border-secondarySkyBlue`}
        value={tagName}
        onChange={handleInputChange}
      />
      <div className="tw-flex tw-flex-col tw-p-[14px] tw-border-solid tw-border-x tw-border-b tw-border-y-0 tw-border-greySix ">
        <span className="tw-text-sm tw-font-moskauGrotesk tw-text-greyThree">{t('SELECT_TAG_COLOR')}</span>
        <div className="tw-flex tw-items-center tw-gap-2.5 flew-wrap tw-mt-2.5">
          {tagsSelector.map((tag) => (
            <button
              key={tag.colorValue}
              type="button"
              onClick={() => handleColorSelect(tag.colorValue)}
              className={`${tag.tailwindClassColor} tw-w-10 tw-h-5 tw-rounded-sm tw-border-none ${selectedColor === tag.colorValue ? ' tw-ring-2 tw-ring-secondarySkyBlue' : ''}`}
            />
          ))}
        </div>
      </div>
      <button
        type="button"
        disabled={tagName === ''}
        className="tw-flex tw-items-center tw-bg-greyEight tw-border tw-border-x tw-border-y-0 tw-border-b tw-border-solid tw-border-greySix tw-rounded-t-none tw-rounded-b-md tw-p-[14px]"
        onClick={handleSubmit}
      >
        <span
          className="tw-border-none tw-bg-greyEight tw-text-base tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-medium -tw-mb-1"
        >
          {tagToEdit ? t('SAVE_TAG') : t('CREATE')}
        </span>
        {tagName && (
          <div className="tw-ml-4 tw-flex tw-items-center tw-rounded tw-py-[6px] tw-px-3 tw-shadow-tagShadow" style={{ backgroundColor: selectedColor }}>
            <span className="tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne -tw-mb-[4px]">{tagName}</span>
          </div>
        )}
      </button>
      {error && <span className="tw-text-sm tw-text-redError tw-font-moskauGrotesk tw-mt-1">{error}</span>}
    </div>
  );
}

export default CreateTagForm;
