import React, { useState } from 'react';
import StoreContext from './StoreContext';

function StoreProvider({ children }) {
  const [store, setStore] = useState({});

  // const value = useMemo(() => ({
  //   store, setStore,
  // }), [store]);

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;
