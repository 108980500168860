import co2IconLevel1 from '../assets/ui/co2-icon-level-1.svg';
import co2IconLevel2 from '../assets/ui/co2-icon-level-2.svg';
import co2IconLevel3 from '../assets/ui/co2-icon-level-3.svg';
import co2IconLevel4 from '../assets/ui/co2-icon-level-4.svg';
import co2IconLevel5 from '../assets/ui/co2-icon-level-5.svg';
import co2IconLevel6 from '../assets/ui/co2-icon-level-6.svg';
import co2Level1 from '../assets/ui/co2-level-1.svg';
import co2Level2 from '../assets/ui/co2-level-2.svg';
import co2Level3 from '../assets/ui/co2-level-3.svg';
import co2Level4 from '../assets/ui/co2-level-4.svg';
import co2Level5 from '../assets/ui/co2-level-5.svg';
import co2Level6 from '../assets/ui/co2-level-6.svg';

const getWebUiElements = (score) => {
  let color;
  let svg;
  let svgV2;
  let co2Icon;
  let co2Arrow;
  let backgroundColor;
  let backgroundColorDataCarbon;
  let svgIteration;
  let mainText;
  let secondText;
  let action;
  let description;
  let actionEN;
  let descriptionEN;
  let mainTextEn;

  switch (score) {
    case 1:
      color = '#820000';
      svg = 'xmark-large';
      svgV2 = 'co2-icon-dark-red';
      co2Icon = co2IconLevel1;
      co2Arrow = co2Level1;
      backgroundColor = 'rgba(119, 21, 12, 0.06)';
      backgroundColorDataCarbon = '#EEE3E1';
      svgIteration = '1';
      mainText = 'CO2 non mesuré / publié';
      secondText = 'Trajectoire critique >4°C.';
      action = 'Action climat : Aucun reporting';
      description = 'Impact carbone même pas mesuré';
      actionEN = 'Climate action: No reporting';
      descriptionEN = 'Carbon footprint not even measured';
      mainTextEn = 'CO2 not measured / published';
      break;
    case 2:
      color = '#C00000';
      svg = 'arrow-up-right';
      svgV2 = 'co2-icon-red';
      co2Icon = co2IconLevel2;
      co2Arrow = co2Level2;
      backgroundColor = 'rgba(176, 36, 24, 0.06)';
      backgroundColorDataCarbon = '#F5E4E3';
      svgIteration = '1';
      mainText = 'Très insuffisant';
      secondText = 'En route vers 4°C.';
      action = 'Action climat : Très insuffisante';
      description = 'Emissions de CO2 encore en hausse';
      actionEN = 'Climate action: Highly insufficient';
      descriptionEN = 'CO2 emissions still increasing';
      mainTextEn = 'Highly insufficient';
      break;
    case 3:
      color = '#FF8939';
      svg = 'arrow-right';
      svgV2 = 'co2-icon-orange';
      co2Icon = co2IconLevel3;
      co2Arrow = co2Level3;
      backgroundColor = 'rgba(239, 143, 76, 0.06)';
      backgroundColorDataCarbon = '#FDF1E9';
      svgIteration = '1';
      mainText = 'Encore insuffisant';
      secondText = 'Incompatible 2°C.';
      action = 'Action climat : Encore insuffisante';
      description = 'Baisse insuffisante de ses émissions';
      actionEN = 'Climate action: Insufficient';
      descriptionEN = 'Insufficient reduction of its emissions';
      mainTextEn = 'Insufficient';
      break;
    case 4:
      color = '#FEC800';
      svg = 'arrow-down-right';
      svgV2 = 'co2-icon-yellow';
      co2Icon = co2IconLevel4;
      co2Arrow = co2Level4;
      backgroundColor = 'rgba(246, 202, 69, 0.06)';
      backgroundColorDataCarbon = '#FEF9E8';
      svgIteration = '1';
      mainText = 'Intermédiaire';
      secondText = 'Compatible 2°C.';
      action = 'Action climat : Intermédiaire';
      description = 'Baisse partielle de ses émissions';
      actionEN = 'Climate Action: Intermediate';
      descriptionEN = 'Partial reduction of its emissions';
      mainTextEn = 'Intermediate';
      break;
    case 5:
      color = '#8CDF41';
      svg = 'arrow-down-right';
      svgV2 = 'co2-icon-green';
      co2Icon = co2IconLevel5;
      co2Arrow = co2Level5;
      backgroundColor = 'rgba(159, 221, 92, 0.06)';
      backgroundColorDataCarbon = '#F3FBEB';
      svgIteration = '2';
      mainText = 'Forte réduction de CO2';
      secondText = 'Compatible Bien moins de 2°C.';
      action = 'Action climat : Forte';
      description = 'Baisse rapide de ses émissions';
      actionEN = 'Climate action: Strong';
      descriptionEN = 'Rapid drop in CO2 emissions';
      mainTextEn = 'Strong CO2 reduction';
      break;
    case 6:
      color = '#0DB800';
      svg = 'arrow-down';
      svgV2 = 'co2-icon-dark-green';
      co2Icon = co2IconLevel6;
      co2Arrow = co2Level6;
      backgroundColor = 'rgba(84, 181, 52, 0.06)';
      backgroundColorDataCarbon = '#EAF6E6';
      svgIteration = '3';
      mainText = 'Très forte réduction de CO2';
      secondText = 'Compatible 1.5°C.';
      action = 'Action climat : Très forte';
      description = 'Baisse très rapide de ses émissions';
      actionEN = 'Climate action: Very strong';
      descriptionEN = 'Very rapid drop in CO2 emissions';
      mainTextEn = 'Very strong CO2 reduction';
      break;

    case false:
    default:
      color = '#C00000';
      svg = 'arrow-up';
      svgV2 = 'co2-icon-dark-red';
      co2Icon = co2IconLevel1;
      co2Arrow = co2Level2;
      backgroundColor = 'rgba(119, 21, 12, 0.06)';
      backgroundColorDataCarbon = '#EEE3E1';
      svgIteration = '1';
      mainText = 'CO2 non mesuré / publié';
      secondText = 'Trajectoire business-as-usual : vers +4°C';
      action = 'Action climat : Aucun reporting';
      description = 'Impact carbone même pas mesuré';
      actionEN = 'Climate action: No reporting';
      descriptionEN = 'Carbon footprint not even measured';
      mainTextEn = 'CO2 not measured / published';
      break;
  }

  return {
    color,
    svg,
    svgV2,
    co2Icon,
    co2Arrow,
    backgroundColor,
    backgroundColorDataCarbon,
    svgIteration,
    mainText,
    secondText,
    action,
    description,
    actionEN,
    descriptionEN,
    mainTextEn,
  };
};

export default getWebUiElements;
