import React from 'react';
import { useTranslation } from 'react-i18next';

import pen from '../../../../assets/ui/pen.svg';

function ActionsHead({ cellStyle }) {
  const { t } = useTranslation();

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('ACTIONS')}
      </div>
    </th>
  );
}

function ActionsBody({ cellStyle, setDrawerVisibility }) {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
    setDrawerVisibility(true);
  };

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySix tw-border" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <button type="button" className="d-flex mt-1 table-status-edit border-0" onClick={(e) => handleClick(e)}>
          <span className="align-self-center me-2 tw-decoration-primaryNewBlue tw-text-primaryNewBlue">
            {t('ACTION_EDIT')}
          </span>
          <div className="action-svg align-self-center" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})`, height: 12, width: 12 }} />
        </button>
      </div>
    </td>
  );
}

export { ActionsHead, ActionsBody };
