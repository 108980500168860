import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getUserLocal from '../../../../utils/getUserLocal';
import Features from '../../../../features.json';

import Tabs from '../Tabs/Tabs';
import ocStomp from '../../../../assets/ui/open-climat-stomp.svg';
import bug from '../../../../assets/ui/bug-new-blue.svg';
import Certified from '../../../../assets/ui/managed.svg';

import './Header.scss';
import SectorInformations from './SectorInformations';
import EmployeesInformations from './EmployeesInformations';
import GroupInformations from './GroupInformations';

function Header({ view }) {
  const [brands, setBrands] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);

  const { profile, features } = useOutletContext();
  const { t } = useTranslation();

  const MAX_BRANDS = 4; // 0, 1, 2, 4 (4 valeurs)

  const userLocale = getUserLocal();

  const tooltipRef = useRef(null);

  const handleMouseEnter = (tooltip) => {
    setActiveTooltip(tooltip);
  };

  const handleMouseLeave = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.relatedTarget)) {
      setActiveTooltip(null);
    }
  };

  useEffect(() => {
    if (profile?.brands) {
      setBrands(profile.brands);
    }
  }, [profile]);

  return (
    <>
      <div className="container-fluid tw-mx-0 tw-bg-backgroundCleanBlue">
        <div className="container">
          <div className="tw-w-full max-[992px]:tw-flex-col tw-flex tw-items-center tw-justify-between tw-p-5">
            <div className="tw-w-full tw-h-full tw-flex max-[768px]:tw-flex-col tw-items-center tw-gap-x-9 ">
              <div className="tw-min-w-[180px] tw-min-h-[180px] tw-w-[180px] tw-h-[180px]  tw-rounded-full tw-bg-white tw-bg-center tw-bg-no-repeat max-[768px]:tw-mb-6 tw-border tw-border-solid tw-border-greySeven" style={{ backgroundImage: `url(${process.env.REACT_APP_FILES_DOMAIN}${profile.logo_url})`, backgroundSize: '140px 140px' }} />
              <div className="tw-w-full tw-flex tw-flex-col">
                <div className="tw-flex tw-items-center tw-gap-x-3 tw-mb-4">
                  <h1 className="tw-text-[28px] tw-text-black tw-m-0">{ profile?.name }</h1>
                  {profile?.is_managed &&
                  <img src={Certified} alt="certified logo" className="-tw-mt-2" />}
                </div>
                <div className="tw-flex tw-items-stretch tw-gap-x-7">
                  <SectorInformations profile={profile} />
                  <EmployeesInformations profile={profile} />
                  <GroupInformations profile={profile} />
                </div>
                {brands?.length > 0 && (
                  <div className="tw-flex tw-flex-col tw-items-stretch tw-gap-x-7 tw-mt-4">
                    <div className="tw-text-sm tw-text-greyFour">
                      {t('BRANDS')}
                    </div>
                    <div className="tw-flex tw-gap-1.5 tw-mt-0.5 tw-items-center">
                      {brands?.slice(0, MAX_BRANDS).map((brand) => (
                        <div
                          ref={tooltipRef}
                          className="tw-w-[40px] tw-h-[40px] tw-rounded-full tw-bg-white tw-bg-center tw-bg-no-repeat tw-border tw-border-greySeven tw-border-solid tw-justify-center tw-items-center tw-flex tw-relative"
                          onMouseEnter={() => handleMouseEnter(brand.name)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img src={`${process.env.REACT_APP_FILES_DOMAIN}${brand.attachment_path}${brand.attachment_name}`} alt={brand.name} width={36} height={36} className="tw-max-h-9 tw-max-w-9 tw-object-contain tw-p-1.5" />
                          {activeTooltip === brand.name && (
                            <ul
                              ref={tooltipRef}
                              className="tw-absolute tw-left-full tw-ml-3 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-bg-greyThree tw-py-2.5 tw-px-3 tw-rounded-sm tw-text-sm tw-text-white tw-z-30 tw-list-none"
                              onMouseEnter={() => handleMouseEnter(brand.name)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <li key={brand.name} className="tw-whitespace-nowrap">{`${brand.name}`}</li>
                            </ul>
                          )}
                        </div>
                      ))}
                      {(brands.length > MAX_BRANDS && brands.length > 0) && (
                        <span
                          className="tw-underline tw-relative"
                          onMouseEnter={() => handleMouseEnter('moreBrands')}
                          onMouseLeave={handleMouseLeave}
                        >
                          +&nbsp;{parseInt(brands.length, 10) - MAX_BRANDS}
                          {activeTooltip === 'moreBrands' && (
                            <ul
                              ref={tooltipRef}
                              className="tw-absolute tw-left-full tw-ml-3 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-bg-greyThree tw-py-2.5 tw-px-3 tw-rounded-sm tw-text-sm tw-text-white tw-z-30 tw-list-none"
                              onMouseEnter={() => handleMouseEnter('moreBrands')}
                              onMouseLeave={handleMouseLeave}
                            >
                              {brands.slice(MAX_BRANDS, brands.length).map((brand) => (
                                <li key={brand.name} className="tw-whitespace-nowrap">{` ${brand.name}`}</li>
                              ))}
                            </ul>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={`${profile.type === 1 ? 'min-[1230px]:tw-w-80 min-[1150px]:tw-w-60 tw-w-40' : 'tw-w-80'}  tw-hidden min-[992px]:tw-flex tw-flex-col tw-items-center tw-justify-center`}>
              {profile?.is_managed ? (
                <>
                  <img src={ocStomp} alt="open climat logo" />
                  <div className="tw-text-sm tw-font-moskauGrotesk tw-font-semibold tw-text-primaryNewBlue tw-my-3 tw-text-center">{t('HEADER_COMPLETED_PROFILE_A')}
                    <br />
                    {t('HEADER_COMPLETED_PROFILE_B')}
                  </div>
                </>
              ) : (
                <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-2">
                  <img src={bug} alt="warning" />
                  <div className="tw-text-sm tw-font-semibold tw-font-moskauGrotesk tw-text-primaryNewBlue">{t('HEADER_PARTIAL_PROFILE')}</div>
                </div>
              )}
              {features.includes(Features.ctaOpenClimat) && (
              <button type="button" className=" tw-py-3 tw-px-7 tw-bg-primaryNewBlue tw-rounded-md tw-text-white tw-text-sm tw-font-medium tw-border-none">
                <a href={`https://www.openclimat.com/${userLocale}/profile/${profile.slug}`} className="tw-no-underline tw-text-white">{t('GO_TO_OPENCLIMAT_PAGE')}</a>
              </button>
              )}
            </div>
            <div className="tw-flex tw-flex-col min-[992px]:tw-hidden tw-p-6 tw-border tw-border-solid tw-border-greySix tw-rounded-sm tw-mt-10">
              {
                profile.is_managed ? (
                  <div className="tw-flex tw-items-center tw-gap-x-2 tw-justify-center tw-text-center">
                    <img src={ocStomp} alt="open climat logo" width="30px" height="auto" />
                    <div className="tw-text-sm tw-font-moskauGrotesk tw-font-semibold tw-text-primaryNewBlue tw-my-3">
                      {t('HEADER_COMPLETED_PROFILE_A')}
                      <br />
                      {t('HEADER_COMPLETED_PROFILE_B')}
                    </div>
                  </div>
                ) : (
                  <div className="tw-flex flex tw-items-center tw-gap-x-2 tw-justify-center">
                    <img src={bug} alt="warning" width="30px" height="auto" />
                    <div className="tw-text-sm tw-font-moskauGrotesk tw-font-semibold tw-text-primaryNewBlue tw-my-3">{t('HEADER_PARTIAL_PROFILE')}</div>
                  </div>
                )
              }
              {
                features.includes(Features.ctaOpenClimat) && (
                <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
                  <button type="button" className=" tw-py-3 tw-px-7 tw-bg-primaryNewBlue tw-rounded-md tw-text-white tw-text-sm tw-font-medium tw-border-none">
                    <a href={`https://www.openclimat.com/${userLocale}/profile/${profile.slug}`} className="tw-no-underline tw-text-white">{t('GO_TO_OPENCLIMAT_PAGE')}</a>
                  </button>
                </div>
                )
}
            </div>
          </div>
        </div>
      </div>
      <Tabs view={view} />
    </>
  );
}

export default Header;
