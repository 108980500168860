import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';

import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

function Tooltip({ id, value, color }) {
  return (
    <div
      style={{
        padding: 12,
        color,
        background: 'white',
      }}
    >
      <div>
        {id}: {value}%
      </div>
    </div>
  );
}

function MainChart({ globalStats, colorsObjects }) {
  const [data, setData] = useState();
  const [colors, setColors] = useState();
  const [chartKeys, setChartKeys] = useState();

  const getColor = (bar) => getHexColorWithOpacity(colors[bar.id], 1);

  const theme = {
    axis: {
      textColor: '#eee',
      fontSize: '34px',
      tickColor: '#eee',
    },
  };

  useEffect(() => {
    const data = [
      {
        Mois: '2023',
        ...(globalStats || {}),
      },
    ];

    const chartKeys = Object.keys(data[0]).filter((key) => key !== 'Mois');

    setColors(colorsObjects);
    setData(data);
    setChartKeys(chartKeys);
  }, [globalStats]);

  return (
    data ? (
      <ResponsiveBar
        theme={theme}
        tooltip={Tooltip}
        data={data}
        keys={chartKeys}
        valueFormat={(value) => `${value}%`}
        maxValue={100}
        indexBy="Mois"
        margin={{ top: 15, right: 24, bottom: 50, left: 40 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getColor}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'fries',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'sandwich',
            },
            id: 'lines',
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
          format: (value) => `${value}%`,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        role="application"
        ariaLabel="Barchart company chart"
        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`}
      />
    ) : ('')
  );
}

export default MainChart;
