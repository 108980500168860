import React from 'react';
import { useTranslation } from 'react-i18next';

function NoCimateActions({ dataUsable, dataNotComparable }) {
  const { t } = useTranslation();

  return (
    <div className="tw-col-span-1 tw-col-start-4 tw-text-xs tw-text-center">
      <div className="tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
        {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
      </div>
      <div className="tw-text-noteTresInsuffisante tw-mt-1 tw-font-semibold tw-font-moskauGrotesk">
        {dataUsable ? t('CLIMATE_ACTIONS.RECENT_MESSURES') : ''}
        {!dataUsable && dataNotComparable ? t('CLIMATE_ACTIONS.NOT_COMPARABLE') : ''}
      </div>
    </div>
  );
}

export default NoCimateActions;
