/**
 * Formats the target SBT value into a string
 *
 * @param {number} target - The target SBT value to format
 * @returns {string} The formatted target SBT
 */
const formatTargetSBTSimple = (target) => {
  let text = null;

  if (target === 1 || target === 2 || target === 3) {
    text = 'VALIDATED';
  } else if (target === 4) {
    text = 'ONGOING';
  }

  return text;
};

export default formatTargetSBTSimple;
