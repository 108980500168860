import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';

import toggleSort from '../../../../utils/toggleSort';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function StatusHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'status';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="status-col font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="tw-flex tw-justify-center tw-text-center">
        {t('STATUS_SUPPLIER')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function StatusBody({ profile, cellStyle }) {
  const { t } = useTranslation();

  return (
    <td className="status-col tw-px-3 tw-border-solid tw-border-greySix tw-border tw-w-52" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div>
          {profile.status_name && profile.status_color ? (
            <div className="status-div" style={{ color: profile.status_color, backgroundColor: getHexColorWithOpacity(profile.status_color, 0.1) }}>
              {t(profile.status_name)}
            </div>
          ) : ('')}
        </div>
      </div>
    </td>
  );
}

export { StatusHead, StatusBody };
