import React from 'react';

import upSortArrowDownActive from '../../../assets/ui/database/arrows-down-up-active-down.svg';
import upDownArrow from '../../../assets/ui/database/arrows-down-up.svg';

function SortArrow({ sort }) {
  return (
    sort ? (
      <span className="text-end tw-ml-2.5 align-self-center">
        <img className="link-sort-icon align-middle" src={upSortArrowDownActive} alt="name" style={{ transform: `rotate(${sort === 'desc' ? '0' : '-180deg'})` }} />
      </span>
    ) : (
      <span className="text-end tw-ml-2.5 align-self-center">
        <img className="link-sort-icon align-middle" src={upDownArrow} alt="name" />
      </span>
    )
  );
}

export default SortArrow;
