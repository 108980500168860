import React from 'react';
import { useTranslation } from 'react-i18next';

function Category({ showCategoryNotes, categories, handleShowCategoryNotes, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="tw-text-greyThree">
        {t('NOTES.SUBTITLE_A')}
      </div>
      <div className="tw-mt-6 tw-flex tw-flex-col tw-gap-2.5">
        {loading && (
          <div className="tw-bg-[#FDFDFD] tw-border tw-border-solid tw-border-greySeven tw-p-[14px] tw-rounded">
            <div className="tw-bg-greySeven tw-animate-pulse tw-h-12 tw-rounded" />
          </div>
        )}
        {!showCategoryNotes && !loading && categories?.map((category) => (
          <button
            key={category.id}
            type="button"
            className="tw-bg-[#FDFDFD] tw-border tw-border-solid tw-border-greySeven tw-p-[14px] tw-text-left tw-rounded hover:tw-border-secondarySkyBlue"
            onClick={() => handleShowCategoryNotes(category)}
          >
            <div className="tw-text-greyThree tw-text-sm">
              {category.category_name}
            </div>
            <div className="tw-text-sm tw-text-primaryNewBlue tw-font-semibold tw-mt-1.5">
              {category.notes_count} {t('NOTES.NOTES')}
            </div>
          </button>
        ))}
      </div>
    </>
  );
}

export default Category;
