import React from 'react';
import { useTranslation } from 'react-i18next';

function Status({ profileStatusList, register, errors }) {
  const { t } = useTranslation();

  return (
    <div className="tw-mt-8">
      <label htmlFor="status" className="tw-block tw-text-base tw-font-medium tw-text-greyOne tw-mb-2.5">
        {t('STATUS_SUPPLIER')}
      </label>
      <select
        className={`border tw-rounded-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.status ? 'border-redError focus:tw-outline-redError' : 'border-greySix focus:tw-outline-secondarySkyBlue'}`}
        {...register('status')}
      >
        <option value="default" disabled>{t('CHOOSE_STATUS')}</option>
        {profileStatusList?.sort((a, b) => a.position - b.position).map((status, index) => (
          <option key={index} value={status.id}>{t(status.name)}</option>
        ), [])}
      </select>
    </div>
  );
}

export default Status;
