import React from 'react';
import { useTranslation } from 'react-i18next';

function ProfileRating({ partner, register, errors }) {
  const { t } = useTranslation();
  return (
    <div className="tw-mt-8">
      <label htmlFor="rating" className="tw-block tw-text-base tw-font-medium tw-text-greyOne tw-mb-2.5">
        Note {partner.name}
      </label>
      <input
        id="rating"
        type="number"
        step=".01"
        className={`border tw-rounded-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.rating ? 'border-redError focus:tw-outline-redError' : 'border-greySix focus:tw-outline-secondarySkyBlue'}`}
        {...register('rating', {
          max: {
            value: partner.max_rating,
            message: `${t('RATING_ERROR_2')} (${partner.max_rating})`,
          },
          min: {
            value: 0,
            message: t('RATING_ERROR_1'),
          },
        })}
      />
      {errors && errors.rating && (
      <span className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors?.rating?.message}</span>
      )}
    </div>
  );
}

export default ProfileRating;
