const getCompetingCompanies = async (profileId, partnerId, selectedCategory) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/competing-companies?partner=${partnerId}${selectedCategory ? `&cf=${selectedCategory}` : ''}`);

    if (response.status === 200) {
      const result = await response.json();

      return result;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default getCompetingCompanies;
