import React from 'react';
import { useTranslation } from 'react-i18next';

import RenderTagOptions from './RenderTagOptions';

function RenderTagDropdown({ availableTags, setHoveredTagId, hoveredTagId, handleTagSelection, handleCreateTag, tailwindConfig, handleToggleMenu, openMenuTagId, handleEditTag, handleDeleteTag }) {
  const { t } = useTranslation();

  return (
    <div className="tw-z-10 tw-w-full tw-bg-white tw-border tw-border-t-0 tw-border-solid tw-border-greySix tw-rounded-b-md tw-shadow-companyCard tw-max-h-60 tw-overflow-auto">
      {availableTags.length === 0 ? (
        <div className="tw-text-sm tw-text-greyThree tw-font-moskauGrotesk tw-p-[14px] tw-border-b tw-border-greyNine">
          {t('NO_TAG_AVAILABLE')}
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-3 tw-py-[14px] tw-px-6">
          {availableTags.map((tag) => (
            <div
              key={`available-${tag.id}`}
              className="tw-flex tw-items-center"
              onMouseEnter={() => setHoveredTagId(tag.id)}
              onMouseLeave={() => setHoveredTagId(null)}
            >
              <button
                type="button"
                onClick={() => handleTagSelection(tag)}
                className="tw-py-[6px] tw-px-3 tw-rounded tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne tw-border-none tw-cursor-pointer"
                style={{ backgroundColor: tag.color || tailwindConfig.theme.colors.tagsGrey }}
              >
                {tag.name}
              </button>
              {hoveredTagId === tag.id && (
                <RenderTagOptions
                  tag={tag}
                  handleToggleMenu={handleToggleMenu}
                  openMenuTagId={openMenuTagId}
                  handleEditTag={handleEditTag}
                  handleDeleteTag={handleDeleteTag}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <button
        type="button"
        onClick={handleCreateTag}
        className="tw-w-full tw-bg-greyEight tw-text-sm tw-text-primaryNewBlue tw-border tw-border-t tw-border-x-0 tw-border-b-0 tw-border-solid tw-border-greySix tw-p-[14px] tw-text-start"
      >
        {t('CREATE_NEW_TAG')}
      </button>
    </div>
  );
}

export default RenderTagDropdown;
