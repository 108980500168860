import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { useOutletContext } from 'react-router-dom';

import dotsThreeVertical from '../../../assets/ui/dots-three-vertical.svg';
import pencilSimple from '../../../assets/ui/pencil-simple.svg';
import trashBin from '../../../assets/ui/trash-bin.svg';
import folderPlus from '../../../assets/ui/folder-plus.svg';
import checkCategory from '../../../assets/ui/check-categegory.svg';
import checkCategoryMediumGrey from '../../../assets/ui/check-medium-grey.svg';

import categoryIcons from '../../../utils/categoryIcons';
import handleResponse from '../../../utils/handleResponse';

function CategoryCard({ level, category, setLevelInfo, toggleFormVisibility, toggleModal, setDeleteInfo, getCategories, categoryLevel, categoriesLevel }) {
  const { partner, session } = useOutletContext();
  const [show, setShow] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [showOrganizeModal, setShowOrganizeModal] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [hoveredSubCategoryId, setHoveredSubCategoryId] = useState(null);

  const isPartnerPrivate = partner.private === 1;

  const { put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const modalRef = useRef(null);
  const cardRef = useRef(null);
  const buttonRef = useRef(null);

  const { t } = useTranslation();

  const changeCardColor = () => {
    const card = cardRef.current;
    const button = buttonRef.current;

    if (card && button && card.classList.contains('tw-bg-white')) {
      card.classList.remove('hover:tw-border-secondarySkyBlue');
      card.classList.remove('tw-bg-white');
      card.classList.add('tw-bg-secondarySkyBlue/[.05]');
      card.classList.remove('tw-border-greyBorderLight');
      card.classList.add('tw-border-secondarySkyBlue');
      button.classList.remove('tw-bg-white');
      button.classList.add('tw-bg-secondarySkyBlue/[.005]');
    } else {
      card.classList.add('hover:tw-border-secondarySkyBlue');
      card.classList.remove('tw-bg-secondarySkyBlue/[.05]');
      card.classList.add('tw-bg-white');
      card.classList.remove('tw-border-secondarySkyBlue');
      card.classList.add('tw-border-greyBorderLight');
      button.classList.remove('tw-bg-secondarySkyBlue/[.005]');
      button.classList.add('tw-bg-white');
    }
  };

  const handleSubCategoryClick = async (e, subCategory) => {
    e.stopPropagation();
    let newParentCategoryId = null;

    if (subCategory.parent_category_id === category.id) {
      newParentCategoryId = null;
    } else {
      newParentCategoryId = category.id;
    }

    const updateData = {
      user_id: session.id,
      parent_category_id: newParentCategoryId,
      name: subCategory.name,
      description: subCategory.description,
      icon: subCategory.icon,
    };

    try {
      await put(`${partner.id}/custom-categories/${subCategory.id}`, updateData);

      if (response.ok) {
        await getCategories();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const onClick = (e) => {
    e.stopPropagation();
    setShow(!show);
    changeCardColor();
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      const card = cardRef.current;
      const button = buttonRef.current;
      if (card) {
        card.classList.remove('tw-bg-secondarySkyBlue/[.05]');
        card.classList.remove('tw-border-secondarySkyBlue');
        card.classList.add('hover:tw-border-secondarySkyBlue');
        card.classList.add('tw-bg-white');
        card.classList.add('tw-border-greyBorderLight');
        button.classList.remove('tw-bg-secondarySkyBlue/[.05]');
        button.classList.add('tw-bg-white');
      }

      setShow(false);
      setShowOrganizeModal(false);
    }
  };

  const findParentCategory = (category, allCategories) => {
    const parentCategory = allCategories?.find((cat) => cat?.sub_categories && cat.sub_categories?.some((subCat) => subCat.id === category.id));
    return parentCategory;
  };

  const getCurrentLevelIndex = () => level.findIndex((lvl) => lvl.level?.some((subLevel) => subLevel.categories?.some((cat) => cat.id === category.id)));

  const handleDelete = async (e) => {
    e.stopPropagation();
    let canDelete = {
      canDelete: true,
    };
    let parentCategory;

    categoriesLevel.forEach((lvl) => {
      const foundParentCategory = findParentCategory(category, lvl.level[0].categories);
      if (foundParentCategory) {
        parentCategory = foundParentCategory;
      }
    });

    if (parentCategory && parentCategory.sub_categories.length <= 1) {
      canDelete = {
        canDelete: false,
        parentCategoryName: parentCategory.name,
      };
    }

    // Trouver le niveau de la catégorie à supprimer
    const currentLevelIndex = getCurrentLevelIndex();
    const currentLevel = currentLevelIndex !== -1 ? level[currentLevelIndex].level[0] : null;

    setDeleteInfo({
      id: category.id,
      name: category.name,
      number: category.profiles ? category.profiles.length : 0,
      subCategoriesNumber: category.sub_categories && categoryLevel.position > 0 ? category.sub_categories.length : null,
      canDelete,
      categoryToDeleteLevelInfos: currentLevel,
    });
    toggleModal(false);
    setShow(false);
  };

  const isLowestLevel = () => {
    const currentLevelIndex = getCurrentLevelIndex();
    return currentLevelIndex === level.length - 1;
  };

  const getPreviousLevelCategories = (currentLevelIndex) => {
    if (currentLevelIndex < level.length - 1) {
      return level[currentLevelIndex + 1].level[0].categories;
    }
    return [];
  };

  const handleClickEdit = () => {
    changeCardColor();

    const currentLevelIndex = getCurrentLevelIndex();
    if (currentLevelIndex !== -1) {
      const currentLevel = level[currentLevelIndex].level[0];
      const subCategories = getPreviousLevelCategories(currentLevelIndex);

      setLevelInfo({
        levelId: currentLevel.id,
        levelName: currentLevel.name,
        position: currentLevel.position,
        category,
        subCategories,
        subCategoriesLevelName: subCategories.length > 0 ? level[currentLevelIndex + 1].level[0].name : null,
      });

      toggleFormVisibility('edit');
    }
  };

  const toggleOrganizeModal = (e) => {
    e.stopPropagation();
    setShowOrganizeModal(!showOrganizeModal);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const iconDetail = category.icon && categoryIcons.find((icon) => icon.value === category.icon);
    setSelectedIcon(iconDetail);
  }, [category]);

  const getIconSrc = (subCategory, categoryId) => {
    let src = checkCategoryMediumGrey;
    if (!subCategory.parent_category_id) {
      src = checkCategory;
    } else if (subCategory.parent_category_id === categoryId) {
      src = checkCategory;
    }

    const isNotVisible = !subCategory.parent_category_id;
    return { src, isNotVisible };
  };

  const getIconClass = (isNotVisible) => (isNotVisible ? 'tw-opacity-0' : '');

  const getTextClass = (subCategory, categoryId) => {
    if (!subCategory.parent_category_id) {
      return 'tw-text-black';
    }
    return subCategory.parent_category_id === categoryId ? '' : 'tw-text-greyFive';
  };

  const handleMouseEnter = (subCategory) => {
    if (subCategory.parent_category_id !== category.id && subCategory.parent_category_id !== null) {
      setHoveredSubCategoryId(subCategory.id);
      setTooltipMessage('Cette sous-catégorie est déjà sélectionnée dans une autre catégorie.');
    }
  };

  const handleMouseLeave = () => {
    setHoveredSubCategoryId(null);
    setTooltipMessage('');
  };

  return (
    <div
      ref={cardRef}
      tabIndex={0}
      role="button"
      className="tw-w-full sm:tw-w-64 tw-pl-5 xl:tw-pr-1.5 tw-py-3 tw-border-solid tw-border tw-border-greyBorderLight tw-rounded-[10px] tw-relative tw-bg-white tw-text-start hover:tw-border-secondarySkyBlue"
      onClick={() => handleClickEdit()}
      onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleClickEdit(); }}
    >
      <div ref={modalRef} id={`modal-${category.id}`} className={`tw-absolute tw-border-solid tw-border tw-border-blueVeryLight ${show ? '' : 'tw-hidden'} tw-p-2 tw-right-[5px] xl:-tw-right-[125px] -tw-bottom-3/4 tw-rounded-[6px] tw-bg-white tw-text-xs tw-z-10 tw-transition-all tw-min-w-40 tw-drop-shadow-[0_4px_4px_0_rgba(206,206,206,0.09)]`}>
        <div className="tw-w-full tw-relative tw-flex">
          <div className="tw-w-full tw-flex tw-flex-col">
            {!isLowestLevel() && (
            <button type="button" className="tw-flex tw-items-center tw-border-0 tw-p-2 tw-w-full tw-bg-white tw-rounded-md hover:tw-bg-blueVeryLight" onClick={(e) => toggleOrganizeModal(e)}>
              <img src={folderPlus} alt="Organize" className="tw-mr-2" />
              {t('PARTNER_DASHBOARD.CATEGORIES.CARD.CTA.ORGANIZE')}
            </button>
            )}
            <button type="button" className="tw-flex tw-items-center tw-border-0 tw-p-2 tw-w-full tw-bg-white tw-rounded-md hover:tw-bg-blueVeryLight" onClick={() => handleClickEdit()}>
              <img src={pencilSimple} alt="Pencil" className="tw-mr-2" />
              <span className={showOrganizeModal ? 'tw-text-greyFive' : ''}>{t('PARTNER_DASHBOARD.CATEGORIES.CARD.CTA.EDIT')}</span>

            </button>
            <button type="button" className="tw-flex tw-items-center tw-border-0 tw-p-2 tw-w-full tw-bg-white tw-rounded-md hover:tw-bg-blueVeryLight" onClick={(e) => handleDelete(e)}>
              <img src={trashBin} alt="Trash bin" className="tw-mr-2" />
              <span className={showOrganizeModal ? 'tw-text-greyFive' : ''}>{t('PARTNER_DASHBOARD.CATEGORIES.CARD.CTA.DELETE')}</span>
            </button>
          </div>
          {showOrganizeModal
          && (
          <div className="tw-absolute -tw-top-4 tw-flex tw-flex-col  tw-border-solid tw-border tw-border-blueVeryLight tw-p-2  tw-rounded-[6px] tw-bg-white tw-text-xs tw-z-10 tw-transition-all tw-min-w-40 tw-drop-shadow-[0_4px_4px_0_rgba(206,206,206,0.09)">

            {getPreviousLevelCategories(getCurrentLevelIndex()).map((subCategory) => {
              const { src, isNotVisible } = getIconSrc(subCategory, category.id);
              const isDisabled = subCategory.parent_category_id !== category.id && subCategory.parent_category_id !== null;
              return (
                <div
                  key={subCategory.id}
                  className="tw-w-full tw-relative tw-rounded-md hover:tw-bg-blueVeryLight"
                  onClick={(e) => handleSubCategoryClick(e, subCategory)}
                  onMouseEnter={() => handleMouseEnter(subCategory)}
                  onMouseLeave={handleMouseLeave}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSubCategoryClick(subCategory);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <button
                    type="button"
                    className="tw-flex tw-items-center tw-border-0 tw-p-2 tw-bg-white/0 tw-text-start tw-w-full"
                    disabled={isDisabled}
                  >
                    <img
                      src={src}
                      alt="check icon"
                      width="16px"
                      className={`tw-mr-2 ${getIconClass(isNotVisible)}`}
                    />
                    <span className={`${getTextClass(subCategory, category.id)}`}>
                      {subCategory.name}
                    </span>
                  </button>
                  {hoveredSubCategoryId === subCategory.id && (
                  <div className="sm:tw-w-auto tw-absolute md:tw-top-full tw-min-w-64 md:tw-left-full tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyTwo tw-rounded-sm tw-shadow-lg -tw-m-10">
                    {tooltipMessage}
                  </div>
                  )}
                </div>
              );
            })}
          </div>
          )}
        </div>

      </div>
      <div className="tw-flex tw-full tw-h-full tw-items-center">
        <div className="tw-flex tw-justify-between tw-w-full">
          {(selectedIcon && !isPartnerPrivate) && (
            <div className="tw-flex tw-mr-3 tw-items-center">
              <img src={selectedIcon.icon} alt="" className="tw-h-6 tw-w-6 tw-flex-shrink-0" />
            </div>
          )}
          <div>
            <div className="tw-text-sm text-greyHeavy">
              {category.name}
            </div>
            {level.find((lvl) => lvl.level.some((subLevel) => subLevel.categories && subLevel.categories.some((cat) => cat.id === category.id)))?.level[0].position === 0 ? (
              <div className="tw-text-greyDark tw-text-xs tw-mt-1">
                {category.profiles ? category.profiles.length : 0} {t('PARTNER_DASHBOARD.CATEGORIES.CARD.COMPANY')}{category.profiles && category.profiles.length > 1 ? 's' : ''} {t('PARTNER_DASHBOARD.CATEGORIES.CARD.LINKED')}{category.profiles && category.profiles.length > 1 ? 's' : ''}
              </div>
            ) : (
              <div className="tw-text-greyDark tw-text-xs tw-mt-1">
                {category.sub_categories ? category.sub_categories.length : 0} {t('PARTNER_DASHBOARD.CATEGORIES.CARD.LINKED_SUBCATEGORIES')}{category.sub_categories && category.sub_categories.length > 1 ? 's' : ''}
              </div>
            )}
          </div>
          <button ref={buttonRef} type="button" className="tw-border-0 tw-px-0 tw-ml-4 tw-bg-white" onClick={(e) => onClick(e)}>
            <img src={dotsThreeVertical} alt="Three vertical dots" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategoryCard;
