const deleteQuestionAnswerItem = async (id, api, profileId, getAnswer) => {
  try {
    const response = await api.delete(`${id}?profile_id=${profileId}`);

    if (api.response.ok) {
      getAnswer();
      return response;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default deleteQuestionAnswerItem;
