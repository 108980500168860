import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import chevronDown from '../../../../assets/ui/chevron-down-grey.svg';
import crossModal from '../../../../assets/ui/cross-modal.svg';
import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';

export default function SubCategorySelector({
  subCategories,
  selectedSubCategories,
  setSelectedSubCategories,
  subCategoriesError,
  setSubCategoriesError,
  subCategoriesRef,
  subCategoriesLevelName,
  editedCategoryId,
}) {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [tooltipId, setTooltipId] = useState(null);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCheckboxChange = (subCategory) => {
    setSubCategoriesError('');
    if (selectedSubCategories.some((sub) => sub.id === subCategory.id)) {
      setSelectedSubCategories(selectedSubCategories.filter((sub) => sub.id !== subCategory.id));
    } else {
      setSelectedSubCategories([...selectedSubCategories, subCategory]);
    }
  };

  const removeSubCategory = (subCategoryId) => {
    setSelectedSubCategories(selectedSubCategories.filter((item) => item.id !== subCategoryId));
  };

  // affichage tooltip au hover si sous catégorie déjà sélectionnée
  const handleMouseEnter = (subCategory) => {
    if (subCategory.parent_category_id !== null && subCategory.parent_category_id !== editedCategoryId) {
      setTooltipId(subCategory.id);
      setTooltipMessage(t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.SUBCATEGORIES_TOOLTIP'));
    }
  };

  const handleMouseLeave = () => {
    setTooltipId(null);
  };

  return (
    <div ref={dropdownRef}>
      <h2 className="tw-text-base tw-mb-2">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_E')}
      </h2>
      <div className="tw-relative tw-mb-9">
        <button
          type="button"
          ref={subCategoriesRef}
          className={`tw-h-11 tw-w-full  tw-text-left tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-greyHeavy tw-border tw-border-solid ${subCategoriesError ? 'tw-border-2 tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border' : 'tw-border-greyLight tw-bg-white'}  focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-p-2 tw-flex tw-items-center tw-justify-between`}
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={showDropdown}
        >
          <span className="tw-flex tw-items-center tw-grow">
            {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.SUBCATEGORY_PLACEHOLDER')}
          </span>
          <img
            src={chevronDown}
            alt="Toggle dropdown"
            className="tw-ml-4 tw-h-5 tw-w-5"
          />
        </button>
        <div className="tw-flex tw-flex-wrap tw-gap-2 tw-list-none tw-p-0 tw-mt-4">
          {selectedSubCategories.map((category) => (
            <div key={category.id} className="tw-py-1 tw-px-4 tw-bg-greyLight tw-border tw-border-solid tw-border-primaryNewBlue/30 tw-text-sm tw-text-greyHeavy tw-font-moskauGrotesk tw-font-normal tw-rounded-full ">
              {category.name}
              <button
                type="button"
                onClick={() => removeSubCategory(category.id)}
                className="tw-border-none tw-p-0 tw-ml-2"
              >
                <img
                  src={crossModal}
                  alt="delete resource"
                  width="16px"
                  className="tw-mb-[1px]"
                />
              </button>
            </div>
          ))}
        </div>
        {subCategoriesError && (
          <span className=" tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-red tw-mb-4">
            <img
              src={exclamationMark}
              alt="exclamation mark"
              className=" tw-mr-1 -tw-mt-[3px]"
            />
            {subCategoriesError}
          </span>
        )}
        {showDropdown && (
          <div
            className="tw-absolute tw-top-11 tw-z-10 tw-w-full tw-max-h-72 tw-flex tw-flex-col tw-overflow-auto tw-rounded-md tw-bg-white tw-py-4 tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-text-sm tw-border tw-border-solid tw-border-greyLight tw-shadow-customDropdown"
            role="listbox"
            aria-label="Subcategories"
          >
            <p className="tw-text-sm tw-text-greyFive tw-font-moskauGrotesk tw-font-normal tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greyLight tw-pl-4 tw-pb-2 tw-mb-0">
              {subCategoriesLevelName}
            </p>
            {subCategories && subCategories.map((subCategory) => {
              const isDisabled = subCategory.parent_category_id !== null && subCategory.parent_category_id !== editedCategoryId;
              return (
                <div
                  key={subCategory.id}
                  className="tw-relative tw-border-b tw-border-greyLight"
                  onMouseEnter={() => handleMouseEnter(subCategory)}
                  onMouseLeave={handleMouseLeave}
                >
                  <label
                    className={`tw-flex tw-items-center hover:tw-bg-backgroundCleanBlue tw-cursor-pointer tw-text-greyTwo tw-font-moskauGrotesk tw-font-normal tw-rounded ${isDisabled ? 'tw-opacity-50' : ''}  tw-pl-4 tw-py-3`}
                  >
                    <input
                      type="checkbox"
                      className="tw-mr-2 tw-accent-primaryNewBlue"
                      checked={subCategory.parent_category_id}
                      onChange={() => handleCheckboxChange(subCategory)}
                      disabled={isDisabled}
                    />
                    {subCategory.name}
                  </label>
                  {tooltipId === subCategory.id && (
                    <div className="tw-absolute tw-left-32 xl:tw-left-40 tw-top-0 tw-ml-2 tw-w-64 tw-p-2 tw-text-xs tw-text-white tw-bg-greyTwo tw-rounded-sm tw-shadow-lg">
                      {tooltipMessage}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
