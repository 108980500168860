import React from 'react';
import { Link } from 'react-router-dom';

import getLocalLink from '../../../../utils/getLocalLink';
import RightArrow from '../../../../assets/ui/right-arrow.svg';

import './Results.scss';

function Results({ profiles, handleClick }) {
  return profiles && profiles.length > 0 && (
    <div className="search-result list-group bg-white">
      {profiles.map((profile, index) => (
        <Link key={index} to={getLocalLink(`/profile/${profile.slug}`)} className="d-flex align-items-center justify-content-between font-14 p-1 text-decoration-none black-color primary-LLL-hover" onClick={() => handleClick()}>
          <div className="d-flex align-items-center">
            <img src={`${process.env.REACT_APP_FILES_DOMAIN}${profile.logo_url}`} alt={`search-result ${profile.name}`} width="40" height="40" className="me-1" />
            <span className="me-2">{profile.name}</span>
            {profile.is_managed ? (
              <span className="align-top primary-color d-inline-block">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" width="18" height="18">
                  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                </svg>
              </span>
            ) : ''}
          </div>
          <img src={RightArrow} alt="right arrow" className="float-end me-3" />
        </Link>
      ))}
    </div>
  );
}

export default Results;
