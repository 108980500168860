import React from 'react';
import { useOutletContext, Outlet, useLocation } from 'react-router-dom';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import './Public.scss';

function Public() {
  const { partner, features, session } = useOutletContext();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      <Header partner={partner} session={session} features={features} searchBar={!isHomePage} />
      <Outlet context={{ partner, features, session }} />
      <Footer partner={partner} features={features} />
    </>
  );
}

export default Public;
