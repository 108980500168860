import React, { useEffect, useState } from 'react';
import { useParams, useLoaderData } from 'react-router-dom';
import { useFetch } from 'use-http';

function ResourcePreview() {
  const [pdfUrl, setPdfUrl] = useState();
  const params = useParams();
  const resourceId = params.resource_id;
  const { partner } = useLoaderData();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/resource-file/${resourceId}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
    responseType: 'blob',
  });

  const getResourceFile = async () => {
    try {
      const blob = await get();
      if (response.ok) {
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getResourceFile();
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []);

  const fullScreenStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    border: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    zIndex: 999999,
  };

  return (
    <div style={fullScreenStyle}>
      {pdfUrl && (
        <embed
          src={pdfUrl}
          type="application/pdf"
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </div>
  );
}

export default ResourcePreview;
