const getOpenClimatComment = (score) => {
  switch (score) {
    case 1:
      return 'OPEN_CLIMAT_COMMENT.ONE';
    case 2:
      return 'OPEN_CLIMAT_COMMENT.TWO';
    case 3:
      return 'OPEN_CLIMAT_COMMENT.THREE';
    case 4:
      return 'OPEN_CLIMAT_COMMENT.FOUR';
    case 5:
      return 'OPEN_CLIMAT_COMMENT.FIVE';
    case 6:
      return 'OPEN_CLIMAT_COMMENT.SIX';
    default:
      return 'OPEN_CLIMAT_COMMENT.ONE';
  }
};

export default getOpenClimatComment;
