import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getAudit from '../../../../utils/getAudit';

import getUserLocal from '../../../../utils/getUserLocal';

function Audit({ profile }) {
  const [items, setItems] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    getAudit(profile.id, getUserLocal()).then((response) => {
      setItems(response);
    });
  }, [profile]);

  return (
    (items?.name && items?.data) ? (
      <div className="p-3 p-xl-4 white-bg mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
        <h4 className="tw-text-greyOne tw-text-base d-flex align-items-middle justify-content-start mb-3">
          {t('AUDIT_AND_CONSEIL_TITLE')}
        </h4>
        <div className="row mx-0 font-14">
          <div className="col px-0">
            <p className="font-14">
              <span className="tw-text-greyDark">{t('AUDIT_AND_CONSEIL_TEXT_1')} </span><br />
              <span className="tw-text-greyDarkHeavy">{items?.name}</span>
            </p>
            <p className="font-14">
              <span className="tw-text-greyDark">{t('AUDIT_AND_CONSEIL_TEXT_2')} </span><br />
              <span className="tw-text-greyDarkHeavy">{items?.data}</span>
            </p>
            {items?.url
              && (
              <a href={items?.url} className="font-14 tw-text-greyFour" target="_blank" rel="noreferrer">
                {t('AUDIT_AND_CONSEIL_TEXT_3')}
              </a>
              )}
          </div>
        </div>
      </div>
    ) : ('')
  );
}

export default Audit;
