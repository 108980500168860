const getLabelsClimat = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${id}/labels-climat`);

    if (response.status === 200) {
      const result = await response.json();

      return result;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default getLabelsClimat;
