import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import cross from '../../assets/ui/cross-modal.svg';

import useModal from '../../hooks/useModal';

/**
 * TemplateFormModal component
 * @component
 * @param {Object} props - The props object for TemplateFormModal component
 * @param {string} props.modalId - The ID of the modal, used for handling its visibility and animations
 * @param {string} props.formId - The ID of the form that the modal contain
 * @param {string} props.title - The title of the modal
 * @param {string} [props.submitButtonText] - Optional. The text on the submit button
 * @param {string} [props.cancelButtonText] - Optional. The text on the cancel button
 * @returns {JSX.Element} - A modal component that can be controlled through a context, supports dynamic content, title, and a form submission
 * @example
 * <TemplateFormModal
 *  modalId="audit-modal"
 *  formId="audit-form"
 *  title="Éditer les informations d'audit"
 * >
 *  <form
 *   id="audit-form"
 *   onSubmit={handleSubmit(onSubmit)}
 *   noValidate
 *  >
 *  </form>
 * </TemplateFormModal>
 *
 * To use it you can wrap your form inside the TemplateFormModal component and pass the modalId, formId, title, submitButtonText, and cancelButtonText as props
 * You can open the modal by calling the open function from the useModal hook and passing the modalId as an argument
 * You can close the modal by calling the close function from the useModal hook
 * To handle the form submission you need to pass the formId to the form template
 */
export default function TemplateFormModal({ children, modalId, formId, title, submitButtonText, cancelButtonText }) {
  const { openModal, close } = useModal();
  const isModalOpen = openModal === modalId;
  const modalRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        const modalContent = document.getElementById(`modal-content-${modalId}`);
        if (modalContent) {
          modalContent.style.opacity = '1';
          modalContent.style.transform = 'translateY(0)';
          modalContent.style.transition = 'transform 0.5s ease-out, opacity 0.5s ease-out';
        }
      }, 50);
    } else {
      const modalContent = document.getElementById(`modal-content-${modalId}`);
      if (modalContent) {
        modalContent.style.opacity = '0';
        modalContent.style.transform = 'translateY(-20px)';
        modalContent.style.transition = 'none';
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        close();
      }
    };

    const handleBodyScroll = () => {
      if (isModalOpen) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.style.paddingRight = '';
        document.body.classList.remove('overflow-hidden');
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      handleBodyScroll();
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      handleBodyScroll();
    }

    return () => {
      document.body.style.paddingRight = '';
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  return (
    <div
      id={modalId}
      tabIndex="-1"
      aria-hidden={!isModalOpen ? 'true' : undefined}
      inert={!isModalOpen ? 'true' : undefined}
      className={`tw-flex ${isModalOpen ? 'tw-visible' : 'tw-hidden'} tw-overflow-y-hidden tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-z-[50000] tw-justify-center tw-items-center tw-w-full tw-h-full tw-max-h-full tw-bg-greyOne/50`}
    >
      <div
        id={`modal-content-${modalId}`}
        ref={modalRef}
        className={`tw-relative tw-my-4 tw-w-full tw-max-w-2xl tw-h-auto tw-transition-all tw-transform ${isModalOpen ? '' : ''} tw-duration-1000 tw-ease-in-out tw-mx-5`}
      >
        <div className="tw-relative tw-bg-white tw-rounded-lg tw-shadow tw-overflow-y-auto tw-max-h-screen">
          <div className="tw-flex tw-items-center tw-justify-between tw-p-4 md:tw-p-5 tw-border-b tw-rounded-t tw-border-greySix tw-sticky tw-top-0 tw-bg-white tw-border-solid tw-border-t-0 tw-border-x-0">
            <h3 className="tw-text-xl tw-font-semibold tw-text-gray-900 tw-mb-0">
              {title}
            </h3>
            <button
              type="button"
              className="tw-text-gray-400 tw-bg-transparent tw-rounded-lg tw-text-sm tw-w-8 tw-h-8 tw-ms-auto tw-inline-flex tw-justify-center tw-items-center tw-border-0"
              data-modal-hide="default-modal"
              onClick={() => close()}
            >
              <img src={cross} alt="cross" />
              <span className="tw-sr-only">Close modal</span>
            </button>
          </div>
          {children}
          <div className="tw-flex tw-items-center tw-justify-end tw-p-4 md:tw-p-5 tw-border-t tw-border-greySix tw-gap-3 tw-rounded-b tw-border-solid tw-border-x-0 tw-border-b-0">
            <button
              data-modal-hide={modalId}
              type="button"
              className="tw-py-2.5 tw-px-7 tw-text-sm tw-text-primaryNewBlue tw-border-0 tw-bg-white"
              onClick={() => close()}
            >
              {cancelButtonText || t('CANCEL')}
            </button>
            <button
              form={formId}
              data-modal-hide={modalId}
              type="submit"
              className="tw-text-white tw-bg-primaryNewBlue hover:tw-bg-white hover:tw-text-primaryNewBlue tw-text-sm tw-rounded-md tw-px-7 tw-py-2.5 tw-border tw-border-primaryNewBlue"
            >
              {submitButtonText || t('SAVE')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
