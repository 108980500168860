import React from 'react';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';

import caretLeftPrimaryBlue from '../../../../../assets/ui/caret-left-primary-blue.svg';

function NoteDetails({ setSelectedNote, selectedNote }) {
  const { t } = useTranslation();

  return (
    <div className="tw-p-6 tw-border tw-border-solid tw-border-greySeven tw-rounded">
      <button
        type="button"
        className="tw-text-greyFour tw-text-sm tw-flex tw-gap-3 tw-items-center tw-bg-white tw-border-0 tw-px-0 hover:tw-text-greyThree"
        onClick={() => {
          setSelectedNote();
        }}
      >
        <img src={caretLeftPrimaryBlue} alt="Caret left" />
        <span className="tw-text-primaryNewBlue tw-pt-0.5">
          {selectedNote?.title}
        </span>
      </button>
      <div className="tw-mt-[22px] tw-text-greyFour tw-text-sm">
        {t('NOTES.PUBLISHED_ON')} {selectedNote ? new Date(selectedNote.created_at).toLocaleDateString() : null} {t('NOTES.BY')} {selectedNote?.firstname} {selectedNote?.lastname}
      </div>
      <div className="tw-mt-[20px] tw-text-greyTwo tw-text-sm tw-break-words">
        <Markdown>{selectedNote?.note}</Markdown>
      </div>
    </div>
  );
}

export default NoteDetails;
