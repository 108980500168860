import React, { useState, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import ProfileResults from './ProfileResults';

import magnifyingGlass from '../../../../../assets/ui/magnifying_glass_grey.svg';
import deleteCross from '../../../../../assets/ui/delete-cross.svg';

function ProfileSearch({ partner }) {
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState('');
  const [searchDone, setSearchDone] = useState(false);

  const { t } = useTranslation();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/profiles/search`, {
    credentials: 'include',
  });

  useEffect(() => {
    const delay = 500;
    let timeout;

    if (query.length >= 1) {
      timeout = setTimeout(async () => {
        try {
          const data = await get(`?query=${query}&domain=${partner.domain}`);
          if (response.ok) {
            setItems(data);
            setSearchDone(true);
          }
        } catch (error) {
          throw error;
        }
      }, delay);
    } else {
      setItems([]);
      setSearchDone(false);
    }

    return () => clearTimeout(timeout);
  }, [query, get, response.ok]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="tw-relative tw-flex tw-items-center tw-h-10 tw-w-56 tw-text-xs">
      {query.length > 0 && (
        <div className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none">
          <img
            src={magnifyingGlass}
            alt="Search icon"
            className="tw-w-4 tw-h-4"
          />
        </div>
      )}
      <input
        type="text"
        className={`tw-w-full tw-h-full ${query.length > 0 ? 'tw-px-8' : 'tw-px-4'}  tw-py-2 tw-pr-10 tw-border tw-border-solid tw-border-greySeven tw-rounded-lg tw-text-greyFour placeholder:tw-text-greyFour focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-secondarySkyBlue focus:tw-border-transparent`}
        placeholder={t('PROFILE_FILTER')}
        value={query}
        onChange={handleInputChange}
      />
      {
        query.length > 0 ? (
          <button
            type="button"
            className="tw-absolute tw-right-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-border-none tw-bg-white"
            onClick={() => setQuery('')}
          >
            <img
              src={deleteCross}
              alt="Delete icon"
            />
          </button>
        ) : (
          <div className="tw-absolute tw-right-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none">
            <img
              src={magnifyingGlass}
              alt="Search icon"
              className="tw-w-5 tw-h-5"
            />
          </div>
        )
      }
      {searchDone && (
        <ProfileResults items={items} query={query} />
      )}
    </div>
  );
}

export default ProfileSearch;
