const chartDataBuilder = (carbonFootprintDistribution, t) => {
  const colorsScope12 = ['#00E0FF', '#00E0FF', '#00E0FF', '#00E0FF'];
  const colorsScope3 = ['#000D85', '#333D9D', '#666EB6', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA'];
  let data = [];
  let dataFiltered = [];

  if (carbonFootprintDistribution.length > 0) {
    carbonFootprintDistribution.sort((a, b) => b.scope - a.scope).sort((a, b) => {
      if (a.scope === 3 && b.scope === 3) {
        return b.percent - a.percent;
      }
      return true;
    });
    let scope3acc = 0;
    let scope12acc = 0;

    data = carbonFootprintDistribution.map((data) => {
      if (data.scope === 12) {
        scope12acc += 1;
      } else {
        scope3acc += 1;
      }

      return {
        scope: Number(data.scope),
        value: data.percent,
        color: data.scope === 12 ? colorsScope12[scope12acc - 1] : colorsScope3[scope3acc - 1],
        id: data.name,
      };
    });
  }

  const totalPercent = data.reduce((acc, item) => acc + Number(item.value), 0);

  if (totalPercent < 100) {
    data = [
      ...data,
      {
        color: '#F4F4F4',
        id: t('NOT_COMMUNICATED'),
        scope: 3,
        value: (100 - totalPercent) % 1 !== 0 ? (100 - totalPercent).toFixed(1) : 100 - totalPercent,
      },
    ];
  }

  let scope12total = 0;
  let scopeAcc = 0;
  let scope3other = 0;

  data.map((item) => {
    if (item.scope === 12) {
      scopeAcc += item.value && parseFloat(item.value);
      scope12total += item.value && parseFloat(item.value);
    }
    return true;
  });

  dataFiltered = [
    {
      scope: 12,
      value: Math.ceil(scope12total),
      color: '#00E0FF',
      id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10'),
    },
  ];

  const dataScope3 = data.filter((item) => item.scope === 3);

  dataScope3.map((item, index) => {
    if (dataScope3.length > 4) {
      if (index >= 0 && index < 3 && item.id !== t('NOT_COMMUNICATED') && item.value) {
        scopeAcc += item.value && parseFloat(item.value);
        dataFiltered.push(
          {
            scope: 3,
            value: item.value && Math.ceil(parseFloat(item.value)),
            color: item.color,
            id: item.id,
          },
        );
      }
      if (index >= 3 && item.id !== t('NOT_COMMUNICATED') && item.value) {
        scope3other += item.value && parseFloat(item.value);
      }
      if (item.id === t('NOT_COMMUNICATED')) {
        scope3other += item.value && parseFloat(item.value);
      }
    } else {
      if (index >= 0 && index < 4 && item.id !== t('NOT_COMMUNICATED') && item.value) {
        scopeAcc += item.value && parseFloat(item.value);
        dataFiltered.push(
          {
            scope: 3,
            value: item.value && Math.ceil(parseFloat(item.value)),
            color: item.color,
            id: item.id,
          },
        );
      }
      if (item.id === t('NOT_COMMUNICATED')) {
        scope3other += item.value && parseFloat(item.value);
      }
    }
    return true;
  });

  if (scopeAcc < 100) {
    dataFiltered.push(
      {
        scope: 3,
        value: Math.ceil(scope3other),
        color: '#B3B6DA',
        id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_11'),
      },
    );
  }

  return [data, dataFiltered];
};

export default chartDataBuilder;
