import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';
import searchIcon from '../../../../assets/ui/search-icon.svg';

function ProfileSelector({ profiles, selectedProfiles, setSelectedProfiles, profileError, setProfileError, profileSearchRef }) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedSelectedProfiles, setSortedSelectedProfiles] = useState([]);
  const [sortedUnselectedProfiles, setSortedUnselectedProfiles] = useState([]);

  const handleProfileChange = (profileId) => {
    setProfileError('');

    setSelectedProfiles((prev) => {
      if (prev.includes(profileId)) {
        return prev.filter((id) => id !== profileId);
      }
      return [...prev, profileId];
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const sortedProfiles = profiles.sort((a, b) => a.name.localeCompare(b.name));

    const filteredProfiles = sortedProfiles.filter((profile) => profile.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const selected = filteredProfiles.filter((profile) => selectedProfiles.includes(profile.id));
    const unselected = filteredProfiles.filter((profile) => !selectedProfiles.includes(profile.id));
    setSortedSelectedProfiles(selected);
    setSortedUnselectedProfiles(unselected);
  }, [profiles, selectedProfiles, searchTerm]);

  const handleKeyPress = (event, profileId) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      handleProfileChange(profileId);
    }
  };

  return (
    <div>
      <h2 className=" tw-text-base tw-mb-2">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_C')}
      </h2>
      <div className=" tw-flex tw-flex-col">
        <div className="tw-w-full tw-flex tw-items-center tw-relative tw-mb-4">
          <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
            <img src={searchIcon} alt="Search" className="tw-w-5 tw-h-5" />
          </div>
          <input
            type="text"
            placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.PROFILE_PLACEHOLDER')}
            value={searchTerm}
            onChange={handleSearchChange}
            ref={profileSearchRef}
            className={`tw-w-full tw-h-11 ${
              profileError
                ? ' tw-border-2 tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border'
                : 'tw-bg-backgroundCleanBlue'
            }  tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-greyHeavy tw-border tw-border-solid tw-border-greyLight focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-full tw-p-2 tw-pl-10`}
          />
        </div>
        {profileError && (
          <span className=" tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-red tw-mb-4">
            <img
              src={exclamationMark}
              alt="exclamation mark"
              className=" tw-mr-1 -tw-mt-[3px]"
            />
            {profileError}
          </span>
        )}
      </div>

      <div className=" tw-w-full tw-max-h-80 tw-overflow-y-scroll">
        {sortedSelectedProfiles.length !== 0 && (
          <span className=" tw-text-xs tw-font-normal tw-font-moskauGrotesk tw-text-greyDark">
            {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.PROFILE_SELECTED')}
          </span>
        )}
        {sortedSelectedProfiles.map((profile) => (
          <div
            key={profile.id}
            className="tw-flex tw-items-center tw-gap-x-2 tw-py-3 tw-cursor-pointer hover:tw-bg-backgroundCleanBlue"
            onClick={() => handleProfileChange(profile.id)}
            onKeyDown={(event) => handleKeyPress(event, profile.id)}
            tabIndex={0}
            role="checkbox"
            aria-checked
            style={{ outline: 'none' }}
          >
            <input
              type="checkbox"
              checked
              readOnly
              className="tw-w-4 tw-h-4 tw-accent-primaryNewBlue tw-border tw-border-solid tw-border-greyLight checked:tw-bg-primaryNewBlue tw-rounded-sm tw-ml-2"
            />
            <span className="tw-text-xs tw-text-greyHeavy">{profile.name}</span>
          </div>
        ))}
        {sortedSelectedProfiles.length !== 0 && (
          <hr className="tw-my-2  tw-border-greyFive" />
        )}
        {sortedUnselectedProfiles.map((profile) => (
          <div
            key={profile.id}
            className="tw-flex tw-items-center tw-gap-x-2 tw-py-3 tw-cursor-pointer hover:tw-bg-backgroundCleanBlue"
            onClick={() => handleProfileChange(profile.id)}
            onKeyDown={(event) => handleKeyPress(event, profile.id)}
            tabIndex={0}
            role="checkbox"
            aria-checked={false}
            style={{ outline: 'none' }}
          >
            <input
              type="checkbox"
              checked={false}
              readOnly
              className="tw-w-4 tw-h-4 tw-accent-primaryNewBlue tw-border tw-border-solid tw-border-greyLight tw-rounded-sm tw-ml-2"
            />
            <span className="tw-text-xs tw-text-greyHeavy">{profile.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfileSelector;
