import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import eye from '../../../../../assets/ui/eye.svg';
import eyeSlash from '../../../../../assets/ui/eye-slash.svg';

import Question from './Question';

function QuestionContainer({ profile, partner, questions, hasWriteAccess, fetchData, setFormContext, view }) {
  const [checked, setChecked] = useState(false);

  const updatePublishing = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profile.id}/private-questions/publishing`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { t } = useTranslation();

  const handleCheck = async () => {
    setChecked(!checked);
    const data = {};
    data.questions = questions;
    data.check = !checked;
    await updatePublishing.post('', data);
    fetchData();
  };

  return (
    <section id={partner.name} className="question-container">
      <div className="p-3 p-xl-4 pb-1 white-bg mb-4 tw-rounded tw-border tw-border-solid tw-border-greySix">
        {hasWriteAccess ? (
          <>
            <div className="d-flex justify-content-between h-100">
              {checked ? (
                <div>
                  <div className="font-14 fw-600 grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.TITLE_PUBLISHED')}
                  </div>
                  <div className="font-12 text-muted grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.SUBTITLE_PUBLISHED')}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="font-14 fw-600 grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.TITLE_NOT_PUBLISHED')}
                  </div>
                  <div className="font-12 text-muted grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.SUBTITLE_NOT_PUBLISHED')}
                  </div>
                </div>
              )}
              <div className="form-check form-switch ms-5 mb-0 d-flex align-items-center">
                {updatePublishing.loading ? (
                  <>
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="ms-2">
                      <img className="align-self-center" src={checked ? eye : eyeSlash} alt={checked ? 'eye' : 'eye slash'} />
                    </div>
                  </>
                ) : (
                  <>
                    <input className="form-check-input private-questions-publishing-switch" type="checkbox" id="flexSwitchCheckDefaultPrivateQuestions" checked={checked} onChange={handleCheck} />
                    <div className="ms-2">
                      <img className="align-self-center" src={checked ? eye : eyeSlash} alt={checked ? 'eye' : 'eye slash'} />
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr className="mt-3 mb-2" />
          </>
        ) : (
          ''
        )}
        <h3>
          <span>
            <img src={process.env.REACT_APP_FILES_DOMAIN + partner.logo_header} alt={`Logo ${partner.name}`} height={30} />
          </span>
          <span className="ms-2 font-16 fw-600">
            {t('PRIVATE_ANSWERS.QUESTIONS.FROM')} {partner.name}
          </span>
        </h3>
        {questions.map((question, index) => (
          <Question
            key={index}
            index={index}
            question={question}
            setFormContext={setFormContext}
            profile={profile}
            partner={partner}
            hasWriteAccess={hasWriteAccess}
            setChecked={setChecked}
            view={view}
            fetchData={fetchData}
          />
        ))}
      </div>
    </section>
  );
}

export default QuestionContainer;
