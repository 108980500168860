import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import './Presentation.scss';

import Features from '../../../features.json';
import getContactUrl from '../../../utils/getPartnerContactUrl';

function Presentation({ partner, features }) {
  const [contactUrl, setContactUrl] = useState();

  const { t } = useTranslation();

  const { get } = useFetch(process.env.REACT_APP_PARTNER_API);

  const fetchData = async () => {
    const url = await getContactUrl(get, partner.id);
    setContactUrl(url);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="container mt-4">
      <div className="row mb-5 border-20 white-bg mx-0">
        <div className="col-12 col-md-12 p-4 my-auto">
          <div className="">
            <h2 className="font-30 fw-600 mb-3 new-branding-primary-color">{partner.presentation_title}</h2>
            <div className="font-14 fw-400">{parse(partner.presentation_content)}</div>
            {features.includes(Features.contactInformations) && contactUrl ? (
              <a href={contactUrl} className="text-decoration-none new-branding-primary-color" target="_blank" rel="noreferrer">
                {t('PARTNER_CONTACT_US')}
              </a>
            ) : ('')}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Presentation;
