/**
 * Fetch all categories from partner
 * @param {string} partnerId
 * @param {function} get
 * @returns {response}
 */
const getPartnerCategories = async (get) => {
  try {
    const response = await get();

    return response;
  } catch (error) {
    throw error;
  }
};

export default getPartnerCategories;
