import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import getLocalLink from '../../../utils/getLocalLink';

import './Login.scss';

function Login({ partner }) {
  const { t } = useTranslation();

  return (
    <section className="container">
      <div className={`row mb-5 border-20 white-bg ${isMobile ? 'mx-0' : ''}`}>
        <div className="col-md-6 d-flex align-items-center p-4">
          <div className="col-12 login-left-text mx-auto text-end">
            <div className="col-12 mx-auto">
              {t('PARTNER_LOGIN_TEXT_4')} {t('PARTNER_LOGIN_TEXT_5')} {partner.name} ?
            </div>
            <div className="col-12 mx-auto">
              {t('PARTNER_LOGIN_TEXT_6')}
            </div>
            <div className="col-12 mx-auto">
              <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.8479 0.308594L37.6037 16.0643L21.7965 31.8716L19.2091 29.2841L30.6688 17.8245L0.643921 17.8945L0.649895 14.2283L30.5694 14.1795L19.2732 2.88333L21.8479 0.308594Z" fill="#031356" />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-md-6 p-4 px-0">
          <div className="col-12 login-button text-center">
            <Link to={getLocalLink('/login')} className="btn text-white btn-connexion-link">
              {t('PARTNER_LOGIN_TEXT_10')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
