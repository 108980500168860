import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from './Widget';

function Details({ scope, scopeColor, unitText, getColorByScore, getClimateAlignementInfos, getHexColorWithOpacity }) {
  const { t } = useTranslation();

  return (
    <>
      {scope?.degreeScore >= 3 ? (
        <>
          <div className="tw-col-span-1 tw-text-center">
            <div className="tw-text-lg tw-text-greyOne tw-font-moskauGrotesk tw-font-semibold">
              {scope?.percentDelta > 0 ? '+' : ''}{scope?.percentDelta}%
            </div>
            <div className="tw-text-xs tw-text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
            <div className="tw-text-xs tw-text-greyTwo">
              {t('CLIMATE_ACTIONS.BETWEEN')} {scope?.startYear} {t('CLIMATE_ACTIONS.AND')} {scope?.endYear}
            </div>
          </div>
          <div className="tw-col-span-1 tw-text-center tw-text-greyOne tw-text-lg tw-flex tw-flex-col tw-justify-center">
            <div className="tw-self-center tw-font-moskauGrotesk tw-font-semibold">
              {(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2) > 0 ? '+' : ''}{(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2)}% / {t('CLIMATE_ACTIONS.YEAR')}
            </div>
            <div className="tw-text-xs tw-text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      <Widget
        getColorByScore={getColorByScore}
        getClimateAlignementInfos={getClimateAlignementInfos}
        getHexColorWithOpacity={getHexColorWithOpacity}
        degreeScore={scope?.degreeScore}
        color={scopeColor}
      />
    </>
  );
}

export default Details;
