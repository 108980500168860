import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cross from '../../../../assets/ui/cross-modal.svg';

function ModalText({ deleteInfo, t }) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (deleteInfo?.canDelete.canDelete === true) {
      if (deleteInfo?.subCategoriesNumber) {
        setText(
          <div className="tw-text-sm tw-text-greyDark tw-mt-4">
            {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_2.PART_A')} {deleteInfo?.subCategoriesNumber} {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_2.PART_B')}{deleteInfo?.subCategoriesNumber > 1 ? 's' : ''}. {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_2.PART_C')}
          </div>,
        );
      } else {
        setText(
          <div className="tw-text-sm tw-text-greyDark tw-mt-4">
            {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE.PART_A')}{deleteInfo?.number}{t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE.PART_B')}{deleteInfo?.number > 1 ? 's' : ''}. {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE.PART_C')}
          </div>,
        );
      }
    } else {
      setText(
        <div className="tw-text-sm tw-text-greyDark tw-mt-4">
          {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_3')} "{deleteInfo?.canDelete.parentCategoryName}".
        </div>,
      );
    }
  }, [deleteInfo]);

  return (
    text
  );
}

function DeleteConfirmationModal({ deleteModalRef, deleteInfo, setDeleteInfo, toggleModal, backgroundDeleteModalRef }) {
  const { t } = useTranslation();

  const handleClickOutside = (event) => {
    if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
      const deleteModal = document.getElementById('delete-modal');
      if (!deleteModal.classList.contains('tw-hidden')) {
        deleteModal.classList.add('tw-hidden');
        setDeleteInfo(null);
      }
    }
  };

  const handleCancel = () => {
    setDeleteInfo(null);
    toggleModal();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      id="delete-modal"
      className="tw-fixed tw-z-40 tw-overflow-y-auto tw-top-0 tw-w-full tw-left-0 tw-hidden"
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 sm:tw-p-0">
        <div className="tw-fixed tw-inset-0 tw-transition-opacity">
          <div ref={backgroundDeleteModalRef} className="tw-absolute tw-inset-0 tw-backdrop-blur-sm tw-bg-greyBlur" />
        </div>
        <span className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen">&#8203;</span>
        <div ref={deleteModalRef} className="tw-inline-block tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-max-w-lg sm:tw-w-full tw-p-7 tw-justify-self-end tw-right-full md:tw-mx-10 md:tw-mt-20" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="tw-bg-white tw-text-xl">
            <div className="tw-flex tw-justify-between">
              <div>
                {deleteInfo?.canDelete.canDelete === true ? (
                  t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.TITLE')
                ) : (
                  t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.TITLE_ALT')
                )}
              </div>
              <button type="button" className="tw-bg-white tw-border-0 tw-flex" onClick={() => { setDeleteInfo(null); toggleModal(); }}>
                <img src={cross} alt="cross" className="tw-float-right tw-cursor-pointer" />
              </button>
            </div>
            <ModalText deleteInfo={deleteInfo} t={t} />
          </div>
          <div className="tw-mt-9">
            <button
              type="button"
              className="tw-py-2 tw-px-8 tw-bg-white tw-mr-3.5 tw-border-primaryNewBlue tw-rounded-[100px]"
              onClick={() => handleCancel()}
            >
              {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.CTA.CANCEL')}
            </button>
            {deleteInfo?.canDelete.canDelete === true ? (
              <button
                type="button"
                className="tw-py-2 tw-px-12 tw-bg-primaryNewBlue tw-border-0 tw-text-white tw-rounded-[100px] tw-font-medium hover:tw-bg-blue-700 tw-mr-2 tw-transition tw-duration-500"
                onClick={() => toggleModal(true)}
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.CTA.DELETE')}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
