import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

function Tooltip({ id, value, color }) {
  return (
    <div
      style={{
        padding: 12,
        color,
        background: 'white',
      }}
    >
      <div>
        {id}: {value}%
      </div>
    </div>
  );
}

function CategoryChart({ category, colorsObjects }) {
  const [data, setData] = useState();
  const [colors, setColors] = useState();
  const [chartKeys, setChartKeys] = useState();

  const getColor = (bar) => getHexColorWithOpacity(colors[bar.id], 1);

  useEffect(() => {
    const scores = [];
    if (category.scores) {
      scores.push(category.scores);
    }

    const data = [
      {
        Catégorie: category.name,
        ...(category.scores || {}),
      },
    ];

    const chartKeys = Object.keys(data[0]).filter((key) => key !== 'Catégorie');

    setColors(colorsObjects);
    setData(data);
    setChartKeys(chartKeys);
  }, [category]);

  return (
    <div style={{ height: '270px' }}>
      <ResponsiveBar
        tooltip={Tooltip}
        data={data}
        keys={chartKeys}
        valueFormat={(value) => `${value}%`}
        maxValue={100}
        indexBy="Catégorie"
        margin={{ top: 10, right: 24, bottom: 30, left: 40 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getColor}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'fries',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'sandwich',
            },
            id: 'lines',
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          // legend: 'country',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickValues: [0, 50, 100],
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'food',
          legendPosition: 'middle',
          legendOffset: -40,
          format: (value) => `${value}%`,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`}
      />
    </div>
  );
}

export default CategoryChart;
