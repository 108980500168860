import isProductionEnvironement from './isProductionEnvironement';

const getPartnerName = () => {
  let partner = '';

  if (isProductionEnvironement()) {
    partner = window.location.host.split('.')[0];
  } else {
    const queryString = new URLSearchParams(window.location.search);
    partner = queryString.get('partner');
  }

  return partner;
};

export default getPartnerName;
