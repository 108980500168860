import React from 'react';
import { useTranslation } from 'react-i18next';

import './InternalError.scss';

import getLocalLink from '../../utils/getLocalLink';

function InternalError() {
  const { t } = useTranslation();
  return (
    <section className="container text-center my-5 py-5" id="error_page">
      <div className="row" style={{ minHheight: '60vh' }}>
        <div className="col-12 mt-4 my-md-auto pt-5 pt-md-0 pb-md-5">
          <h1>{t('INTERNAL_ERROR')}</h1>
          <p>
            {t('CHECK_URL')} <a href={getLocalLink('/')}>{t('GO_TO_HOMEPAGE')}</a>.
          </p>
        </div>
      </div>
    </section>
  );
}

export default InternalError;
