import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

function Disclaimer({ partner }) {
  const [disclaimer, setDisclaimer] = useState();

  const { get } = useFetch(process.env.REACT_APP_PARTNER_API);
  const { t } = useTranslation();

  useEffect(() => {
    const getDisclaimer = async () => {
      const response = await get(`partner/${partner.id}/disclaimer`);
      setDisclaimer(response?.content);
    };
    try {
      getDisclaimer();
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  return (
    <div className="col-12 col-lg-6 pt-md-2">
      <h6
        className={`font-14 ${!partner && 'violet-color'}`}
      >
        {t('Informations sur le partenariat ')}
      </h6>
      <div className="fw-400 font-12" dangerouslySetInnerHTML={{ __html: sanitize(disclaimer) }} />
    </div>
  );
}

export default Disclaimer;
