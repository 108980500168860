import React from 'react';

import checkToast from '../../../assets/ui/check-toast.svg';
import exclamationMark from '../../../assets/ui/exclamation-mark-toast.svg';

export default function ToastMessage({ text, warning = false }) {
  return (
    <div className="tw-flex tw-items-center tw-text-primaryNewBlue tw-text-sm tw-font-moskauGrotesk tw-font-normal">
      <img src={warning ? exclamationMark : checkToast} alt="Icon" className=" tw-h-full tw-mr-2" />
      <div>{text}</div>
    </div>
  );
}
