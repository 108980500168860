import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MDXEditor, markdownShortcutPlugin, headingsPlugin, toolbarPlugin, BoldItalicUnderlineToggles, listsPlugin } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import './Form.scss';

import caretRight from '../../../../assets/ui/caret-right.svg';
import handleResponse from '../../../../utils/handleResponse';

function Form({ handleShowForm, post, put, response, selectedCategory, getNotes, setShowForm, editedNote, setIsDisabled }) {
  const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({});
  const { t } = useTranslation();

  register('note', { required: true });

  const onSubmit = async (data) => {
    try {
      if (editedNote && editedNote.id) {
        await put(`categories/${selectedCategory.id}/notes/${editedNote.id}`, data);
      } else {
        await post(`categories/${selectedCategory.id}/notes`, data);
      }

      if (response.ok) {
        setShowForm(false);
        reset();
        if (selectedCategory) {
          try {
            getNotes(selectedCategory.id);
          } catch (error) {
            throw new Error(error);
          }
        }
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const getToolbarMardownEditor = () => <BoldItalicUnderlineToggles />;

  useEffect(() => {
    watch((value) => {
      if (!value.title || !value.note) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    });
    if (!editedNote) {
      setIsDisabled(true);
    }
  }, [watch, editedNote]);

  return (
    <>
      <button
        type="button"
        className="tw-text-greyFour tw-text-sm tw-flex tw-gap-1 tw-items-center tw-bg-white tw-border-0 tw-px-0 hover:tw-text-greyThree"
        onClick={(e) => handleShowForm(e)}
      >
        <img src={caretRight} alt="Caret right" />
        <span className="tw-pt-0.5">
          {editedNote ? t('NOTES.EDIT') : t('NOTES.WRITE')} {t('NOTES.A_NOTE')}
        </span>
      </button>
      <form
        id="note-form"
        onSubmit={handleSubmit(onSubmit)}
        className="tw-mt-[30px] tw-flex tw-flex-col tw-h-full"
      >
        <div>
          <input
            type="text"
            className={`tw-w-full tw-border-greySeven tw-border-solid tw-border tw-rounded tw-p-3 tw-text-sm tw-text-greyOne ${errors.title ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            defaultValue={editedNote ? editedNote.title : ''}
            placeholder={t('NOTES.PLACEHOLDER_TITLE')}
            {...register('title', {
              maxLength: 255,
              required: t('NOTES.REQUIRED'),
            })}
          />
          {errors.title && (
          <div className="tw-mt-1.5 tw-text-redError tw-text-sm">{errors.title.message}</div>
          )}
        </div>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-mt-3">
          <MDXEditor
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: getToolbarMardownEditor,
              }),
            ]}
            className={`tw-w-full tw-border-greySeven tw-border-solid tw-border tw-rounded  tw-text-sm tw-text-greyOne tw-flex-grow ${errors.note ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            placeholder={t('NOTES.PLACEHOLDER_NOTE')}
            markdown={editedNote ? editedNote.note : ''}
            onChange={(value) => setValue('note', value)}
          />
          {errors.note && (
          <div className="tw-mt-1.5 tw-text-redError tw-text-sm">{errors.note.message}</div>
          )}
        </div>
      </form>
    </>
  );
}

export default Form;
