const handleCategoryClick = (
  category,
  position,
  sectorId,
  indexSelected,
  setLoading,
  QUERY_NAME,
  searchParams,
  setFilteredCategory,
  findParentCategory,
  allLevels,
  setDescription,
  setSearchParams,
  setSelected,
  description,
  sectors,
  setSectors,
) => {
  setLoading(true);
  setTimeout(() => {
    setLoading(false);
  }, 350);

  const params = new URLSearchParams(document.location.search);
  const id = params.get(QUERY_NAME);

  searchParams.set('p', 0);

  if (id && id === category.id) {
    searchParams.delete(QUERY_NAME);
    setFilteredCategory(null);

    // Find the parent category
    const parentCategory = findParentCategory(category, allLevels);

    // If there is a parent category, add its ID to the URL and set the description
    if (parentCategory) {
      searchParams.set(QUERY_NAME, parentCategory.id);

      setDescription((prevState) => [
        ...prevState.filter((cat) => cat.id !== category.id),
      ]);

      setFilteredCategory(parentCategory.name);
    }

    setSearchParams(searchParams);

    setSelected((prevSelected) => {
      // Find the index of the category in the array
      const index = prevSelected.findIndex((selection) => selection.name === category.name);

      // If the category is not in the array, return the previous state
      if (index === -1) return prevSelected;

      // Remove the category and all categories below it from the array
      return prevSelected.slice(0, index);
    });

    setSectors((prevSectors) => {
      // Remove the sector and all sectors below it from the array
      return prevSectors.slice(0, position + 1);
    });
  } else {
    setSelected((prevSelected) => {
      // Create a copy of the array up to the position of the clicked category
      const newSelected = prevSelected.slice(0, position);

      // Find the index of the category at the same level in the array
      const index = newSelected.findIndex((selection) => selection.position === position);

      // If there is a category at the same level, replace it
      if (index !== -1) {
        newSelected[index] = {
          name: category.name,
          position,
          resources: category.resources,
          icon: category.icon,
          description: category.description,
        };
      } else if (newSelected.length < sectors.length + 1) {
        // If there isn't a category at the same level and we haven't reached the limit, add it
        newSelected.splice(position, 0, {
          name: category.name,
          position,
          resources: category.resources,
          icon: category.icon,
          description: category.description,
        });
      }

      const fullCategory = allLevels
        .flatMap((sector) => sector.level)
        .find((level) => level.categories.some((cat) => cat.id === category.id));

      const currentLevelIndex = allLevels.findIndex((level) => level.level[0].categories.some((cat) => cat.id === category.id));

      const nextLevel = allLevels[currentLevelIndex + 1];

      if (fullCategory && (sectors.length < allLevels.length || fullCategory.position > 0)) {
        const categoryWithSubCategories = fullCategory.categories.find((cat) => cat.id === category.id);

        setSectors((prevSectors) => {
          // Create a copy of the array to avoid mutating the state directly
          const newSectors = [...prevSectors];

          // Replace the categories below the selected category with the subcategories of the selected category
          newSectors.splice(position + 1, newSectors.length - position - 1, {
            level: [
              {
                categories: categoryWithSubCategories.sub_categories,
                name: nextLevel.level[0].name ? nextLevel.level[0].name : `Niveau ${nextLevel.level[0].position}`,
                id: nextLevel.level[0].id,
              },
            ],
          });

          return newSectors;
        });
      }

      // Update the URL and the filtered category
      searchParams.set(QUERY_NAME, category.id);
      setSearchParams(searchParams);

      setDescription((prevState) => {
        // Check if the category is already in the prevState
        const existingCategory = prevState.find((cat) => cat.id === category.id);

        // If the category is not in the prevState, add it
        if (!existingCategory || description.length !== indexSelected) {
          return [
            ...prevState.slice(0, indexSelected),
            {
              name: category.name,
              description: category.description,
              svg: category.icon,
              resources: category.resources,
              id: category.id,
              sectorId,
            },
          ];
        }

        // If the category is already in the prevState, return the prevState as is
        return prevState;
      });

      setFilteredCategory(category.name);

      return newSelected;
    });
  }
};

export default handleCategoryClick;
