import React from 'react';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../../tailwind.config';

function TagsHead({ cellStyle }) {
  const { t } = useTranslation();

  return (
    <th
      className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0"
      style={cellStyle}
    >
      <div className="tw-flex tw-justify-center">
        <span className="align-self-center">
          {t('TAGS')}
        </span>
      </div>
    </th>
  );
}

function TagsBody({ profile, cellStyle }) {
  return (
    <td className="tw-px-3" style={cellStyle}>
      <div className=" tw-flex tw-items-center tw-gap-1 tw-flex-wrap tw-py-3">
        {profile.tags && profile.tags.map((tag) => (
          <div key={tag.id} className="tw-ml-4 tw-flex tw-items-center tw-rounded tw-py-[6px] tw-px-3" style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}>
            <span className="tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne -tw-mb-[4px]">{tag.name}</span>
          </div>
        ))}
      </div>
    </td>
  );
}

export { TagsHead, TagsBody };
