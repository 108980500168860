import { useContext } from 'react';

import { ModalContext } from '../context/ModalContext';

/**
 * Custom hook for accessing modal control functions.
 *
 * This hook provides access to modal control functions such as opening and closing modals,
 * by utilizing the ModalContext. It should be used within components that are children of
 * the ModalProvider to ensure access to the context.
 *
 * @returns {{open: (modalId: string) => void, close: () => void, openModal: string}} An object containing the modal control functions and the current state of the modal.
 *
 * @example
 * const { open, close, openModal } = useModal();
 *
 * // To open a modal with a specific ID
 * open('loginModal');
 *
 * // To close the currently open modal
 * close();
 */
export default function useModal() {
  return useContext(ModalContext);
}
