import React from 'react';
import { useTranslation } from 'react-i18next';

import aucunReporting from '../../../assets/ui/climateIcons/aucun-reporting.svg';
import insuffisant from '../../../assets/ui/climateIcons/insuffisant.svg';
import intermediaire from '../../../assets/ui/climateIcons/intermediaire.svg';
import fort from '../../../assets/ui/climateIcons/fort.svg';
import tresFort from '../../../assets/ui/climateIcons/tres-fort.svg';
import tresInsuffisant from '../../../assets/ui/climateIcons/tres-insuffisant.svg';

function BarchartLegend({ legend }) {
  const { t } = useTranslation();
  return (
    <div className="tw-pt-6 md:tw-pt-0 col-md-2">
      <div className="d-flex flex-column align-items-center justify-content-center tw-h-full">
        <div className="text-center font-10 fw-500 align-self-center">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.TITLE')}</div>
        <div className="fw-500 font-10 mt-4">
          <div className="justify-content-center">
            {legend ? Object.entries(legend).reverse().map((item, index) => (
              <div key={index} className="d-flex align-items-center">
                <div className="tw-min-h-5">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="19" height="19" rx="7.5" fill="white" />
                    <rect x="6" y="6" width="8" height="8" rx="2.66667" fill={item[1]} />
                    <rect x="0.5" y="0.5" width="19" height="19" rx="7.5" stroke="#DCDCDC" />
                  </svg>
                </div>
                <span className="p-2">{item[0]}</span>
              </div>
            )) : (
              <>
                <img src={tresFort} alt={t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.VERY_STRONG')} /><span className="p-2">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.VERY_STRONG')}</span>
                <img src={fort} alt={t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.STRONG')} /><span className="p-2">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.STRONG')}</span>
                <img src={intermediaire} alt={t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.INTERMEDIATE')} /><span className="p-2">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.INTERMEDIATE')}</span>
                <img src={insuffisant} alt={t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.INSUFFICIENT')} /><span className="p-2">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.INSUFFICIENT')}</span>
                <img src={tresInsuffisant} alt={t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.VERY_INSUFFICIENT')} /><span className="p-2">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.VERY_INSUFFICIENT')}</span>
                <img src={aucunReporting} alt={t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.NO_REPORTING')} /><span className="p-2">{t('PARTNER_DASHBOARD.OVERVIEW.LEGEND.NO_REPORTING')}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarchartLegend;
