import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getLocalLink from '../../../utils/getLocalLink';

import spin from '../../../assets/ui/spin.svg';
import logoLeclerc from '../../../assets/ui/landingPartner/logo-partner-leclerc.svg';
import logoOcLeclerc from '../../../assets/ui/landingPartner/logo-oc-x-leclerc.svg';

function Form() {
  const [firstFormData, setFirstFormData] = useState({});
  const [isFirstFormSubmitted, setIsFirstFormSubmitted] = useState(false);
  const [isSecondFormSubmitted, setIsSecondFormSubmitted] = useState(false);
  const [isCalendar, setIsCalendar] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const { partner } = useLoaderData();

  const { post, response, loading } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/contact`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      await post('landing', data);

      if (response.ok) {
        const finalFirstFormData = { ...response.data, ...data };
        setFirstFormData(finalFirstFormData);
        setIsFirstFormSubmitted(true);
        searchParams.set('email', finalFirstFormData.email);
        setSearchParams(searchParams);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const onSubmitSecondForm = async (data) => {
    const finalData = { ...firstFormData, ...data };
    try {
      await post('landing-two', finalData);

      if (response.ok) {
        setIsSecondFormSubmitted(true);
        setFirstFormData({});
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isSecondFormSubmitted && isFirstFormSubmitted) {
      const script = document.createElement('script');
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isSecondFormSubmitted, isFirstFormSubmitted, isCalendar]);

  return (
    <div id="contact-form" className="parent-header tw-bg-white xl:tw-bg-gradient-openclimat">
      <div id="content" className="tw-container tw-mx-auto tw-flex tw-flex-col xl:tw-flex-row tw-bg-white tw-text-white xl:tw-h-[770px]">
        <div className="xl:tw-w-8/12 clip-65 xl:tw-bg-gradient-openclimat tw-flex tw-items-center xl:tw-pr-80 tw-py-[60px] tw-pr-[80px] tw-pl-[80px]">
          {isSecondFormSubmitted ? (
            <div>
              <h5 className="tw-text-3xl">
                {t('LANDING_LECLERC.FORM.TEXT_C')}
              </h5>
              <div className="tw-mt-[30px] tw-text-base">
                {t('LANDING_LECLERC.FORM.TEXT_D')}
              </div>
            </div>
          ) : (
            <div className="tw-text-center xl:tw-text-left">
              <div className="tw-flex tw-justify-center xl:tw-justify-normal">
                <img src={logoOcLeclerc} alt="OpenClimat X Leclerc" className="tw-w-full tw-h-full md:tw-w-96 tw-object-contain xl:tw-object-none xl:tw-w-max xl:tw-h-max" />
              </div>
              <h5 className="tw-text-3xl tw-mt-9">
                {t('LANDING_LECLERC.FORM.TEXT_A')}
              </h5>
              <div className="tw-mt-[30px] tw-text-base">
                {t('LANDING_LECLERC.FORM.TEXT_B')}
              </div>
            </div>
          )}
        </div>
        <div className="xl:tw-bg-white xl:tw-w-4/12 xl:tw-relative xl:tw-flex xl:tw-justify-center xl:tw-items-center">
          {firstFormData.email && firstFormData.name && isFirstFormSubmitted && !isSecondFormSubmitted && (
            <div className="tw-bg-white xl:tw-w-[640px] xl:tw-right-[80px] xl:tw-absolute xl:tw-rounded-xl xl:tw-border xl:tw-border-solid xl:tw-border-greySix tw-pt-7 xl:tw-px-10 xl:tw-pt-[20px] xl:tw-pb-[20px] xl:tw-text-greyOne tw-px-[20px] tw-pb-20 xl:tw-h-[722px] xl:tw-max-h-[722px]">
              <div className="tw-flex tw-text-center tw-gap-4 tw-text-greyOne tw-pt-4">
                <button type="button" className={`tw-w-1/2 tw-bg-transparent tw-font-medium tw-py-2.5 ${!isCalendar ? 'tw-text-greyFour hover:tw-text-primaryNewBlue tw-border-0 tw-border-t-0 tw-border-x-0 tw-border-b-greyFour hover:tw-bg-greySeven tw-rounded-[6px]' : 'tw-text-greyOne tw-border-b-2 tw-border-x-0 tw-border-t-0 tw-border-primaryNewBlue'}`} onClick={() => setIsCalendar(true)}>
                  {t('LANDING_LECLERC.FORM.TEXT_J')}
                </button>
                <button type="button" className={`tw-w-1/2 tw-bg-transparent tw-font-medium tw-py-2.5 ${isCalendar ? 'tw-text-greyFour hover:tw-text-primaryNewBlue tw-border-0 tw-border-t-0 tw-border-x-0 tw-border-b-greyFour hover:tw-bg-greySeven tw-rounded-[6px]' : 'tw-text-greyOne tw-border-b-2 tw-border-x-0 tw-border-t-0 tw-border-primaryNewBlue'}`} onClick={() => setIsCalendar(false)}>
                  {t('LANDING_LECLERC.FORM.TEXT_K')}
                </button>
              </div>
              {!isCalendar && (
                <form
                  onSubmit={handleSubmit(onSubmitSecondForm)}
                  className="tw-mt-6"
                >
                  <h5 className="tw-text-base tw-text-greyTwo tw-font-medium">
                    {t('LANDING_LECLERC.FORM.TEXT_E')}
                  </h5>
                  <div className="tw-flex tw-gap-5 tw-mt-[30px] tw-flex-col xl:tw-flex-row">
                    <div className="xl:tw-w-1/2 tw-text-sm">
                      <label htmlFor="firstname" className="tw-text-greyThree">
                        {t('LANDING_LECLERC.FORM.INPUT.FIRSTNAME.LABEL')}
                      </label>
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        className={`tw-w-full tw-rounded-md tw-border tw-border-solid tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.firstname ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                        {...register('firstname', {
                          required: t('LANDING_LECLERC.FORM.INPUT.FIRSTNAME.REQUIRED'),
                        })}
                      />
                      {errors && errors.firstname && (
                      <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.firstname?.message}</div>
                      )}
                    </div>
                    <div className="xl:tw-w-1/2 tw-text-sm">
                      <label htmlFor="lastname" className="tw-text-greyThree">
                        {t('LANDING_LECLERC.FORM.INPUT.LASTNAME.LABEL')}
                      </label>
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        className={`tw-w-full tw-rounded-md tw-border tw-border-solid tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.lastname ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                        {...register('lastname', {
                          required: t('LANDING_LECLERC.FORM.INPUT.LASTNAME.REQUIRED'),
                        })}
                      />
                      {errors && errors.lastname && (
                      <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.lastname?.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="tw-flex tw-gap-5 tw-mt-6 tw-flex-col xl:tw-flex-row">
                    <div className="xl:tw-w-1/2 tw-text-sm">
                      <label htmlFor="job" className="tw-text-greyThree">
                        {t('LANDING_LECLERC.FORM.INPUT.JOB.LABEL')}
                      </label>
                      <input
                        type="text"
                        id="job"
                        name="job"
                        className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.job ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                        {...register('job', {
                          required: t('LANDING_LECLERC.FORM.INPUT.JOB.REQUIRED'),
                        })}
                      />
                      {errors && errors.job && (
                      <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.job?.message}</div>
                      )}
                    </div>
                    <div className="xl:tw-w-1/2 tw-text-sm">
                      <label htmlFor="phone" className="tw-text-greyThree">
                        {t('LANDING_LECLERC.FORM.INPUT.PHONE.LABEL')}
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.phone ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                        {...register('phone', {
                          required: false,
                          pattern: {
                            value: /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
                            message: t('LANDING_LECLERC.FORM.INPUT.PHONE.INVALID'),
                          },
                        })}
                      />
                      {errors && errors.phone && (
                      <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.phone?.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="tw-mt-6">
                    <label htmlFor="job" className="tw-text-greyThree">
                      {t('LANDING_LECLERC.FORM.INPUT.MESSAGE.LABEL')}
                    </label>
                    <div>
                      <textarea
                        name="message"
                        id="message"
                        className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.message ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                        rows={5}
                        {...register('message', {
                          required: false,
                        })}
                      />
                      {errors && errors.message && (
                      <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.message?.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-between tw-gap-5 tw-mt-6">
                    <div className="tw-items-center tw-flex">
                      <button
                        id="back-button"
                        type="button"
                        className="tw-border-0 tw-text-greyFour hover:tw-text-primaryNewBlue tw-underline tw-font-medium tw-px-0"
                        onClick={() => setIsFirstFormSubmitted(false)}
                      >
                        {t('LANDING_LECLERC.FORM.BACK')}
                      </button>
                    </div>
                    <div className="">
                      <button type="submit" disabled={loading} className={`tw-w-full tw-py-3 tw-font-medium tw-bg-primaryNewBlue tw-text-white tw-border-0 tw-rounded-md tw-px-8 hover:tw-bg-primaryNewBlueHover ${loading ? 'disabled' : ''}`}>
                        {loading ? <img src={spin} alt="spin" /> : t('LANDING_LECLERC.FORM.SEND')}
                      </button>
                    </div>
                  </div>
                  <div className="tw-mt-5 tw-text-xs tw-text-greyOne">
                    {t('LANDING_LECLERC.FORM.TEXT_H')}
                    <a href={`${getLocalLink('/invitation-adhesion-fournisseurs/politique-de-confidentialite')}`} className="tw-no-underline tw-font-medium tw-text-secondarySkyBlue" rel="nofollow noreferrer" target="blank">
                      {t('LANDING_LECLERC.FORM.TEXT_I')}
                    </a>
                  </div>
                </form>
              )}
              {isCalendar && (
                <div className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/meetings/elsachai/meet-with-openclimat?embed=true" />
              )}
            </div>
          )}
          {!isFirstFormSubmitted && !isSecondFormSubmitted && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="tw-bg-white tw-pt-7 xl:tw-w-[640px] xl:tw-right-[80px] xl:tw-absolute xl:tw-rounded-xl xl:tw-border xl:tw-border-solid xl:tw-border-greySix xl:tw-px-10 xl:tw-pt-[50px] xl:tw-pb-[30px] tw-text-greyOne tw-px-[30px] tw-pb-20"
            >
              <div className="tw-flex tw-gap-6 xl:tw-gap-5 tw-w-full tw-flex-col xl:tw-flex-row">
                <div className="xl:tw-w-1/2 tw-text-sm">
                  <label htmlFor="email" className="tw-text-greyThree">
                    {t('LANDING_LECLERC.FORM.INPUT.EMAIL.LABEL')}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.email ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                    {...register('email', {
                      required: t('LANDING_LECLERC.FORM.INPUT.EMAIL.REQUIRED'),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('LANDING_LECLERC.FORM.INPUT.EMAIL.INVALID'),
                      },
                    })}
                  />
                  {errors && errors.email && (
                    <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.email?.message}</div>
                  )}
                </div>
                <div className="xl:tw-w-1/2 tw-text-sm">
                  <label htmlFor="name" className="tw-text-greyThree">
                    {t('LANDING_LECLERC.FORM.INPUT.COMPANY.LABEL')}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.name ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                    {...register('name', {
                      required: t('LANDING_LECLERC.FORM.INPUT.COMPANY.REQUIRED'),
                    })}
                  />
                  {errors && errors.name && (
                    <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.name?.message}</div>
                  )}
                </div>
              </div>
              <div className="tw-flex tw-gap-2.5 tw-mt-6 tw-items-center">
                <input
                  type="checkbox"
                  name="accept"
                  id="accept"
                  {...register('accept', {
                    required: t('LANDING_LECLERC.FORM.INPUT.ACCEPT.REQUIRED'),
                  })}
                />
                <label htmlFor="accept" className="tw-text-sm tw-text-primaryNewBlue tw-mt-1">
                  {t('LANDING_LECLERC.FORM.TEXT_F')}
                  <a href={`${getLocalLink('/invitation-adhesion-fournisseurs/politique-de-confidentialite')}`} className="tw-underline tw-text-white xl:tw-text-primaryNewBlue" rel="nofollow noreferrer" target="blank">
                    {t('LANDING_LECLERC.FORM.TEXT_G')}
                  </a>
                </label>
              </div>
              {errors && errors.accept && (
                <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.accept?.message}</div>
              )}
              <div className="tw-mt-10 xl:tw-mt-[18px]">
                <button type="submit" disabled={loading} className={`tw-w-full tw-py-3 tw-font-medium tw-bg-primaryNewBlue tw-text-white tw-border-0 tw-rounded-md ${loading ? 'disabled' : ''}`}>
                  {loading ? <img src={spin} alt="spin" /> : t('LANDING_LECLERC.FORM.CONTINUE')}
                </button>
              </div>
            </form>
          )}
          {isFirstFormSubmitted && isSecondFormSubmitted && (
            <div className="xl:tw-relative tw-bg-white tw-p-10 xl:tw-p-0 tw-flex tw-justify-center xl:tw-justify-normal">
              <img src={logoLeclerc} alt="Logo Leclerc" className="xl:tw-absolute xl:-tw-left-[320px] xl:-tw-top-32 tw-h-full tw-w-full md:tw-w-96 xl:tw-h-max xl:tw-w-max tw-object-contain" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Form;
