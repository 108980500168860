const getPartnerSectors = async (api) => {
  try {
    const response = await api.get();

    return response;
  } catch (error) {
    throw error;
  }
};

export default getPartnerSectors;
