import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import crossModal from '../../../../assets/ui/cross-modal.svg';
import linkIcon from '../../../../assets/ui/link-icon.svg';
import check from '../../../../assets/ui/check-thin-dark-blue.svg';
import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';

function ResourceManager({
  resources,
  setResources,
}) {
  const { t } = useTranslation();
  const [resourceData, setResourceData] = useState({ name: '', link: '' });
  const [showResourceInputs, setShowResourceInputs] = useState(false);
  const [nameError, setNameError] = useState('');
  const [linkError, setLinkError] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const resourceNameRef = useRef(null);
  const resourceLinkRef = useRef(null);

  const isValidUrl = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))'
    + '(\\:\\d+)?'
    + '(\\/[-a-z\\d%_.~+]*)*'
    + '(\\?[;&a-z\\d%_.~+=-]*)?'
    + '(\\#[-a-z\\d_]*)?$', 'i');
    return !!pattern.test(url);
  };

  const addResource = () => {
    setNameError('');
    setLinkError('');

    if (!resourceData.name.trim()) {
      setNameError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.ERROR.MISSING_NAME'));
      resourceNameRef.current.focus();
      return;
    }

    if (!isValidUrl(resourceData.link)) {
      setLinkError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.ERROR.INVALID_LINK'));
      resourceLinkRef.current.focus();
      return;
    }

    setResources((prev) => [...prev, { ...resourceData }]);
    setResourceData({ name: '', link: '' });
    setShowResourceInputs(false);
  };

  const removeResource = (index) => {
    setResources((prev) => prev.filter((_, i) => i !== index));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResourceData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTooltip = (show) => {
    let message = '';
    if (show) {
      if (resources.length >= 3) {
        message = t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCEs_TOOLTIP_MAX_RESSOURCES');
      } else if (showResourceInputs) {
        message = t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCEs_TOOLTIP_VALIDATE');
      }
      setTooltipMessage(message);
      setShowTooltip(message !== '');
    } else {
      setShowTooltip(false);
    }
  };

  return (
    <div>
      <h2 className="tw-text-base tw-mb-2 tw-mt-9">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_B')}
      </h2>
      <span className="tw-text-sm tw-text-greyDark tw-font-moskauGrotesk tw-font-normal mb-2">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCES_INFO')}
      </span>
      {resources.length > 0 || showResourceInputs ? (
        <ul className="tw-flex tw-items-center tw-gap-x-2 tw-list-none tw-p-0 tw-mt-4">
          {resources.map((resource, index) => (
            <li
              key={index}
              className="tw-py-1 tw-px-4 tw-bg-greyLight tw-border tw-border-solid tw-border-primaryNewBlue/30 tw-text-sm tw-text-greyHeavy tw-font-moskauGrotesk tw-font-normal tw-rounded-full "
            >
              {resource.name}
              <button
                type="button"
                onClick={() => removeResource(index)}
                className="tw-border-none tw-p-0 tw-ml-2"
              >
                <img
                  src={crossModal}
                  alt="delete resource"
                  width="16px"
                  className="tw-mb-[1px]"
                />
              </button>
            </li>
          ))}
        </ul>
      ) : ('')}
      {showResourceInputs && (
        <div className="tw-flex tw-flex-col">
          <div className="tw-w-full tw-flex tw-items-center tw-gap-x-3 tw-justify-between">
            <div className="tw-w-1/3  min-[450px]:tw-w-2/3    tw-flex tw-flex-col">
              <label
                className="tw-text-sm tw-font-moskauGrotesk tw-font-normal"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NAME')}
              </label>
              <input
                id="resource-name"
                name="name"
                ref={resourceNameRef}
                value={resourceData.name}
                onChange={handleInputChange}
                placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCE_NAME_PLACEHOLDER')}
                className={`tw-h-11 tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-greyHeavy tw-border tw-border-solid ${
                  nameError
                    ? ' tw-border-2 tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border'
                    : 'tw-border-greyLight'
                } focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-p-2 tw-mb-4`}
              />
            </div>
            <div className="tw-w-full tw-flex tw-flex-col">
              <label
                htmlFor="resource-link"
                className="tw-text-sm tw-font-moskauGrotesk tw-font-normal"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.LINK')}
              </label>
              <div className="tw-w-full tw-flex tw-items-center tw-mb-4 tw-relative">
                <div className="tw-pointer-events-none tw-absolute tw-top-3 tw-left-0 tw-flex tw-items-center tw-pl-3">
                  <img src={linkIcon} alt="Link" className="tw-w-5 tw-h-5" />
                </div>
                <input
                  id="resource-link"
                  name="link"
                  ref={resourceLinkRef}
                  value={resourceData.link}
                  onChange={handleInputChange}
                  placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCE_LINK_PLACEHOLDER')}
                  className={`tw-w-full tw-h-11 tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-greyHeavy tw-border tw-border-solid ${
                    linkError
                      ? ' tw-border-2 tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border'
                      : 'tw-border-greyLight'
                  }  focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-p-2 tw-pl-10`}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={addResource}
              className="tw-min-w-11 tw-h-11 tw-bg-white tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-greyLight tw-rounded-md tw-mt-[3px]"
            >
              <img src={check} alt="add resource icon" />
            </button>
          </div>
          {nameError && (
            <span className="tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-red tw-mt-1">
              <img
                src={exclamationMark}
                alt="exclamation mark"
                className="tw-mr-1 -tw-mt-[3px]"
              />
              {nameError}
            </span>
          )}
          {linkError && (
            <span className="tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-red tw-mt-1">
              <img
                src={exclamationMark}
                alt="exclamation mark"
                className="tw-mr-1 -tw-mt-[3px]"
              />
              {linkError}
            </span>
          )}
        </div>
      )}
      {showResourceInputs && <hr className="tw-my-4  tw-border-greyFive" />}
      <div className="relative">
        <button
          type="button"
          onMouseOver={() => handleTooltip(true)}
          onMouseOut={() => handleTooltip(false)}
          onFocus={() => handleTooltip(true)}
          onBlur={() => handleTooltip(false)}
          onClick={setShowResourceInputs}
          disabled={resources.length >= 3 || showResourceInputs}
          className="tw-inline tw-bg-white tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-secondarySkyBlue tw-underline tw-bg-transparent tw-border-none tw-p-0 tw-m-0 hover:tw-text-blue-700 disabled:tw-text-greyDark disabled:tw-cursor-not-allowed tw-mt-4 tw-mb-11"
        >
          {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ADD_A_RESSOURCE')}
        </button>
        {showTooltip && (
          <div className="tw-w-2/3 sm:tw-w-auto tw-absolute tw-left-12 tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-moskauGrotesk tw-font-normal tw-bg-greyTwo tw-rounded-sm tw-shadow-lg -tw-m-10">
            {tooltipMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default ResourceManager;
