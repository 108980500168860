import React, { useEffect, useRef } from 'react';

import tailwindConfig from '../../../tailwind.config';

import Description from './Description';
import handleCategoryClick from './handleCategoryClick';
import findParentCategory from '../../../utils/findParentCategory';
import categoryIcons from '../../../utils/categoryIcons';

function CategoryCard({
  sector,
  index,
  sectors,
  loading,
  setLoading,
  QUERY_NAME,
  searchParams,
  setFilteredCategory,
  allLevels,
  setDescription,
  setSearchParams,
  setSelected,
  description,
  setSectors,
  selected,
}) {
  const descriptionRef = useRef(null);

  const MouseEnter = (id) => {
    const button = document.getElementById(id);
    button.style.border = `1px solid ${tailwindConfig.theme.colors.secondarySkyBlue}`;
  };

  const MouseLeave = (id) => {
    const button = document.getElementById(id);
    button.style.border = `1px solid ${tailwindConfig.theme.colors.blueBorder}`;
  };

  const activeSectorStyle = {
    color: tailwindConfig.theme.colors.primaryNewBlue,
    backgroundColor: tailwindConfig.theme.colors.blueFlashLight,
    border: `1px solid ${tailwindConfig.theme.colors.secondarySkyBlue}`,
  };

  useEffect(() => {
    const div = document.getElementById(sector.level[0].id);
    if (
      div
      && sectors
      && sectors[index]?.level[0].id === sector?.level[0].id
      && div.style.maxHeight === '0px'
      && index > 0
    ) {
      const animation = div.animate(
        [
          { maxHeight: '0px', overflow: 'hidden' },
          { maxHeight: `${div.scrollHeight + 100 + (descriptionRef.current ? 200 : 0)}px`, overflow: 'hidden', marginTop: '24px' },
        ],
        {
          duration: 350,
          fill: 'forwards',
          easing: 'ease',
        },
      );

      animation.onfinish = () => {
        div.style.maxHeight = '100%';
      };
    } else if (
      sectors[index]?.level[0].id !== sector?.level[0].id
      && div.style.maxHeight === '100%'
    ) {
      const animation = div.animate(
        [
          { maxHeight: `${div.scrollHeight}px`, overflow: 'hidden', marginTop: '24px' },
          { maxHeight: '0px', overflow: 'hidden', marginTop: '0px' },
        ],
        {
          duration: 350,
          fill: 'forwards',
          easing: 'ease',
        },
      );

      animation.onfinish = () => {
        div.style.maxHeight = '0px';
        div.style.marginTop = '0px';
      };
    } else if (sectors[index]?.level[0].id !== sector?.level[0].id) {
      div.style.maxHeight = '0px';
      div.style.marginTop = '0px';
    }
  }, [sectors, index, sector, description]);

  return (
    <div id={sector?.level[0].id} className={`tw-hidden md:tw-block tw-overflow-hidden ${sectors && sectors[index]?.level[0].id === sector?.level[0].id ? '' : ''}`}>
      <h3 className="tw-text-sm tw-text-greyHeavy">
        {sector.level.length > 0 && sector.level[0].name ? (
          sector.level[0].name
        ) : (
          `Niveau ${sector.level[0].position}`
        )}
      </h3>
      <div className="wrapper sector-list-section tw-mt-6">
        {sector.level.length > 0 && sector.level[0].categories?.sort((a, b) => a.name.localeCompare(b.name)).map((category) => (
          <button
            type="button"
            key={category.id}
            id={category.name}
            className={`category-div ${sectors && sectors[index]?.level[0].categories.find((cat) => cat.id === category.id) ? 'tw-flex' : 'tw-hidden'} ${selected && selected.find((sel) => sel.name === category.name) ? 'active-category' : ''}`}
            onClick={() => !loading && handleCategoryClick(category, index, sector.level[0].id, index, setLoading, QUERY_NAME, searchParams, setFilteredCategory, findParentCategory, allLevels, setDescription, setSearchParams, setSelected, description, sectors, setSectors)}
            style={selected && selected.find((sel) => sel.name === category.name) ? activeSectorStyle : null}
            onMouseEnter={() => selected && !selected.find((sel) => sel.name === category.name) && MouseEnter(category.name)}
            onMouseLeave={() => selected && !selected.find((sel) => sel.name === category.name) && MouseLeave(category.name)}
          >
            {category.icon ? (
              <div className="align-self-center svg-container">
                <div className="sector-logo tw-bg-primaryNewBlue" style={{ WebkitMaskImage: `url(${category.icon ? process.env.REACT_APP_FILES_DOMAIN + category.icon && categoryIcons.find((icon) => icon.value === category.icon).icon : ''})`, maskImage: `url(${category.icon ? process.env.REACT_APP_FILES_DOMAIN + category.icon && categoryIcons.find((icon) => icon.value === category.icon).icon : ''})` }} />
              </div>
            ) : ('')}
            <div className="align-self-center">
              {category.name}
            </div>
          </button>
        ))}
      </div>
      {sector.level.length > 0 && sector.level[0].categories?.sort((a, b) => a.name.localeCompare(b.name)).map((category, indexCategory) => (
        category.description || category.resources?.length > 0 ? (
          <Description key={indexCategory} sector={sector} category={category} index={index} description={description} descriptionRef={descriptionRef} />
        ) : ('')
      ))}
    </div>
  );
}

export default CategoryCard;
