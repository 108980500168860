import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import handleResponse from '../../../../utils/handleResponse';
import tailwindConfig from '../../../../tailwind.config';

function ProfileTags({ partnerId, profileId }) {
  const [profileTags, setProfileTags] = useState([]);

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/profile_tags/${profileId}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getProfileTags = async () => {
    await get();
    if (response.ok) {
      setProfileTags(response.data);
    }

    handleResponse(response);
  };

  useEffect(() => {
    getProfileTags();
  }, [profileId, partnerId]);

  return (
    <div className="tw-flex tw-items-center tw-gap-x-2.5">
      {profileTags && profileTags.map((tag) => (
        <div key={tag.id} className="tw-flex tw-items-center tw-rounded tw-py-[6px] tw-px-3" style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}>
          <span className="tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne -tw-mb-[4px]">{tag.name}</span>
        </div>
      ))}
    </div>
  );
}

export default ProfileTags;
