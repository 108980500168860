import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';

import toggleSort from '../../../../utils/toggleSort';
import formatEmissionsPercentage from '../../../../utils/formatEmissionsPercentage';
import formatDegreePositionColor from '../../../../utils/formatDegreePositionColor';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function RythmeReduction3Head({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'scope3';

  useEffect(() => {
    if (searchParams.get('scope3')) {
      setSort(searchParams.get('scope3'));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('RYTHME_REDUCTION')}
        <br />
        {t('CO2_SCOPE_3_TABLE_HEADER')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function RythmeReduction3Body({ profile, cellStyle }) {
  const [dateText, setDateText] = useState('');
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (profile.complete_percentage && profile.complete_percentage !== 0) {
      if (profile.complete_final_year < currentYear) {
        setDateText(` ${t('SINCE')} ${profile.complete_initial_year}`);
      } else {
        setDateText(`${t('BETWEEN').toLowerCase()} ${profile.complete_initial_year} ${t('AND').toLowerCase()} ${profile.complete_final_year}`);
      }
    }
  }, []);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySix tw-border" style={cellStyle}>
      <div className="d-flex">
        <div className={`elipse-scope-one ${formatDegreePositionColor(profile.complete_degree_position)} align-self-center`} />
        <div className="ps-1">
          {profile.complete && profile.complete_percentage ? formatEmissionsPercentage(t, profile.complete_percentage, profile.complete_reduce_type, profile.complete) + dateText : <div>n.a. <span className="text-muted">{t(profile.complete_details)}</span></div>}
        </div>
      </div>
    </td>
  );
}

export { RythmeReduction3Head, RythmeReduction3Body };
