import React, { useEffect, useRef, useState } from 'react';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import note from '../../../../assets/ui/note.svg';
import spin from '../../../../assets/ui/spin.svg';
import cross from '../../../../assets/ui/cross-modal.svg';

import Form from './Form';
import Category from './Cards/Category';
import Note from './Cards/Note';
import NoteDetails from './Cards/NoteDetails';

function Notes({ partnerId, profileId }) {
  const [showNotes, setShowNotes] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [showCategoryNotes, setShowCategoryNotes] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [editedNote, setEditedNote] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const notesRef = useRef();

  const { t } = useTranslation();

  const { get, post, del, put, response, loading } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/profiles/${profileId}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { session } = useOutletContext();

  const handleShowNotes = () => {
    setShowNotes(!showNotes);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notesRef.current && !notesRef.current.contains(event.target)) {
        setShowNotes(false);
        setSelectedCategory();
        setShowCategoryNotes(false);
        setShowForm(false);
        setNotes([]);
        setCategories([]);
        setSelectedNote();
      }
    };

    if (showNotes) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotes]);

  const getCategories = async () => {
    try {
      setGlobalLoading(true);
      await get('/categories');

      if (response.ok) {
        setCategories(response.data);
        if (response.data.length === 1) {
          setSelectedCategory(response.data[0]);
          setShowCategoryNotes(true);
        }
      }

      setGlobalLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  const getNotes = async (id) => {
    try {
      setGlobalLoading(true);
      await get(`/categories/${id}/notes`);

      if (response.ok) {
        setNotes(response.data);
      }

      setGlobalLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (showNotes) {
      getCategories();
    }
  }, [showNotes, profileId, showCategoryNotes]);

  useEffect(() => {
    if (selectedCategory) {
      try {
        getNotes(selectedCategory.id);
      } catch (error) {
        throw new Error(error);
      }
    }
  }, [selectedCategory]);

  const handleShowCategoryNotes = (category) => {
    setSelectedCategory(category);
    setShowCategoryNotes(true);
  };

  const handleClickShowSelectedNote = (note) => {
    setSelectedNote(note);
  };

  const handleShowForm = (e) => {
    e.preventDefault();
    setShowForm(!showForm);
    setEditedNote();
  };

  const handleCloseCrossButton = () => {
    setShowNotes(false);
    setSelectedCategory();
    setShowCategoryNotes(false);
    setShowForm(false);
    setNotes([]);
    setCategories([]);
    setSelectedNote();
  };

  return (
    showNotes ? (
      <div
        ref={notesRef}
        className="tw-bg-white tw-h-[calc(100%_-_40px)] tw-mt-5 tw-w-[calc(100%_-_40px)] md:tw-w-[424px] tw-fixed tw-bottom-5 tw-right-5 tw-z-20 tw-rounded-md tw-font-moskauGrotesk tw-flex tw-flex-col"
        style={{ boxShadow: '10px 10px 30px 13px rgba(183, 183, 183, 0.25)' }}
      >
        <div className="tw-p-6 tw-text-greyOne tw-font-semibold tw-border tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b tw-border-backgroundGrey tw-flex tw-justify-between">
          <span>
            {t('NOTES.TITLE')}
          </span>
          <button type="button" className="tw-bg-white tw-border-0" onClick={() => handleCloseCrossButton()}>
            <img src={cross} alt="Cross" />
          </button>
        </div>
        <div className="tw-px-4 tw-py-6 tw-flex-grow tw-overflow-y-auto">
          {showCategoryNotes && !showForm && !selectedNote && (
            <Note
              setShowCategoryNotes={setShowCategoryNotes}
              setNotes={setNotes}
              selectedCategory={selectedCategory}
              notes={notes}
              handleClickShowSelectedNote={handleClickShowSelectedNote}
              loading={globalLoading}
              session={session}
              del={del}
              response={response}
              getNotes={getNotes}
              setEditedNote={setEditedNote}
              setShowForm={setShowForm}
              categories={categories}
            />
          )}
          {showCategoryNotes && !showForm && selectedNote && (
            <NoteDetails
              setSelectedNote={setSelectedNote}
              selectedNote={selectedNote}
            />
          )}
          {showForm && (
            <Form
              handleShowForm={handleShowForm}
              getNotes={getNotes}
              selectedCategory={selectedCategory}
              setShowForm={setShowForm}
              post={post}
              response={response}
              editedNote={editedNote}
              put={put}
              setIsDisabled={setIsDisabled}
            />
          )}
          {!showForm && !showCategoryNotes && (
            <Category
              showCategoryNotes={showCategoryNotes}
              categories={categories}
              handleShowCategoryNotes={handleShowCategoryNotes}
              loading={globalLoading}
            />
          )}
        </div>
        {showCategoryNotes && !selectedNote && (
          <div className="tw-px-4 tw-py-6 tw-border tw-border-solid tw-border-backgroundGrey tw-border-x-0 tw-border-b-0">
            {showForm ? (
              <button
                disabled={loading || isDisabled}
                form="note-form"
                type="submit"
                className={`tw-w-full tw-border-0 tw-p-[10px] tw-rounded-md ${isDisabled ? 'tw-bg-greySix tw-text-greyFour' : 'tw-bg-primaryNewBlue tw-text-white '}`}
              >
                {loading ? <img src={spin} alt="Spin" /> : t('NOTES.SHARE_YOUR_NOTE')}
              </button>
            ) : (
              <button
                type="button"
                className="tw-bg-primaryNewBlue tw-w-full tw-border-0 tw-text-white tw-p-[10px] tw-rounded-md"
                onClick={(e) => handleShowForm(e)}
              >
                {t('NOTES.WRITE_NOTE')}
              </button>
            )}
          </div>
        )}
      </div>
    ) : (
      <button
        type="button"
        className="tw-fixed tw-bottom-5 tw-right-5 tw-bg-secondarySkyBlue tw-p-3 tw-rounded-full tw-border-0 tw-w-12 tw-h-12"
        style={{ boxShadow: '0px 4px 4px 0px rgba(189, 189, 189, 0.25)' }}
        onClick={() => handleShowNotes()}
      >
        <img src={note} alt="Note" />
      </button>
    )
  );
}

export default Notes;
