const getAnsweredQuestionsCount = (questions) => {
  let answeredQuestions = 0;
  questions.forEach((question) => {
    if (question.answer) {
      answeredQuestions += 1;
    }
  });
  return answeredQuestions;
};

export default getAnsweredQuestionsCount;
