import React from 'react';
import { useTranslation } from 'react-i18next';

import Results from '../Results/Results';

function Medium({ profiles, query, setQuery, handleClick }) {
  const { t } = useTranslation();

  return (
    <div className="search-container">
      <input
        type="text"
        id="search-md-bar"
        placeholder={t('SEARCH_COMPANY_PARTNER')}
        className="font-14"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      {query.length > 0 && <Results profiles={profiles} handleClick={handleClick} />}
    </div>
  );
}

export default Medium;
