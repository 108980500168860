import React from 'react';
import { Link } from 'react-router-dom';

import getLocalLink from '../../../utils/getLocalLink';
import rightArrow from '../../../assets/ui/arrow-right-blueSky.svg';

function Card({ cardName, cardText, cardCta, cardLink }) {
  return (
    <Link to={getLocalLink(cardLink)} className="tw-w-full lg:tw-w-[300px] xl:tw-w-[408px] tw-h-[155px] xl:tw-h-[164px] tw-bg-white tw-flex tw-flex-col tw-justify-between tw-p-6 tw-rounded tw-border tw-border-solid tw-border-greySeven hover:tw-border-secondarySkyBlue tw-no-underline tw-transition-all">
      <h3 className=" tw-text-base tw-font-semibold tw-text-greyOne">
        {cardName}
      </h3>
      <p className="tw-text-xs tw-text-greyThree">
        {cardText}
      </p>
      <div className="tw-w-full tw-flex tw-items-center tw-gap-x-2">
        <span className=" tw-text-sm tw-text-primaryNewBlue">{cardCta}</span>
        <img src={rightArrow} alt="right arrow" />
      </div>
    </Link>
  );
}

export default Card;
