const getProfileData = async (slug, partner, locale) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cf = urlParams.get('cf');

    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${slug}?domain=${partner}&locale=${locale}${cf ? `&cf=${cf}` : ''}`);

    if (response.status === 200) {
      const results = await response.json();

      return results;
    }
    return false;
  } catch (error) {
    throw error;
  }
};

export default getProfileData;
