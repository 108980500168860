import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import Header from '../../Header/Header';
import QuestionContainer from '../Template/QuestionContainer';
import PublishingSwitch from '../PublishingSwitch';
import getUserLocal from '../../../../../utils/getUserLocal';

function Climat({ view }) {
  const [isPublished, setIsPublished] = useState();
  const { profile, partner, hasWriteAccess } = useOutletContext();

  return (
    <div id="profile-page">
      <Header view={view} />
      {hasWriteAccess ? (
        <PublishingSwitch
          isViewPublishedInThisLocale={isPublished}
          view={view}
          publishing={profile.publishing}
          locale={getUserLocal()}
          profilId={profile.id}
        />
      ) : ('')}
      <QuestionContainer profile={profile} partner={partner} questionType={8} view={view} hasWriteAccess={hasWriteAccess} isPublished={isPublished} setIsPublished={setIsPublished} />
    </div>
  );
}

export default Climat;
