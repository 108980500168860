import React from 'react';

import blueCheckmark from '../../../../../../assets/ui/blue-checkmark.svg';
import emptyCheckmark from '../../../../../../assets/ui/empty-checkmark.svg';

function Content({ category, hasWriteAccess, isPublished }) {
  let allAnswered = true;
  let show = false;
  category.individualQuestions.forEach((question) => {
    if (!question.answer) {
      allAnswered = false;
    }
    if (question.answer || question.isRequired || hasWriteAccess) {
      show = true;
    }
  });
  return (
    show && (
      hasWriteAccess ? (
        <a href={`#${category.name}`} className={`d-flex justify-content-between text-decoration-none ${allAnswered ? 'summary-question' : 'summary-question-empty'}`}>
          <div className="p-2 font-14 fw-400 d-block">
            {category.name}
          </div>
          {allAnswered ? (
            <div className="tw-flex tw-content-center">
              <img className="p-2 tw-flex tw-self-center" src={blueCheckmark} alt="blue checkmark" />
            </div>
          ) : (
            <div className="tw-flex tw-content-center">
              <img className="p-2 tw-flex tw-self-center" src={emptyCheckmark} alt="empty checkmark" />
            </div>
          )}
        </a>
      ) : (
        <a href={`#${category.name}`} className={`p-2 font-14 fw-400 ${!allAnswered || !isPublished ? 'summary-question-empty' : 'summary-question'} text-decoration-none d-block`}>
          {category.name}
        </a>
      )
    )
  );
}

export default Content;
