import React, { useEffect } from 'react';
import { useFetch } from 'use-http';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';

function ProfileStatusForm({ formContext }) {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const { action, profileId, partnerId, currentStatusId, statusList, setStatus, modalTitle } = formContext;

  const { close } = useModal();
  const navigate = useNavigate();

  const { put, post, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    const data = {
      status_id: formData.status,
    };

    try {
      if (action === 'create') {
        await post(`/partner/${partnerId}/profiles/${profileId}/status`, data);
      } else if (action === 'edit') {
        await put(`/partner/${partnerId}/profiles/${profileId}/status/${currentStatusId}`, { status_id: formData.status });
      }
      if (response.ok) {
        const newStatus = statusList?.find((status) => status.id === formData.status);
        setStatus(newStatus);
        close();
        navigate(0);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  return (
    <TemplateFormModal
      modalId="profile-status-modal"
      formId="profile-status-form"
      title={modalTitle}
    >
      <form
        id="profile-status-form"
        onSubmit={handleSubmit(onSubmit)}
        className="needs-validation"
        noValidate
      >
        <div className="tw-p-4">
          <div className="has-validation">
            {statusList && statusList.sort((a, b) => a.position - b.position).map((element, index) => (
              <div key={index} className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`flexRadio${element.id}`}
                  id={`flexRadio${element.id}`}
                  value={element.id}
                  {...register('status')}
                />
                <label className="form-check-label" htmlFor={`flexRadio${element.id}`}>
                  {element.name}
                </label>
              </div>
            ))}
            {errors.status && (
            <div className="invalid-feedback">{errors.status.message}</div>
            )}
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

export default ProfileStatusForm;
