import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { BrowserView, MobileView } from 'react-device-detect';
import { useSearchParams, useLocation } from 'react-router-dom';

import downArrow from '../../../assets/ui/arrow-down-white.svg';
import caretLeft from '../../../assets/ui/database/caret-left.svg';
import caretLeftBlue from '../../../assets/ui/database/caret-left-blue.svg';
import caretRight from '../../../assets/ui/database/caret-right.svg';
import caretRightBlue from '../../../assets/ui/database/caret-right-blue.svg';

import TableFeatures from '../../../tableFeatures.json';
import getPartnerTableFeatures from '../../../utils/getPartnerTableFeatures';
import MobileCard from './MobileCard';
import Table from './Table';
import { TableLoadingContext } from '../../../context/TableLoadingProvider';
import ProfileSearch from '../../Dashboard/Suppliers/Filters/ProfileSearch/ProfileSearch';
import Status from '../../Dashboard/Suppliers/Filters/Status';
import Sbti from '../../Dashboard/Suppliers/Filters/Sbti';
import SbtiSimple from '../../Dashboard/Suppliers/Filters/SbtiSimple';
import CurrentProgress from '../../Dashboard/Suppliers/Filters/CurrentProgress';
import ActionPlanCompleted from '../../Dashboard/Suppliers/Filters/ActionPlanCompleted';
import Tags from '../../Dashboard/Suppliers/Filters/Tags';

import './Database.scss';

function Database({ partner, hasWriteAccess, filteredCategory, isDashboard }) {
  const { loading, setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [profileStatusList, setProfileStatusList] = useState();
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sort, setSort] = useState(searchParams.get('sort'));
  const [filter, setFilter] = useState(searchParams.get('filter'));
  const [tagsFilter, setTagsFilter] = useState(searchParams.get('tags_filter'));
  const [sectorFilter, setSectorFilter] = useState(searchParams.get('sf'));
  const [categoryFilter, setCategoryFilter] = useState(searchParams.get('cf'));
  const [sbtiFilter, setSbtiFilter] = useState(searchParams.get('sbti_filter'));
  const [sbtiFilterSimple, setSbtiFilterSimple] = useState(searchParams.get('sbti_filter_simple'));
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status_filter'));
  const [currentProgressFilter, setCurrentProgressFilter] = useState(searchParams.get('current_progress_filter'));
  const [actionPlanFilter, setActionPlanFilter] = useState(searchParams.get('action_plan_filter'));
  const [total, setTotal] = useState(0);
  const [tableComponent, setTableComponent] = useState();
  const [edit, setEdit] = useState([]);
  const [features, setFeatures] = useState();
  const [pagesToShow, setPagesToShow] = useState([]);
  const [isHoveredPrev, setIsHoveredPrev] = useState(false);
  const [isHoveredNext, setIsHoveredNext] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: hasWriteAccess ? 'no-cache' : 'cache-first',
  });

  const location = useLocation();

  const getProfiles = async (localSort, localFilter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, localPage, showMore = false) => {
    const url = location.pathname;

    try {
      if (searchParams.get('sort') && searchParams.get('filter')) {
        setLoading(true);

        await get(`/partner/${partner.id}/profiles-complete?page=${localPage}${localFilter ? `&filter=${filter}` : ''}${localSort ? `&sort=${sort}` : ''}${sectorFilter ? `&sector_filter=${sectorFilter}` : ''}${categoryFilter ? `&category_filter=${categoryFilter}` : ''}${sbtiFilter ? `&sbti_filter=${sbtiFilter}` : ''}${sbtiFilterSimple ? `&sbti_filter_simple=${sbtiFilterSimple}` : ''}${statusFilter ? `&status_filter=${statusFilter}` : ''}${currentProgressFilter ? `&current_progress_filter=${currentProgressFilter}` : ''}${actionPlanFilter ? `&action_plan_filter=${actionPlanFilter}` : ''}${tagsFilter ? `&tags_filter=${tagsFilter}` : ''}&domain=${partner.domain}`);

        if (location.pathname === url) {
          if (response.ok && response.data && response.data.profiles) {
            setTotal(response.data.total);
            const editArray = [];
            response.data.profiles.forEach((profile) => {
              editArray.push({ id: profile.id, edit: false });
            });

            setTotalPages(Math.ceil(response.data.total / process.env.REACT_APP_RESULT_PER_PAGE));

            setProfiles(response.data.profiles);
            setEdit(editArray);

            if (showMore) {
              setProfiles([...profiles, ...response.data.profiles]);
              setEdit([...edit, ...editArray]);
            } else {
              setProfiles(response.data.profiles);
              setEdit(editArray);
            }
          } else {
            setProfiles([]);
            setTotal(0);
            setEdit([]);
          }

          setLoading(false);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const getProfileStatusList = async () => {
    try {
      await get(`/partner/${partner.id}/profile-status`);
      if (response.ok) {
        setProfileStatusList(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const togglePage = (page) => {
    searchParams.set('p', page);
    setSearchParams(searchParams);
    document.getElementById('table-container').scrollIntoView();
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  };

  const showMore = () => {
    const localPage = page + 1;
    setPage(localPage);
    getProfiles(sort, filter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, localPage, true);
  };

  useEffect(() => {
    const localPage = searchParams.get('p') || 0;
    setPage(parseInt(localPage, 10));
    setSectorFilter(searchParams.get('sf'));
    setCategoryFilter(searchParams.get('cf'));
    setSbtiFilter(searchParams.get('sbti_filter'));
    setSbtiFilterSimple(searchParams.get('sbti_filter_simple'));
    setStatusFilter(searchParams.get('status_filter'));
    setCurrentProgressFilter(searchParams.get('current_progress_filter'));
    setActionPlanFilter(searchParams.get('action_plan_filter'));
    setTagsFilter(searchParams.get('tags_filter'));
    getProfiles(sort, filter, searchParams.get('sf'), searchParams.get('cf'), searchParams.get('sbti_filter'), searchParams.get('sbti_filter_simple'), searchParams.get('status_filter'), searchParams.get('current_progress_filter'), searchParams.get('action_plan_filter'), searchParams.get('tags_filter'), localPage);
    getProfileStatusList();
  }, [searchParams.get('tags_filter'), searchParams.get('filter'), searchParams.get('sort'), searchParams.get('sf'), searchParams.get('cf'), searchParams.get('sbti_filter'), searchParams.get('sbti_filter_simple'), searchParams.get('status_filter'), searchParams.get('current_progress_filter'), searchParams.get('action_plan_filter'), searchParams.get('p')]);

  useEffect(() => {
    const getTableFeatures = async () => {
      try {
        const features = await getPartnerTableFeatures(partner.id);
        if (features.length > 0) {
          setFeatures(features);
          setTableComponent({
            tags: features.includes(TableFeatures.tags),
            turnover: features.includes(TableFeatures.turnover),
            sector: features.includes(TableFeatures.sector),
            category: features.includes(TableFeatures.category),
            climateAction: features.includes(TableFeatures.climateAction),
            scope12: features.includes(TableFeatures.scope12),
            scope3: features.includes(TableFeatures.scope3),
            objScope12: features.includes(TableFeatures.objScope12),
            objScope3: features.includes(TableFeatures.objScope3),
            targetSbt: features.includes(TableFeatures.targetSbt),
            targetSbtSimple: features.includes(TableFeatures.targetSbtSimple),
            currentProgress: features.includes(TableFeatures.currentProgress),
            scoreCdp: features.includes(TableFeatures.scoreCdp),
            status: features.includes(TableFeatures.status),
            profileRating: features.includes(TableFeatures.profileRating),
            profileGroup: features.includes(TableFeatures.profileGroup),
            actionsCompleted: features.includes(TableFeatures.actionsCompleted),
            profileTurnover: features.includes(TableFeatures.profileTurnover),
            profileCarbonWeight: features.includes(TableFeatures.profileCarbonWeight),
            scope12Real: features.includes(TableFeatures.scope12Real),
            scope3Real: features.includes(TableFeatures.scope3Real),
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getTableFeatures();
  }, []);

  useEffect(() => {
    const pagesToShowPlaceholder = [];

    if (windowDimensions.width > 960) {
      if (page < 5) {
        for (let i = 1; i <= Math.min(5, totalPages); i += 1) {
          pagesToShowPlaceholder.push(i);
        }
        if (page === 4 && totalPages > 5) pagesToShowPlaceholder.push(6);
        if (totalPages > 5) {
          pagesToShowPlaceholder.push('...');
          pagesToShowPlaceholder.push(totalPages);
        }
      } else {
        for (let i = 1; i <= Math.min(5, totalPages); i += 1) {
          pagesToShowPlaceholder.push(i);
        }
        if (page - 1 > 1 && page !== 5) pagesToShowPlaceholder.push('...');
        if (page - 1 > 1 && page !== 5) pagesToShowPlaceholder.push(page);
        pagesToShowPlaceholder.push(page + 1);
        if ((page + 1 < totalPages) && (page + 2 !== totalPages)) pagesToShowPlaceholder.push(page + 2);
        if ((page + 1 < totalPages) && (page + 3 !== totalPages) && (page + 2 !== totalPages)) pagesToShowPlaceholder.push('...');
        if (totalPages !== page + 1) pagesToShowPlaceholder.push(totalPages);
      }
    } else {
      pagesToShowPlaceholder.push(1);
      if (page + 1 !== 1) pagesToShowPlaceholder.push(page + 1);
      pagesToShowPlaceholder.push(totalPages);
    }

    setPagesToShow(pagesToShowPlaceholder);
  }, [totalPages, page, windowDimensions]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <h2 className="tw-text-newBrand tw-text-2xl tw-font-semibold tw-mb-[12px] 2xl:tw-mb-[20px]">
        {t('SUPPLIERS')}
        <span className="tw-text-greyHeavy tw-text-xs tw-ml-[10px] tw-font-medium">
          ({total} {t('SUPPLIER')}{total > 1 && 's'} {filteredCategory && `- ${t('CATEGORY')} ${filteredCategory}`})
        </span>
      </h2>
      {isDashboard ? (
        <div className="tw-border-solid tw-border-greySix tw-border-b-0 tw-border tw-bg-backgroundCleanBlue tw-py-2 tw-px-3 tw-flex tw-flex-wrap tw-gap-2">
          <div>
            <ProfileSearch partner={partner} />
          </div>
          <div className="tw-flex tw-gap-2 tw-flex-wrap">
            {tableComponent?.status ? <Status partner={partner} /> : null}
            {tableComponent?.targetSbt ? <Sbti /> : null}
            {tableComponent?.targetSbtSimple ? <SbtiSimple /> : null}
            {tableComponent?.currentProgress ? <CurrentProgress /> : null}
            {tableComponent?.actionsCompleted ? <ActionPlanCompleted /> : null}
            {tableComponent?.tags ? <Tags tags={partner.tags} /> : null}
          </div>
        </div>
      ) : null}
      {isDashboard ? (
        <Table
          profiles={profiles}
          partner={partner}
          profileStatusList={profileStatusList}
          tableComponent={tableComponent}
          filter={filter}
          setFilter={setFilter}
          sort={sort}
          setSort={setSort}
          categoryFilter={categoryFilter}
          hasWriteAccess={hasWriteAccess}
          edit={edit}
          setEdit={setEdit}
          isDashboard={isDashboard}
          loading={loading}
          total={total}
          features={features}
          getProfiles={getProfiles}
          sectorFilter={sectorFilter}
          sbtiFilter={sbtiFilter}
          sbtiFilterSimple={sbtiFilterSimple}
          statusFilter={statusFilter}
          currentProgressFilter={currentProgressFilter}
          actionPlanFilter={actionPlanFilter}
          tagsFilter={tagsFilter}
          windowDimensions={windowDimensions}
        />
      ) : (
        <>
          <BrowserView>
            <Table
              profiles={profiles}
              partner={partner}
              profileStatusList={profileStatusList}
              tableComponent={tableComponent}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              categoryFilter={categoryFilter}
              hasWriteAccess={hasWriteAccess}
              edit={edit}
              setEdit={setEdit}
              isDashboard={isDashboard}
              loading={loading}
              total={total}
              features={features}
              getProfiles={getProfiles}
              sectorFilter={sectorFilter}
              sbtiFilter={sbtiFilter}
              sbtiFilterSimple={sbtiFilterSimple}
              statusFilter={statusFilter}
              currentProgressFilter={currentProgressFilter}
              actionPlanFilter={actionPlanFilter}
              tagsFilter={tagsFilter}
              windowDimensions={windowDimensions}
            />
          </BrowserView>
          <MobileView className="mb-5">
            {profiles.map((profile, index) => (
              <MobileCard key={index} profile={profile} index={index} tableComponent={tableComponent} />
            ))}
            <nav id="more-items" className={`mx-auto w-100 my-5 text-center ${(loading || (total === profiles.length) || total <= 10) ? 'd-none' : ''}`} aria-label="Page navigation">
              <button type="button" className="btn add-more-button text-white mx-auto" style={{ background: partner.primary_color }} onClick={() => showMore()}>
                <img className="add-more-icon align-self-center me-2" src={downArrow} alt="arrow down" />
                {t('SHOW_MORE')}
              </button>
            </nav>
          </MobileView>
        </>
      )}
      <nav
        className="tw-flex tw-justify-center tw-w-full tw-pt-3"
        style={{ boxShadow: '0px -2px 2px 0px rgba(176, 176, 176, 0.15)' }}
      >
        <button
          type="button"
          className={`tw-text-xs tw-text-greyFour tw-font-medium tw-mr-6 tw-bg-transparent tw-border-0 ${page === 0 ? 'tw-opacity-50' : 'hover:tw-text-greyOne'}`}
          {...(page === 0 ? { disabled: true } : {})}
          onClick={() => {
            if (page > 0) {
              togglePage(page - 1);
            }
          }}
          onMouseEnter={() => setIsHoveredPrev(true)}
          onMouseLeave={() => setIsHoveredPrev(false)}
        >
          <img src={isHoveredPrev ? caretLeftBlue : caretLeft} alt="Caret left" className="tw-mr-[4.5px]" />
          {t('PREVIOUS').toLocaleLowerCase()}
        </button>
        <div className="tw-flex tw-gap-2">
          {pagesToShow.map((item, index) => (
            <button
              type="button"
              key={index}
              {...(item === '...' ? { disabled: true } : {})}
              onClick={() => togglePage(item - 1)}
              className={`tw-text-sm tw-font-medium tw-border-0 ${item - 1 === page ? 'tw-bg-primaryNewBlue tw-py-1 tw-px-3 tw-rounded-md tw-text-white' : 'tw-px-3 tw-bg-transparent tw-text-greyFour hover:tw-text-greyOne'}`}
            >
              {item}
            </button>
          ))}
        </div>
        <button
          type="button"
          className={`tw-text-xs tw-text-greyFour tw-font-medium tw-ml-6 tw-bg-transparent tw-border-0 ${page === totalPages - 1 ? 'tw-opacity-50' : 'hover:tw-text-greyOne '}`}
          {...(page === totalPages - 1 ? { disabled: true } : {})}
          onClick={() => {
            if (page < totalPages - 1) {
              togglePage(page + 1);
            }
          }}
          onMouseEnter={() => setIsHoveredNext(true)}
          onMouseLeave={() => setIsHoveredNext(false)}
        >
          {t('NEXT').toLocaleLowerCase()}
          <img src={isHoveredNext ? caretRightBlue : caretRight} alt="Caret right" className="tw-ml-[4.5px]" />
        </button>
      </nav>
    </>
  );
}

export default Database;
