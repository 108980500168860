const formatDegreePositionColor = (degreePosition) => {
  let color = 'score-scope-one';

  if (degreePosition > 1 && degreePosition < 2) {
    color = 'score-scope-one';
  } else if (degreePosition >= 2 && degreePosition < 3) {
    color = 'score-scope-two';
  } else if (degreePosition >= 3 && degreePosition < 4) {
    color = 'score-scope-three';
  } else if (degreePosition >= 4 && degreePosition < 5) {
    color = 'score-scope-four';
  } else if (degreePosition >= 5 && degreePosition < 6) {
    color = 'score-scope-five';
  } else if (degreePosition >= 6) {
    color = 'score-scope-six';
  }

  return color;
};

export default formatDegreePositionColor;
