import React from 'react';
import { useTranslation } from 'react-i18next';

function Widget({ getColorByScore, getClimateAlignementInfos, getHexColorWithOpacity, degreeScore, color }) {
  const { t } = useTranslation();

  return (
    <div className="tw-col-span-1 tw-col-start-4 tw-text-center tw-flex tw-justify-center">
      {degreeScore >= 4 ? (
        <div className={`tw-self-center tw-py-1 tw-px-7 tw-font-medium tw-text-sm tw-text-greyThree tw-font-moskauGrotesk tw-border tw-border-solid tw-rounded-[100px] tw-text-${getColorByScore(degreeScore)}`} style={{ backgroundColor: `${getHexColorWithOpacity(color, 0.1)}` }}>
          {getClimateAlignementInfos(degreeScore).text}
        </div>
      ) : (
        <div className="tw-text-xs tw-self-center tw-font-moskauGrotesk">
          <div className="tw-text-greyThree">
            {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
          </div>
          <div className={`tw-text-${getColorByScore(degreeScore)} tw-font-semibold`}>
            {getClimateAlignementInfos(degreeScore).text}
          </div>
        </div>
      )}
    </div>
  );
}

export default Widget;
