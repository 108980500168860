import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import chevronRightM from '../../../assets/ui/chevron-right-m.svg';

import formatEmissionsPercentage from '../../../utils/formatEmissionsPercentage';
import formatTurnover from '../../../utils/formatTurnover';
import formatTargetSBT from '../../../utils/formatTargetSbt';
import getWebUiElements from '../../../utils/getWebUiElements';
import getLocalLink from '../../../utils/getLocalLink';
import getHexColorWithOpacity from '../../../utils/getHexColorWithOpacity';

function RowTemplate({ leftCol, rightCol }) {
  return (
    <div className="row mt-4 font-12">
      <div className="col-6 fw-500 d-flex pe-1">
        <div className="align-self-center">
          {leftCol}
        </div>
      </div>
      <div className="col-6 fw-400 d-flex ps-1">
        {rightCol}
      </div>
    </div>
  );
}

function MobileCard({ profile, index, tableComponent }) {
  const [status, setStatus] = useState();
  const [statusColor, setStatusColor] = useState();
  const [dateTextScope12, setDateTextScope12] = useState('');
  const [dateTextScope3, setDateTextScope3] = useState('');
  const [dateTextObjScope12, setDateTextObjScope12] = useState('');
  const [dateTextObjScope3, setDateTextObjScope3] = useState('');
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setStatus(profile.status_name);
    setStatusColor(profile.status_color);
    if (profile.direct_percentage && profile.direct_percentage !== 0) {
      if (profile.direct_final_year < currentYear) {
        setDateTextScope12(` ${t('SINCE')} ${profile.direct_initial_year}`);
      } else {
        setDateTextScope12(`${t('BETWEEN').toLowerCase()} ${profile.direct_initial_year} ${t('AND').toLowerCase()} ${profile.direct_final_year}`);
      }
    }
    if (profile.complete_percentage && profile.complete_percentage !== 0) {
      if (profile.complete_final_year < currentYear) {
        setDateTextScope3(` ${t('SINCE')} ${profile.complete_initial_year}`);
      } else {
        setDateTextScope3(`${t('BETWEEN').toLowerCase()} ${profile.complete_initial_year} ${t('AND').toLowerCase()} ${profile.complete_final_year}`);
      }
    }
    if (profile.obj_direct_percentage && profile.obj_direct_percentage !== 0) {
      if (profile.obj_scope_1e2_direct_final_year < currentYear) {
        setDateTextObjScope12(` ${t('SINCE')} ${profile.obj_scope_1e2_direct_initial_year}`);
      } else {
        setDateTextObjScope12(` ${t('BETWEEN').toLowerCase()} ${profile.obj_scope_1e2_direct_initial_year} ${t('AND').toLowerCase()} ${profile.obj_scope_1e2_direct_final_year}`);
      }
    }
    if (profile.obj_scope_3_n_1_complete_percentage && profile.obj_scope_3_n_1_complete_percentage !== 0) {
      if (profile.obj_scope_3_n_1_complete_final_year < currentYear) {
        setDateTextObjScope3(` ${t('SINCE')} ${profile.obj_scope_3_n_1_complete_initial_year}`);
      } else {
        setDateTextObjScope3(` ${t('BETWEEN').toLowerCase()} ${profile.obj_scope_3_n_1_complete_initial_year} ${t('AND').toLowerCase()} ${profile.obj_scope_3_n_1_complete_final_year}`);
      }
    }
  }, []);

  return (
    <a href={getLocalLink(`${window.origin}/profile/${profile.slug}`)} key={index} className={`bg-white d-block black-color text-decoration-none border-25 p-3 ${index > 0 && 'mt-4'}`}>
      <div className="d-flex mobile-card-header">
        <div className="profile-card-logo">
          <img src={`${process.env.REACT_APP_FILES_DOMAIN}${profile.logo_url}`} className="img-fluid" alt={profile.name} loading="lazy" />
        </div>
        <div className="ms-3">
          <div className="font-14">
            {profile.name}
          </div>
          <div className="font-12" style={{ color: '#9E9E9E' }}>
            {profile.sector_name}
          </div>
        </div>
        <div className="align-self-center ms-auto">
          <img src={chevronRightM} alt="Chevron right" />
        </div>
      </div>
      {profile.status_name && profile.status_color ? (
        <div className="mt-3 text-center fw-500 font-14 p-2" style={{ borderRadius: '100px', color: statusColor, backgroundColor: getHexColorWithOpacity(statusColor, 0.1) }}>
          {t(status)}
        </div>
      ) : ('')}
      {tableComponent?.turnover && (
        <RowTemplate leftCol={t('TURNOVER')} rightCol={formatTurnover(profile.turnover_amount, profile.turnover_type, t)} />
      )}
      {tableComponent?.climateAction && (
        <RowTemplate leftCol={t('CLIMATE_ACTION')} rightCol={t(profile.score_label)} />
      )}
      {tableComponent?.scope12 && (
        <RowTemplate
          leftCol={t('CO2_SCOPE_1_2_MOBILE_CARD')}
          rightCol={(
            <div className="d-flex align-self-center">
              <div className="align-self-center">
                <img src={`${getWebUiElements(Math.floor(profile.direct_level)).co2Arrow}`} alt="co2 arrow course" className="co2-arrow-course-card" width="16" height="16" />
              </div>
              <div className="font-10 cloudy-grey-color align-self-center">
                {profile.direct && profile.direct_percentage ? formatEmissionsPercentage(t, profile.direct_percentage, profile.direct_reduce_type, profile.direct) + dateTextScope12 : <div>n.a. <span className="text-muted">{t(profile.direct_details)}</span></div>}
              </div>
            </div>
        )}
        />
      )}
      {tableComponent?.scope3 && (
        <RowTemplate
          leftCol={t('CO2_SCOPE_3_MOBILE_CARD')}
          rightCol={(
            <div className="d-flex align-self-center">
              <div className="align-self-center">
                <img src={`${getWebUiElements(Math.floor(profile.complete_level)).co2Arrow}`} alt="co2 arrow course" className="co2-arrow-course-card" width="16" height="16" />
              </div>
              <div className="font-10 cloudy-grey-color align-self-center">
                {profile.complete && profile.complete_percentage ? formatEmissionsPercentage(t, profile.complete_percentage, profile.complete_reduce_type, profile.complete) + dateTextScope3 : <div>n.a. <span className="text-muted">{t(profile.complete_details)}</span></div>}
              </div>
            </div>
        )}
        />
      )}
      {tableComponent?.objScope12 && (
        <RowTemplate
          leftCol={t('OBJ_CO2_SCOPE_1_2_MOBILE_CARD')}
          rightCol={(
            <div className="d-flex align-self-center">
              <div className="align-self-center">
                <img src={`${getWebUiElements(Math.floor(profile.obj_scope_1e2_manual_degree_position)).co2Arrow}`} alt="co2 arrow course" className="co2-arrow-course-card" width="16" height="16" />
              </div>
              <div className="font-10 cloudy-grey-color align-self-center">
                {profile.direct ? formatEmissionsPercentage(t, profile.obj_direct_percentage, profile.obj_scope_1e2_direct_value_type, profile.obj_direct) + dateTextObjScope12 : <div>n.a. <span className="text-muted">({t('ACTIONS_SUBTEXT_1')})</span></div>}
              </div>
            </div>
        )}
        />
      )}
      {tableComponent?.objScope3 && (
        <RowTemplate
          leftCol={t('OBJ_CO2_SCOPE_3_MOBILE_CARD')}
          rightCol={(
            <div className="d-flex align-self-center">
              <div className="align-self-center">
                <img src={`${getWebUiElements(Math.floor(profile.obj_scope_3_n_1_manual_degree_position)).co2Arrow}`} alt="co2 arrow course" className="co2-arrow-course-card" width="16" height="16" />
              </div>
              <div className="font-10 cloudy-grey-color align-self-center">
                {profile.obj_scope_3_n_1_complete_percentage ? formatEmissionsPercentage(t, profile.obj_scope_3_n_1_complete_percentage, profile.obj_scope_3_n_1_direct_value_type, profile.obj_complete) + dateTextObjScope3 : <div>n.a. <span className="text-muted">({t('ACTIONS_SUBTEXT_1')})</span></div>}
              </div>
            </div>
        )}
        />
      )}
      {tableComponent?.targetSbt && (
        <RowTemplate leftCol={t('SBT_TARGET')} rightCol={t(formatTargetSBT(profile.target_sbt))} />
      )}
      {tableComponent?.scoreCdp && (
        <RowTemplate leftCol={t('CDP_SCORE')} rightCol={profile.scope_cdp} />
      )}
    </a>
  );
}

export default MobileCard;
