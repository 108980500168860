import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFetch } from 'use-http';

import getUserLocal from '../../../../../utils/getUserLocal';
import TableOfContents from './TableOfContents';
import QuestionContainer from './QuestionsContainer';
import Header from '../../Header/Header';
import getQuestions from '../../../../../utils/getQuestions';
import getAnsweredQuestionsCount from '../../../../../utils/getAnsweredQuestionsCount';
import ContentQuestionForm from '../../Modal/Forms/ContentQuestionForm';
import ContentQuestionsTypeCarbonModelForm from '../../Modal/Forms/ContentQuestionsTypeCarbonModelForm';

function PrivateQuestions({ view }) {
  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [formContext, setFormContext] = useState({});
  const [summary, setSummary] = useState([]);

  const { profile, partner, hasWriteAccess } = useOutletContext();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });

  const QUESTION_TYPE = 6;

  const fetchData = async () => {
    try {
      const response = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), QUESTION_TYPE, profile.typology_id, partner.id);

      if (api.response.ok) {
        setQuestions(response.questions);
        setSummary(response.table_contents);
        const answeredNormal = getAnsweredQuestionsCount(response.table_contents);
        setNumberOfAnsweredQuestions(answeredNormal);
        const totalQuestions = response.questions.length;
        setNumberOfQuestions(response.questions.length);
        const progressionBar = (answeredNormal * 100) / totalQuestions;
        setProgressionBar(parseInt(progressionBar, 10));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="profile-page">
      <Header view={view} />
      <div className="container-fluid container-lg mt-3 pb-5">
        <div className="row">
          <div className="col-12 col-md-4 px-md-3">
            <TableOfContents
              hasWriteAccess={hasWriteAccess}
              progressionBar={progressionBar}
              numberOfQuestions={numberOfQuestions}
              numberOfAnsweredQuestions={numberOfAnsweredQuestions}
              summary={summary}
              partnerInfo={partner}
              partner={partner}
              isPublished
            />
          </div>
          <div className="col-12 col-md-8 px-md-3">
            <QuestionContainer
              profile={profile}
              partner={partner}
              questions={questions}
              setFormContext={setFormContext}
              hasWriteAccess={hasWriteAccess}
              fetchData={fetchData}
              view={view}
            />
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <ContentQuestionForm formContext={formContext} />
            <ContentQuestionsTypeCarbonModelForm formContext={formContext} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default PrivateQuestions;
