import React from 'react';
import { useTranslation } from 'react-i18next';
import getHexColorWithOpacity from '../../utils/getHexColorWithOpacity';

import LogoGrey from '../../assets/logo-grey.svg';
import Authenticated from './Authenticated/Authenticated';
import Search from '../Features/Search/Search';
import Features from '../../features.json';

import './Header.scss';
import getLocalLink from '../../utils/getLocalLink';

function Header({ partner, session, features, searchBar, showHeader = true }) {
  const { t } = useTranslation();

  const search = searchBar && features.includes(Features.bigSearch) && <Search partner={partner} navbarTemplate />;

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-bg sticky-top" id="navbar-partner">
        <div className="container-xl">
          <a href={getLocalLink('/')} className="navbar-brand py-0 oc-navbar-brand mb-1 mt-1">
            {partner.logo_header ? (
              <img
                src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_header}`}
                id="partner-logo-header"
                alt={partner.name}
                className="my-0 py-0"
                height="30"
              />
            ) : (
              partner.name
            )}
          </a>
          <div
            id="menuButton"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              id="first-block"
              className="navbar-nav mx-md-auto mb-2 mb-lg-0 align-items-center"
            >
              {search}
            </ul>
            <div className="d-flex align-self-center flex-row justify-content-center">
              {session ? <Authenticated session={session} partner={partner} /> : (
                <ul
                  id="second-block"
                  className="navbar-nav d-flex  flex-row align-self-center align-items-end"
                >
                  <li className="nav-item py-2 px-2 grey-color mr-auto">
                    <span className="font-14 fw-400">
                      {t('WITH')}
                      &nbsp;
                    </span>
                    <img
                      src={LogoGrey}
                      id="logo"
                      alt="openclimat"
                      className="my-0 py-0"
                      height="20"
                    />
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </nav>
      {showHeader ? (
        <header id="partner-banner" className="d-flex container-fluid align-items-center" style={{ backgroundImage: `linear-gradient(89.51deg, ${getHexColorWithOpacity(partner?.primary_color, 0.043)}, ${getHexColorWithOpacity(partner?.primary_color, 0.4058)}, ${getHexColorWithOpacity(partner?.primary_color, 0.9957)})` }}>
          <div className="container mx-auto">
            <div className="row">
              <div className={`${partner && partner.header_title && partner.header_description ? 'col-md-6' : 'col-12'} d-flex justify-content-center align-items-center`}>
                <img src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_banner ?? partner.logo_header}`} alt={`Logo ${partner.name}`} className="tw-max-h-32 tw-py-6 md:tw-py-0" />
              </div>
              {partner && partner.header_title && partner.header_description ? (
                <div className="header-text-block col-md-6 mx-auto text-center grey-HHH-color tw-flex">
                  <div className="tw-flex tw-flex-col tw-self-center">
                    <h1 className="tw-mb-5">
                      {partner?.header_title}
                    </h1>
                    <div className="font-16 fw-400">
                      {partner?.header_description}
                    </div>
                  </div>
                </div>
              ) : ('')}
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
}

export default Header;
