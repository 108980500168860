/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import blueCheckmark from '../../../../../assets/ui/blue-checkmark.svg';
import emptyCheckmark from '../../../../../assets/ui/empty-checkmark.svg';

function TableOfContents({ hasWriteAccess, progressionBar, numberOfQuestions, numberOfAnsweredQuestions, summary, summaryTransverse, isPublished, showOptional }) {
  const { t } = useTranslation();

  const distributionEnum = {
    '': '--',
    0: '<1',
    2: '<5',
  };

  for (let value = 5; value <= 100; value += 5) {
    distributionEnum[value] = value;
  }

  return (
    <div className="summary bg-white black-color sticky-top tw-rounded tw-border tw-border-solid tw-border-greySix">
      <h3 className="summary-title tw-text-base d-flex justify-content-between">
        <span className="align-self-center">
          {t('TABLE_OF_CONTENTS_TITLE')}
        </span>
      </h3>
      <div className={`${summaryTransverse.length > 0 && showOptional === true ? 'summary-first-question pb-3' : 'summary-second-question'}summary-first-question mt-3`}>
        {hasWriteAccess && (
          <div className="d-flex justify-content-between pb-3">
            <div className="summary-progression-bar-container position-relative align-self-center">
              <div className="summary-progression-bar position-absolute" />
              <div className="summary-progression-bar-fill position-absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 font-12">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
        )}
        <div className="d-flex">
          <div className="font-13 fw-500 text-center mb-3 primary-color border-14 summary-subtext-container">
            {t('TABLE_OF_CONTENTS_TEXT_7')}
          </div>
        </div>
        <h4 className="summary-question-title fw-600 font-14">
          {t('TABLE_OF_CONTENTS_TEXT_4')}
        </h4>
        {summary && summary.map((question, index) => {
          const percentage = question.distribution[0]?.percentage;
          const displayPercentage = (percentage !== undefined && percentage !== null) ? distributionEnum[percentage] : '--';

          return (
            hasWriteAccess ? (
              <a href={`#${question.name}`} key={index} className={`d-flex text-decoration-none ${question.individualQuestions[0].answer ? 'summary-question' : 'summary-question-empty'}`}>
                <div className="font-13 fw-500 primary-color border-14 text-center d-flex justify-content-around align-self-center distribution-percent-container">
                  <div className="d-block align-self-center lh-19">{displayPercentage}%</div>
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <div className="py-2 px-3 font-14 fw-400 d-block">
                    {question.name}
                  </div>
                  {question.individualQuestions[0].answer ? (
                    <div className="tw-flex tw-content-center">
                      <img className="p-2 tw-flex tw-self-center" src={blueCheckmark} alt="blue checkmark" />
                    </div>
                  ) : (
                    <div className="tw-flex tw-content-center">
                      <img className="p-2 tw-flex tw-self-center" src={emptyCheckmark} alt="empty checkmark" />
                    </div>
                  )}
                </div>
              </a>
            ) : (
              question.individualQuestions[0].isRequired ? (
                <a href={`#${question.name}`} key={index} className={`d-flex text-decoration-none ${!question.individualQuestions[0].answer || !isPublished ? 'summary-question-empty' : 'summary-question'}`}>
                  <div className="font-13 fw-500 primary-color border-14 text-center d-flex justify-content-around align-self-center distribution-percent-container">
                    <div className="d-block align-self-center lh-19">{displayPercentage}%</div>
                  </div>
                  <div className="p-2 w-100 font-14 fw-400 d-block">
                    {question.name}
                  </div>
                </a>
              ) : (
                (question.individualQuestions[0].answer || hasWriteAccess)
                  && (
                  <a href={`#${question.name}`} key={index} className={`d-flex text-decoration-none ${!question.individualQuestions[0].answer || !isPublished ? 'summary-question-empty' : 'summary-question'}`}>
                    <div className="font-13 fw-500 primary-color border-14 text-center d-flex justify-content-around align-self-center distribution-percent-container">
                      <div className="d-block align-self-center lh-19">{displayPercentage}%</div>
                    </div>
                    <div className="p-2 w-100 font-14 fw-400 d-block">
                      {question.name}
                    </div>
                  </a>
                  )
              )
            )
          );
        })}
      </div>
      {summaryTransverse.length > 0 && showOptional === true ? (
        <div className="summary-second-question mt-3">
          <h4 className="fw-600 font-14 d-flex justify-content-between">
            <span>{t('TABLE_OF_CONTENTS_TEXT_8')}</span>
            {hasWriteAccess && <span className="fw-400 font-12">{t('TABLE_OF_CONTENTS_TEXT_6')}</span>}
          </h4>
          {summaryTransverse && summaryTransverse.map((question, index) => (
            hasWriteAccess ? (
              <a href={`#${question.name}`} key={index} className={`d-flex justify-content-between text-decoration-none ${question.individualQuestions[0].answer ? 'summary-question' : 'summary-question-empty'}`}>
                <div className="p-2 font-14 fw-400 d-block">
                  {question.name}
                </div>
                {question.individualQuestions[0].answer ? (
                  <div className="tw-flex tw-content-center">
                    <img className="p-2 tw-flex tw-self-center" src={blueCheckmark} alt="blue checkmark" />
                  </div>
                ) : (
                  <div className="tw-flex tw-content-center">
                    <img className="p-2 tw-flex tw-self-center" src={emptyCheckmark} alt="empty checkmark" />
                  </div>
                )}
              </a>
            ) : (
              question.individualQuestions[0].isRequired ? (
                <a href={`#${question.name}`} key={index} className={`p-2 font-14 fw-400 ${!question.individualQuestions[0].answer || !isPublished ? 'summary-question-empty' : 'summary-question'} text-decoration-none d-block`}>
                  {question.name}
                </a>
              ) : (
                (question.individualQuestions[0].answer || hasWriteAccess)
                  && (
                  <a href={`#${question.name}`} key={index} className={`p-2 font-14 fw-400 ${!question.individualQuestions[0].answer || !isPublished ? 'summary-question-empty' : 'summary-question'} text-decoration-none d-block`}>
                    {question.name}
                  </a>
                  )
              )
            )
          ))}
        </div>
      ) : ('')}
    </div>
  );
}

export default TableOfContents;
