import React from 'react';
import { useTranslation } from 'react-i18next';

import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

function Status({ profile }) {
  const { t } = useTranslation();

  return (
    <div className="status-div-competency tw-mt-2 fw-500 font-12 tw-inline-flex" style={{ color: profile.status_color, backgroundColor: getHexColorWithOpacity(profile.status_color, 0.1) }}>
      {t(profile.status_name)}
    </div>
  );
}

export default Status;
