const getClimateAlignementInfos = (level) => {
  const roundedLevel = Math.floor(level);
  switch (roundedLevel) {
    case 6:
      return {
        text: '1.5°C',
        textColor: 'noteTresForte',
        bgColor: 'noteTresForte',
      };
    case 5:
      return {
        text: 'WB 2°C',
        textColor: 'noteForte',
        bgColor: 'noteForte',
      };
    case 4:
      return {
        text: '2°C',
        textColor: 'noteIntermediaire',
        bgColor: 'noteIntermediaire',
      };
    default:
      if (roundedLevel <= 4) { // Engagement Insuffisant donc pas de text
        return {
          textColor: 'noteEncoreInsufissante',
          bgColor: 'noteEncoreInsufissante',
        };
      }
      return {
        text: '--',
        textColor: 'greyDark',
        bgColor: '',
      };
  }
};

export default getClimateAlignementInfos;
