import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Dropdown from './Dropdown';

import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function ActionPlanCompleted() {
  const { setLoading } = useContext(TableLoadingContext);
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const options = [
    {
      name: 'YES',
      value: 'completed',
    },
    {
      name: 'NO',
      value: 'not_completed',
    },
  ];

  const handleActionPlanCompletedFilter = (value) => {
    setLoading(true);
    if (value === '') {
      searchParams.delete('action_plan_filter');
    } else {
      searchParams.set('action_plan_filter', value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('action_plan_filter')) {
      setValue(searchParams.get('action_plan_filter'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name={t('ACTION_PLAN_COMPLETED_FILTER')}
      handleChange={handleActionPlanCompletedFilter}
      options={options}
      value={value}
    />
  );
}

export default ActionPlanCompleted;
