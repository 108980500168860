import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';

import BarchartLegend from './BarChartLegend';
import handleResponse from '../../../utils/handleResponse';
import MainChart from './Chart/MainChart';
import CategoryChart from './Chart/CategoryChart';

import './Overview.scss';

function Statistics() {
  const [globalStats, setGlobalStats] = useState();
  const [colorsObjects, setColorsObjects] = useState();
  const [categories, setCategories] = useState(null);
  const [categoriesLevel, setCategoriesLevel] = useState();
  const [legend, setLegend] = useState();

  const [isCustom, setIsCustom] = useState(false);

  const { partner } = useOutletContext();

  const { t } = useTranslation();

  const api = useFetch(
    `${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
    },
  );

  const getStats = async (levelId) => {
    try {
      await api.get(`/stats${levelId ? `?level_id=${levelId}` : ''}`);
      if (api.response.ok) {
        if (!categoriesLevel) {
          const categoriesLevelArray = [];
          api.response.data?.categories?.forEach((category) => {
            if (!categoriesLevelArray.some((level) => level.id === category.partner_level_id)) {
              categoriesLevelArray.push({
                id: category.partner_level_id,
                name: category.partner_level_name || `Niveau ${category.partner_level_position}`,
                position: category.partner_level_position,
              });
            }
          });
          if (categoriesLevelArray.length > 1) {
            setCategoriesLevel(categoriesLevelArray);
          }
        }

        if (api.response.data.colors) {
          setLegend(api.response.data.colors);
        }

        setGlobalStats(api.response.data.scores);
        setCategories(api.response.data.categories);
        setColorsObjects(api.response.data.colors);
        setIsCustom(api.response.data.is_custom);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      handleResponse(api.response);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="tw-col-span-full md:tw-col-span-4 lg:tw-col-span-5 md:tw:col-start-3 lg:tw-col-start-2 tw-p-[20px] 2xl:tw-p-12 tw-bg-whiteGrey tw-min-h-screen">
      <h2 className="tw-text-xl">{t('PARTNER_DASHBOARD.OVERVIEW.HEADER')}</h2>
      <div className="tw-mt-12 tw-rounded-2xl tw-overflow-hidden">
        <div className="tw-p-6 tw-bg-oldColors-white-LL">
          <h3 className="tw-text-base tw-font-semi-bold">
            {t('PARTNER_DASHBOARD.OVERVIEW.TITLE')}
          </h3>
          <h4 className="tw-text-sm text-muted">
            {t('PARTNER_DASHBOARD.OVERVIEW.SUBTITLE_1')}
          </h4>
        </div>
        <div className="tw-p-6 tw-bg-white row">
          <div className="tw-flex tw-justify-center tw-align-middle col-md-10">
            <div className="tw-w-full lg:tw-w-1/5" style={{ height: '470px' }}>
              {globalStats ? (
                <MainChart globalStats={globalStats} colorsObjects={colorsObjects} />
              ) : ('')}
            </div>
          </div>
          <BarchartLegend legend={legend} />
        </div>
      </div>
      <div className="tw-mt-12 tw-rounded-2xl tw-overflow-hidden">
        <div className="tw-p-6 tw-bg-oldColors-white-LL">
          <h3 className="tw-text-base tw-font-semi-bold">
            {isCustom ? t('PARTNER_DASHBOARD.OVERVIEW.TITLE_2') : t('PARTNER_DASHBOARD.OVERVIEW.TITLE_2_ALT')}
          </h3>
          <h4 className="tw-text-sm text-muted">
            {isCustom ? t('PARTNER_DASHBOARD.OVERVIEW.SUBTITLE_2') : t('PARTNER_DASHBOARD.OVERVIEW.SUBTITLE_2_ALT')}
          </h4>
        </div>
        <div className="tw-p-6 tw-bg-white row">
          <div className="row col-md-10 mx-auto">
            {categories && categories.sort((a, b) => a.name.localeCompare(b.name)).map((category, index) => (
              <div key={index} className="col-4 mx-auto mt-4 border border-1 border-14 white-bg" style={{ width: '18rem' }}>
                <div className="ms-3">
                  <div className="mt-2 font-15 fw-500">{category.name}</div>
                  <div className="mx-auto col-5 text-center p-2 white-LL-bg my-3 border-10">
                    <span className="font-12">
                      <span className="fw-600">{category.count || 0}</span>
                      <span className="font-11"> entreprise{category.count > 1 ? 's' : ''}</span>
                    </span>
                  </div>
                </div>
                <div style={{ height: '270px' }}>
                  <CategoryChart category={category} colorsObjects={colorsObjects} />
                </div>
              </div>
            ))}
          </div>
          <BarchartLegend legend={legend} />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
