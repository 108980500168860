import React, { useEffect, useState } from 'react';

import QuestionTypeQualitative from './QuestionTypeQualitative/QuestionTypeQualitative';
import QuestionTypeCarbonDistribution from './QuestionTypeCarbonDistribution/QuestionTypeCarbonDistribution';
import QuestiontTypeQuantitative from './QuestionTypeQuantitative/QuestiontTypeQuantitative';

function Question({ profile, partner, question, isPublished, hasWriteAccess, setFormContext, fetchData, setShowOptional, additionalClass }) {
  const [answer, setAnswer] = useState();
  const [noAnswerText, setNoAnswerText] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (question.answer) {
      setAnswer(question.answer);
    }
    if (setShowOptional) {
      setShowOptional(true);
    }

    if (setShowOptional && hasWriteAccess === true) {
      setShowOptional(true);
    }

    setShow(true);

    if ((!isPublished || !hasWriteAccess) && partner) {
      setNoAnswerText('PROFILE_APPROACH_TEXT_2');
    } else if (profile.is_managed) {
      setNoAnswerText('PROFILE_APPROACH_TEXT_1');
    } else {
      setNoAnswerText('PROFILE_APPROACH_TEXT_2');
    }
  }, [hasWriteAccess, fetchData]);

  return (
    ((answer && answer.answer) || question.is_required || hasWriteAccess) && show ? (
      <section id={question.name} className={`question-container ${additionalClass}`}>
        <p className="fw-500 font-14 primary-HH-color mb-0 pt-2">
          {question.info}
        </p>
        {question.type === 3 ? (
          <QuestionTypeCarbonDistribution
            question={question}
            isPublished={isPublished}
            hasWriteAccess={hasWriteAccess}
            answer={answer}
            noAnswerText={noAnswerText}
            setFormContext={setFormContext}
            partner={partner}
            fetchData={fetchData}
            profile={profile}
          />
        ) : ('')}
        {question.type === 2 ? (
          <QuestiontTypeQuantitative
            question={question}
            isPublished={isPublished}
            hasWriteAccess={hasWriteAccess}
            answer={answer}
            noAnswerText={noAnswerText}
            setFormContext={setFormContext}
            partner={partner}
            fetchData={fetchData}
            profile={profile}
          />
        ) : ('')}
        {question.type === null || question.type === 1 ? (
          <QuestionTypeQualitative
            question={question}
            isPublished={isPublished}
            hasWriteAccess={hasWriteAccess}
            answer={answer}
            noAnswerText={noAnswerText}
            setFormContext={setFormContext}
            partner={partner}
            fetchData={fetchData}
            profile={profile}
          />
        ) : ('')}
      </section>
    ) : ('')
  );
}

export default Question;
