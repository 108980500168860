import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';
import checkDarkBlue from '../../../../assets/ui/check-dark-blue.svg';

function Dropdown({
  name,
  handleChange,
  options,
  value,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const handleClickDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickElem = (option) => {
    if (JSON.stringify(option) === JSON.stringify(selected)) {
      handleChange('');
      setSelected(null);
    } else {
      handleChange(option?.value);
      setSelected(option);
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value !== selected?.value && options) {
      setSelected(options.find((option) => String(option?.value) === String(value)));
    }
  }, [value]);

  useEffect(() => {
    options?.forEach((option) => {
      if (option?.value === value) {
        setSelected(option);
      }
    });
  }, [options]);

  return (
    <div ref={dropdownRef} className="tw-relative tw-rounded-sm tw-max-w-60">
      <button
        type="button"
        className="tw-relative tw-max-h-10 tw-h-10 tw-border tw-border-solid tw-rounded-lg tw-border-greySeven focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-secondarySkyBlue tw-text-greyFour tw-text-xs tw-px-4 filter-select tw-w-full sm:tw-w-56 tw-flex tw-justify-between tw-items-center"
        onClick={handleClickDropdown}
      >
        <div className="tw-transition-all tw-duration-600 tw-flex tw-flex-col tw-items-start">
          <div className={`tw-transition-all tw-duration-600 tw-text-greyFour ${t(selected?.name) ? 'tw-text-[10px]' : 'tw-text-xs'}`}>
            {name}
          </div>
          <div className="tw-gap-2.5 tw-text-greyTwo tw-w-44 tw-truncate tw-text-left tw-text-xs">
            {t(selected?.name)}
          </div>
        </div>
        <img
          src={dropdownArrow}
          className={`${isOpen ? 'tw-rotate-180' : ''} tw-duration-500 tw-transition-all`}
          alt="Dropdown arrow"
        />
      </button>
      <div
        ref={contentRef}
        className={`tw-absolute tw-top-full tw-w-full tw-mt-1 tw-z-50 tw-transition-all tw-duration-200 tw-overflow-hidden tw-rounded-[6px] tw-bg-white tw-border tw-border-solid ${isOpen ? 'tw-border-blueVeryLight tw-visible' : 'tw-border-[#00ffff00] tw-invisible'}`}
        style={{ height: isOpen ? `${contentRef.current.scrollHeight}px` : '0' }}
      >
        <div className="tw-flex tw-flex-col tw-text-xs">
          {options?.map((option) => (
            <button
              type="button"
              key={option?.name}
              className={`tw-px-4 tw-py-3 tw-w-full tw-flex tw-justify-between tw-text-xs tw-text-left border-0 tw-text-greyTwo tw-bg-white tw-transition-all tw-delay-50 ${isOpen ? 'hover:tw-bg-blueVeryLight' : ''}`}
              onClick={() => handleClickElem(option)}
              style={{ backgroundColor: String(option?.value) === String(selected?.value) ? 'tw-bg-blueVeryLight' : '' }}
            >
              <div className="tw-w-44 tw-text-left tw-break-words">
                {t(option?.name)}
              </div>
              {option?.value === selected?.value && (
              <img
                src={checkDarkBlue}
                className=""
                alt="Check"
                width={16}
              />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
