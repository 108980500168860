import React from 'react';

const pdfUrl = encodeURI(`${process.env.REACT_APP_FILES_DOMAIN}/uploads/privacy-policy/Politique de confidentialité - Formulaire de prescription Le Galec par OpenClimat.pdf`);

function PrivacyPolicy() {
  const fullScreenStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    border: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    zIndex: 999999,
  };

  return (
    <div style={fullScreenStyle}>
      {pdfUrl && (
        <embed
          src={pdfUrl}
          type="application/pdf"
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </div>
  );
}

export default PrivacyPolicy;
