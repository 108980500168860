const getCompleteColorByScore = (score) => {
  if (score >= 1 && score < 2) {
    return 'notePasDengagement';
  }
  if (score >= 2 && score < 3) {
    return 'noteTresInsuffisante';
  }
  if (score >= 3 && score < 4) {
    return 'noteEncoreInsufissante';
  }
  if (score >= 4 && score < 5) {
    return 'noteIntermediaire';
  }
  if (score >= 5 && score < 6) {
    return 'noteForte';
  }
  if (score >= 6) {
    return 'noteTresForte';
  }
  return 'notePasDengagement';
};

export default getCompleteColorByScore;
