import { Tooltip } from 'bootstrap';

const loadBootstrapTooltips = async () => {
  if (document.readyState === 'complete') {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
    return () => {
      tooltipList.map((t) => t.dispose());
    };
  }
  return false;
};

export default loadBootstrapTooltips;
