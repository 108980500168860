import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [openModal, setOpenModal] = useState('');

  const open = (modalId) => setOpenModal(modalId);
  const close = () => setOpenModal('');

  return (
    <ModalContext.Provider value={{ open, close, openModal }}>
      {children}
    </ModalContext.Provider>
  );
}
