import React from 'react';

import Question from './Question';

function Category({ category, profile, partner, isPublished, hasWriteAccess, view, setFormContext, fetchData, setShowOptional }) {
  return (
    <section id={category.name} className="question-container">
      <div className="profile-question p-3 p-xl-4 pb-1 white-bg mb-4 tw-rounded tw-border tw-border-solid tw-border-greySix">
        <div className="text-start">
          <h2 className="tw-text-base tw-mb-0">
            {category.icon_path && category.icon_name && (
              <span className="primary-color d-inline-block align-bottom me-2">
                <img src={process.env.REACT_APP_FILES_DOMAIN + category.icon_path + category.icon_name} alt="icon" width="18" height="18" />
              </span>
            )}
            {category.name}
          </h2>
          {category.questions.map((question, index) => (
            <Question
              key={index}
              profile={profile}
              question={question}
              isPublished={isPublished}
              partner={partner}
              view={view}
              hasWriteAccess={hasWriteAccess}
              setFormContext={setFormContext}
              fetchData={fetchData}
              setShowOptional={setShowOptional}
              additionalClass={index + 1 < category.questions.length ? 'mb-4' : ''}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Category;
