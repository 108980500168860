import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';

import eye from '../../../../assets/ui/eye.svg';
import eyeSlash from '../../../../assets/ui/eye-slash.svg';

function PublishingSwitch({ isViewPublishedInThisLocale, view, publishing, locale, profilId }) {
  const [published, setPublished] = useState(false);
  const [newPublishing, setNewPublishing] = useState(publishing);
  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();

  const updatePublishing = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profilId}/publishing`, {
    credentials: 'include',
  });

  const handleCheck = async () => {
    const tmpPublishing = newPublishing;

    if (newPublishing[view].indexOf(locale) < 0) {
      newPublishing[view].push(locale);
    } else if (newPublishing[view].indexOf(locale) === 0) {
      newPublishing[view] = newPublishing[view].slice(1);
    } else {
      newPublishing[view] = newPublishing[view].slice(0, newPublishing[view].indexOf(locale));
    }

    setNewPublishing(tmpPublishing);
    setChecked(!checked);

    try {
      await updatePublishing.put({
        publishing: newPublishing,
      });

      if (updatePublishing.error) {
        // TODO pop toaster + erreur
        throw new Error(updatePublishing.error);
      } else {
        setPublished(!published);
        return true;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (isViewPublishedInThisLocale) {
      setChecked(isViewPublishedInThisLocale);
      setPublished(isViewPublishedInThisLocale);
    }
  }, [isViewPublishedInThisLocale]);

  return (
    <div className="container-fluid container-lg mb-5">
      <div className="row">
        <div className="col-12">
          <div className="white-bg cloudy-grey-color fw-400 mb-1 font-14 py-3 px-4 d-flex justify-content-between flex-wrap flex-sm-nowrap tw-rounded tw-border tw-border-solid tw-border-greySix">
            <div className="d-flex align-items-center">
              <div className="grey-LLLL-bg d-inline-block border-14 me-3">
                <img src={published ? eye : eyeSlash} className="eye-slash m-4" alt={published ? 'eye' : 'eye-slash'} />
              </div>
              {published ? <span>{t('SWITCH_MESSAGE_PUBLISHING_1')}<br />{t('SWITCH_MESSAGE_PUBLISHING_2')}</span>
                : <span>{t('SWITCH_MESSAGE_PUBLISHING_3')}<br />{t('SWITCH_MESSAGE_PUBLISHING_4')}</span>}
            </div>
            <div className="grey-LLLL-bg py-2 px-4 border-14 mt-3 mt-md-0">
              <div className="d-flex h-100">
                <div className="d-flex flex-column h-100 justify-content-center">
                  <span className="fw-500 font-15 primary-HH-color">{t('SWITCH_MESSAGE_PUBLISHING_8')} {published ? '' : t('SWITCH_MESSAGE_PUBLISHING_7') } {t('SWITCH_MESSAGE_PUBLISHING_6')}</span>
                  {t('SWITCH_MESSAGE_PUBLISHING_5')}
                </div>
                <div className="form-check form-switch ms-5 mb-0 d-flex align-items-center">
                  {updatePublishing.loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <input className="form-check-input publishing-switch" type="checkbox" id="flexSwitchCheckDefault" checked={checked} onChange={handleCheck} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishingSwitch;
