import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import upload from '../../../assets/ui/upload.svg';
import chevronDown from '../../../assets/ui/chevron-down-grey.svg';

function AddResourceForm({ partnerCategories, register, errors, watch, setValue, editingResource }) {
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);

  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  const file = watch('resource');
  const category = watch('category');

  const sortedCategories = [...partnerCategories].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (editingResource) {
      setValue('category', editingResource.partner_category_id);
    }
  }, [editingResource]);

  useEffect(() => {
    if (category) {
      const selectedCat = sortedCategories.find((cat) => cat.id === category);
      setSelectedCategory(selectedCat || null);
    } else {
      setSelectedCategory(null);
    }
  }, [category, sortedCategories]);

  const handleCategoryChange = (categoryItem) => {
    setValue('category', categoryItem.id, { shouldValidate: true });
    setShowCategoryDropdown(false);
  };

  const getDisplayFileName = () => {
    if (file && file[0]) {
      return file[0].name;
    }
    if (editingResource) {
      return editingResource.file_name;
    }
    return t('PARTNER_DASHBOARD.RESOURCES.FORM.UPLOAD_RESOURCE');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setValue('resource', droppedFiles);
    }
  };

  const getDropzoneClasses = () => {
    const baseClasses = 'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-[6px] tw-w-full tw-border tw-border-dashed tw-rounded-lg tw-cursor-pointer hover:tw-bg-greyEight tw-transition-all tw-duration-150 tw-p-4';

    if (errors.resource) {
      return `${baseClasses} tw-border-redError tw-bg-red/5`;
    }
    if (isDragActive) {
      return `${baseClasses} tw-border-secondarySkyBlue tw-bg-secondarySkyBlue/10`;
    }
    return `${baseClasses} tw-border-greySix`;
  };

  return (
    <div>
      <label htmlFor="resource" className="text-sm tw-text-greyOne tw-font-medium">
        {t('PARTNER_DASHBOARD.RESOURCES.FORM.RESOURCES')}
      </label>
      <div
        className="tw-mt-2.5"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <label
          htmlFor="resource"
          className={getDropzoneClasses()}
        >
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-[6px] p-">
            <img src={upload} alt="upload" />
            <p className="tw-text-sm tw-text-greyFour tw-m-0 tw-text-center">
              {getDisplayFileName()}
            </p>
            <p className="tw-text-[10px] tw-text-greyThree tw-m-0">{t('PARTNER_DASHBOARD.RESOURCES.FORM.PDF_MAX_SIZE')}</p>
          </div>
          <input
            id="resource"
            type="file"
            className="tw-hidden"
            accept=".pdf"
            {...register('resource', {
              required: editingResource ? false : t('PARTNER_DASHBOARD.RESOURCES.FORM.RESOURCE_REQUIRED'),
              validate: {
                lessThan20MB: (files) => !files[0] || files[0].size <= 20 * 1024 * 1024 || t('PARTNER_DASHBOARD.RESOURCES.FORM.FILE_TOO_LARGE'),
                isPDF: (files) => !files[0] || files[0].type === 'application/pdf' || t('PARTNER_DASHBOARD.RESOURCES.FORM.MUST_BE_PDF'),
              },
            })}
          />
        </label>
      </div>
      {errors.resource && (
        <p className="tw-mt-1 tw-text-sm tw-text-redError" role="alert">{errors.resource.message}</p>
      )}

      <div className="tw-mt-[30px]">
        <label htmlFor="category" className="tw-block tw-text-sm tw-text-greyOne tw-font-medium tw-mb-2.5">
          {t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY')}
        </label>
        <div className="tw-relative" ref={dropdownRef}>
          <button
            type="button"
            className={`tw-h-11 tw-w-full tw-text-left tw-text-sm tw-font-moskauGrotesk tw-font-normal ${selectedCategory ? 'tw-text-greyOne' : 'tw-text-greyFour'} tw-border tw-border-solid ${errors.category ? 'tw-border-redError' : 'tw-border-greySix'} tw-bg-white focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-py-3 tw-px-4 tw-flex tw-items-center tw-justify-between`}
            onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
          >
            <span>{selectedCategory ? selectedCategory.name : t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY')}</span>
            <img src={chevronDown} alt="Toggle dropdown" className="tw-ml-4 tw-h-5 tw-w-5" />
          </button>
          {showCategoryDropdown && (
          <div className="tw-absolute tw-z-10 tw-w-full tw-mt-1 tw-bg-white tw-rounded-md tw-max-h-96 tw-overflow-auto tw-border tw-border-greySix tw-border-solid tw-py-3">
            {sortedCategories.map((categoryItem) => (
              <label key={categoryItem.id} className="tw-flex tw-items-center tw-px-4 tw-py-3 hover:tw-bg-backgroundCleanBlue tw-cursor-pointer tw-text-sm tw-text-greyOne">
                <input
                  type="radio"
                  value={categoryItem.id}
                  checked={category === categoryItem.id}
                  onChange={() => handleCategoryChange(categoryItem)}
                  className="tw-hidden"
                  {...register('category', { required: t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY_REQUIRED') })}
                />
                <span className="tw-relative tw-inline-block tw-w-[14px] tw-h-[14px] tw-mr-2 tw-rounded-full tw-border tw-border-solid tw-border-greyFour">
                  <span className={`tw-absolute tw-inset-[2px] tw-rounded-full ${category === categoryItem.id ? 'tw-bg-primaryNewBlue' : ''}`} />
                </span>
                <span className={`${category === categoryItem.id ? 'tw-font-medium' : ''}`}>{categoryItem.name}</span>
              </label>
            ))}
          </div>
          )}
        </div>
        {errors.category && (
        <p className="tw-mt-1 tw-text-sm tw-text-redError" role="alert">{errors.category.message}</p>
        )}
      </div>
    </div>
  );
}

export default AddResourceForm;
