import React, { useEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import bug from '../../../../assets/ui/bug.svg';

import tailwindConfig from '../../../../tailwind.config';

import getLocalLink from '../../../../utils/getLocalLink';
import getTabsQuestionsCount from '../../../../utils/getTabsQuestionsCount';
import getUserLocal from '../../../../utils/getUserLocal';
import questionType from '../../../../utils/questionType';
import handleResponse from '../../../../utils/handleResponse';

function Tabs({ view }) {
  const [categoryFilter, setCategoryFilter] = useState();
  const [views, setViews] = useState([]);
  const { partner, profile, params, session, isDashboard } = useOutletContext();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}`);

  const viewsInfo = [
    {
      url: getLocalLink(`${params?.slug}`),
      label: 'OVERVIEW',
      view: 'preview',
      display: true,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/approach`),
      label: 'OUR_APPROACH',
      view: 'approach',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/actions`),
      label: 'ACTION_PLAN',
      view: 'actions',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/other_esg`),
      label: 'OTHER_ESG',
      view: 'other_esg',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/co2_by_product`),
      label: 'CO2_BY_PRODUCT',
      view: 'co2_by_product',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/climat`),
      label: 'CLIMAT',
      view: 'climat',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/environment`),
      label: 'ENVIRONMENT',
      view: 'environment',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/governance`),
      label: 'GOVERNANCE',
      view: 'governance',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/questions`),
      label: getUserLocal() === 'fr' ? t('PRIVATE_QUESTIONS_OF') + partner.name : partner.name + t('PRIVATE_QUESTIONS_OF'),
      view: 'private_questions',
      display: false,
      disabled: false,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTabsQuestionsCount(api.get, api.response, profile.type, partner.id, profile.typology_id, getUserLocal(), profile.id);

        if (response) {
          const updatedViews = viewsInfo.map((view) => {
            const display = response.some((question) => ((question.is_required || (!question.is_required && question.answer) || session?.roles?.includes('ROLE_ADMIN') || session?.roles?.includes('ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS')) && (questionType[question.tab] === view.view)) || view.view === 'preview');

            let disabled = false;
            if (view.view !== 'preview' && view.view !== 'environment' && view.view !== 'governance' && view.view !== 'climat') {
              if (profile.type !== 2 && profile.is_managed === false) {
                disabled = true;
              }

              if (profile.type === 2 && !profile.is_premium) {
                disabled = true;
              }
            }

            return { ...view, display, disabled };
          });

          setViews(updatedViews);
        }
      } catch (error) {
        handleResponse(error);
      }
    };

    fetchData();
  }, [profile]);

  useEffect(() => {
    const categoryFilter = searchParams.get('cf');

    if (categoryFilter) {
      setCategoryFilter(categoryFilter);
    }
  }, [searchParams]);

  return (
    <>
      <div id="sub-header" className={`${profile?.type !== 1 ? 'mb-4 ' : ''} container-fluid tw-px-0 tw-bg-backgroundCleanBlue border-bottom`}>
        <div className="container tw-px-0 tw-mx-0 md:tw-mx-auto">
          <div className="tw-flex tw-justify-between tw-w-full tw-items-center">
            <div id="scroll-container" className="tw-flex custom-horizontal-scrollbar tw-w-full">
              <div id="shadow-start" className="shadow-start shadow--start" />
              {views.map((profile, index) => {
                function onMouseEnterSetBackground(index) {
                  if (view !== profile.view) {
                    document.getElementById(`tabsBorder${index}`).style.background = tailwindConfig.theme.colors.primaryNewBlue;
                    document.getElementById(`tabsText${index}`).style.color = tailwindConfig.theme.colors.primaryNewBlue;
                  }
                }

                function onMouseLeaveSetBackground(index) {
                  if (view !== profile.view) {
                    document.getElementById(`tabsBorder${index}`).style.background = 'unset';
                    document.getElementById(`tabsText${index}`).style.color = tailwindConfig.theme.colors.greyFour;
                  }
                }

                const bottomBorderStyle = {
                  backgroundColor: view === profile.view ? tailwindConfig.theme.colors.primaryNewBlue : '',
                };

                if (profile.display && !profile.disabled) {
                  return (
                    <div
                      key={index}
                      className={`tw-mx-0 mx-md-1 px-md-3 subview ${view === profile.view ? 'view-active' : ''} tw-flex tw-flex-col tw-items-center`}
                      onMouseEnter={() => onMouseEnterSetBackground(index)}
                      onMouseLeave={() => onMouseLeaveSetBackground(index)}
                      style={{
                        minWidth: `${document.getElementById(`tabsText${index}`)?.getBoundingClientRect().width}px`,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Link
                        id={`tabsText${index}`}
                        to={`${(isDashboard ? '/dashboard/suppliers/' : '/profile/') + profile.url}${searchParams.get('partner') ? '&' : '?'}${categoryFilter ? `cf=${categoryFilter}` : ''}`}
                        className={`d-block p-3 text-decoration-none my-auto tw-text-greyFour tw-font-moskauGrotesk ${view === profile.view ? 'tw-text-primaryNewBlue tw-font-bold' : ''}`}
                      >
                        {t(profile.label)}
                      </Link>
                      <div
                        className="custom-border-bottom"
                        id={`tabsBorder${index}`}
                        style={bottomBorderStyle}
                      />
                    </div>
                  );
                }
                return profile.display && profile.disabled ? (
                  <div
                    key={index}
                    className="tw-mx-0 mx-md-1 px-md-3 subview tw-flex tw-flex-col tw-items-center p-3 tw-text-greySix tw-font-moskauGrotesk tw-text-sm tw-justify-center"
                    style={{
                      minWidth: `${document.getElementById(`tabsText${index}`)?.getBoundingClientRect().width}px`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t(profile.label)}
                  </div>
                ) : null;
              })}
              <div className="shadow-end shadow--end" />
            </div>
          </div>
        </div>
      </div>
      {profile?.type === 1 && (
        <div className="container-fluid tw-bg-secondaryCreamBg tw-py-2 tw-my-3.5">
          <div className="container d-flex justify-center">
            <img src={bug} className="tw-mr-3" alt="warrning" />
            <span className="fw-500 font-12 tw-text-greyOne tw-self-center">
              {t('SUBSIDIARY_OF_THE_GROUP_HEADER', { group: profile?.parent_company_name, profile: profile?.name })}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default Tabs;
