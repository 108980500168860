import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SBTI from '../../../../assets/ui/SBTI.svg';
import CDP from '../../../../assets/ui/CDP.svg';
import info from '../../../../assets/ui/i-info.svg';

import getLabelsClimat from '../../../../utils/getLabelsClimat';
import loadBootstrapTooltips from '../../../../utils/loadBootstrapTooltips';

function Content({ item, resultWording, index, data, t }) {
  let wording;

  if (item.title === 'TARGET_SBT_LABEL') {
    if (item.value !== '-') {
      wording = t(resultWording[item.value]);
    } else {
      wording = item.value;
    }
  } else {
    wording = item.value;
  }

  return (
    <div key={index} className="info-card font-14">
      <div className="d-flex">
        <div>
          {item.title === 'CDP_SCORE_LABEL' ? (
            <img
              src={CDP}
              className="pe-3 more-info-label-svg"
              alt="cdp"
            />
          ) : (
            <img
              src={SBTI}
              className="pe-3 more-info-label-svg"
              alt="sbti"
            />
          )}
        </div>
        <div>
          <div className="cloudy-grey-color fw-400 mb-1 d-flex flex-row">
            <div>
              {t(item.title)}
            </div>
            <div className="primary-color d-flex align-items-center ms-1" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={t(item.tooltip)}>
              <img src={info} alt="info" />
            </div>
          </div>
          <div className="fw-500">
            {wording}
          </div>
          {index < data.length - 1 && <hr style={{ color: '#0000003e' }} />}
        </div>
      </div>
    </div>
  );
}

function LabelsClimat({ profile }) {
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  // const netZeroLabel = [
  //   'RESULT_NET_ZERO_1',
  //   'RESULT_NET_ZERO_2',
  // ];

  const resultWording = [
    '-',
    'RESULT_WORDING_1',
    'RESULT_WORDING_2',
    'RESULT_WORDING_3',
    'RESULT_WORDING_4',
  ];

  useEffect(() => {
    getLabelsClimat(profile.id).then((response) => {
      const items = [
        {
          title: 'TARGET_SBT_LABEL',
          value: response.target_sbt ? response.target_sbt : '-',
          tooltip: 'TARGET_SBT_TOOLTIP',
        },
        {
          title: 'CDP_SCORE_LABEL',
          value: response.scope_cdp ? response.scope_cdp : '-',
          tooltip: 'CDP_SCORE_LABEL_TOOLTIP',
        },
      ];
      setData(items);
      loadBootstrapTooltips();
    });
  }, [profile]);

  return (
    <div className="white-bg mb-4 p-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
      <h1 className="tw-text-greyOne tw-text-base d-flex align-items-middle justify-content-start mb-3">
        {t('MORE_INFO_TITLE')}
      </h1>
      <div className="row">
        {data.map((item, index) => (
          <Content
            key={index}
            index={index}
            data={data}
            item={item}
            resultWording={resultWording}
            t={t}
          />
        ))}
      </div>
    </div>
  );
}

export default LabelsClimat;
