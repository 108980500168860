import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import useFetch from 'use-http';

import '../Form.scss';

function Mail() {
  const { partner, mailRequested, setMailRequested } = useOutletContext();
  const { t } = useTranslation();
  const { post, loading } = useFetch(process.env.REACT_APP_PARTNER_API);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      await post('/login', {
        domain: partner.domain,
        email: data.email,
      });

      setMailRequested(data.email);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div>
      {mailRequested ? (
        <>
          <h3 className="login-title mb-3 tw-mt-9">{t('PARTNER_LOGIN_TEXT_7')}</h3>
          <p>{t('PARTNER_LOGIN_TEXT_8')} <b>{ mailRequested }</b> {t('PARTNER_LOGIN_TEXT_9')}</p>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id="partner-login-form">
          <p className="tw-mt-9 tw-text-base tw-text-greyTwo tw-mb-9">
            {t('LOGIN_FORM.TEXT_D')}
          </p>
          <label htmlFor="inputEmail" className="tw-mb-2.5 tw-font-medium">{t('PARTNER_LOGIN_TEXT_2')}</label>
          <input
            type="email"
            {...register('email', {
              required: t('FORM_CONTACT_COMPANY_EMAIL_MISSING'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('FORM_CONTACT_COMPANY_EMAIL_BAD_FORMAT'),
              },
            })}
            name="email"
            id="inputEmail"
            className="tw-flex tw-w-full tw-border-greySix tw-border tw-border-solid tw-rounded-md tw-py-3 tw-px-5 tw-text-greyFour focus:tw-border-secondarySkyBlue focus:tw-outline-secondarySkyBlue"
            autoComplete="email"
            placeholder={t('LOGIN_FORM.PASSWORD.PLACEHOLDER_EMAIL')}
          />
          {errors.email && <div className="invalid-feedback d-block">{errors.email.message}</div>}
          <button type="submit" className="tw-w-full tw-mt-[30px] tw-py-3 tw-bg-primaryNewBlue tw-border-0 tw-rounded tw-text-white" disabled={loading}>
            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : t('PARTNER_LOGIN_TEXT_3')}
          </button>
        </form>
      )}
    </div>
  );
}

export default Mail;
