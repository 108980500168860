import React, { useEffect, useState } from 'react';
import { useLoaderData, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function Login() {
  const [mailRequested, setMailRequested] = useState(null);
  const { partner, features } = useLoaderData();
  let { state } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (mailRequested !== null) {
      state = null;
    }
  }, [mailRequested]);

  return (
    <>
      <Header partner={partner} showHeader={false} />
      <div id="main-content" className="tw-flex tw-flex-col tw-justify-center tw-align-middle tw-min-h-screen tw-font-moskauGrotesk">
        {state?.message && !mailRequested && <div className="mt-5 alert alert-danger">{t(state.message)}</div>}
        <div className="tw-rounded-lg tw-bg-white tw-p-14 tw-h-auto tw-self-center tw-max-w-[660px] sm:tw-my-24">
          <div className="tw-flex tw-justify-center">
            {partner.logo_header ? (
              <img
                src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_header}`}
                id="partner-logo-header"
                alt={partner.name}
                className="my-0 py-0"
                height="82"
              />
            ) : (
              partner.name
            )}
          </div>
          <h1 className="tw-text-3xl tw-text-greyOne tw-text-center tw-mt-10">
            {t('LOGIN_FORM.TEXT_A', { partnername: partner.name })}
          </h1>
          <Outlet context={{ partner, mailRequested, setMailRequested }} />
          <div className="tw-border-t tw-border-b-0 tw-border-x-0 tw-border-solid tw-border-greySeven tw-mt-[50px] tw-mb-[30px]" />
          <p className="tw-text-center tw-text-greyTwo tw-mb-0">
            {t('LOGIN_FORM.TEXT_B')} <a href="mailto:support@openclimat.com" className="tw-font-normal tw-text-greyFour hover:tw-text-greyThree tw-underline">support@openclimat.com</a>
          </p>
        </div>
      </div>
      <Footer partner={partner} features={features} />
    </>
  );
}

export default Login;
