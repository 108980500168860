import isProductionEnvironement from './isProductionEnvironement';

const getPartnerData = async (partner) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/partner${isProductionEnvironement() ? '' : `?domain=${partner}`}`);
    const results = await response.json();

    return results;
  } catch (error) {
    return false;
  }
};

export default getPartnerData;
