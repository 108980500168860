const handleSelectChange = (
  e,
  position,
  description,
  sectorId,
  allLevels,
  sectors,
  setSectors,
  setSelected,
  setDescription,
  setFilteredCategory,
  searchParams,
  setSearchParams,
  QUERY_NAME,
) => {
  const selectedOption = e.target.selectedOptions[0];

  setSelected((prevSelected) => {
    // Create a copy of the array up to the position of the clicked category
    const newSelected = prevSelected.slice(0, position);

    // Find the index of the category at the same level in the array
    const index = newSelected.findIndex((selection) => selection.position === position);

    // If there is a category at the same level, replace it
    if (index !== -1) {
      newSelected[index] = {
        id: e.target.value,
        position,
        name: selectedOption.text,
        description: selectedOption.getAttribute('data-description'),
        icon: selectedOption.getAttribute('data-svg'),
        resources: JSON.parse(selectedOption.getAttribute('data-resources')),
      };
    } else if (newSelected.length < sectors.length + 1) {
      // If there isn't a category at the same level and we haven't reached the limit, add it
      newSelected.splice(position, 0, {
        id: e.target.value,
        position,
        name: selectedOption.text,
        description: selectedOption.getAttribute('data-description'),
        icon: selectedOption.getAttribute('data-svg'),
        resources: JSON.parse(selectedOption.getAttribute('data-resources')),
      });
    }

    const fullCategory = allLevels
      .flatMap((sector) => sector.level)
      .find((level) => level.categories.some((cat) => cat.id === e.target.value));

    const currentLevelIndex = allLevels.findIndex((level) => level.level[0].categories.some((cat) => cat.id === e.target.value));

    const nextLevel = allLevels[currentLevelIndex + 1];

    if (fullCategory && (sectors.length < allLevels.length || fullCategory.position > 0)) {
      const categoryWithSubCategories = fullCategory.categories.find((cat) => cat.id === e.target.value);

      setSectors((prevSectors) => {
        // Create a copy of the array to avoid mutating the state directly
        const newSectors = [...prevSectors];

        // Replace the categories below the selected category with the subcategories of the selected category
        newSectors.splice(position + 1, newSectors.length - position - 1, {
          level: [
            {
              categories: categoryWithSubCategories.sub_categories,
              name: nextLevel.level[0].name ? nextLevel.level[0].name : `Niveau ${nextLevel.level[0].position}`,
              id: nextLevel.level[0].id,
            },
          ],
        });

        return newSectors;
      });
    }

    // Update the URL and the filtered category
    searchParams.set(QUERY_NAME, e.target.value);
    setSearchParams(searchParams);

    setDescription((prevState) => {
      // Check if the category is already in the prevState
      const existingCategory = prevState.find((cat) => cat.id === e.target.value);

      // If the category is not in the prevState, add it
      if (!existingCategory || description.length !== position) {
        return [
          ...prevState.slice(0, position),
          {
            id: e.target.value,
            position,
            name: selectedOption.text,
            description: selectedOption.getAttribute('data-description'),
            icon: selectedOption.getAttribute('data-svg'),
            resources: JSON.parse(selectedOption.getAttribute('data-resources')),
            sectorId,
          },
        ];
      }

      // If the category is already in the prevState, return the prevState as is
      return prevState;
    });

    setFilteredCategory(selectedOption.text);

    return newSelected;
  });
};

export default handleSelectChange;
