const formatTurnover = (amount, type, t) => {
  if (type === 1) {
    return `${amount} ${t('MILLION_€')}`;
  }

  if (type === 2) {
    return `${amount} ${t('BILLION_€')}`;
  }

  return 'n.a.';
};

export default formatTurnover;
