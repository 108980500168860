import React, { useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import previousArrow from '../../../../assets/ui/previous-arrow.svg';
import nextArrow from '../../../../assets/ui/next-arrow.svg';
import dropdownArrow from '../../../../assets/ui/dropdown-arrow-down.svg';

import getPartnerCategories from '../../../../utils/getPartnerCategories';
import handleResponse from '../../../../utils/handleResponse';
import findParentCategory from '../../../../utils/findParentCategory';
import categoryIcons from '../../../../utils/categoryIcons';

import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function Suppliers({ isActive, partner, isPrivatePartner, getIconStyle, storeIcon, storeIconActive }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [allLevels, setAllLevels] = useState([]);
  const [topLevelCategories, setTopLevelCategories] = useState([]);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState();
  const [showCategories, setShowCategories] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const QUERY_NAME = 'cf';

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/categories`, {
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (categories.length > 0) {
      setTimeout(() => setShowCategories(true), 0);
    } else {
      setShowCategories(false);
    }
  }, [categories.length]);

  const getCategories = async () => {
    try {
      const result = await getPartnerCategories(api.get);
      if (result) {
        if (result[0].level[0].categories) {
          setCategories([result[0]]);
          setDisplayedCategories(result[0].level[0].categories);
          setTopLevelCategories(result[0].level[0].categories);
          setAllLevels(result);
        } else {
          setCategories([result[1]]);
          setDisplayedCategories(result[1].level[0].categories);
          setTopLevelCategories(result[1].level[0].categories);
          setAllLevels(result.filter((item, index) => index !== 0));
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      handleResponse(api.response);
    }
  };

  useEffect(() => {
    if (isActive) {
      getCategories();
    } else {
      setCategories([]);
      setSelected();
      setDisplayedCategories([]);
      setAllLevels([]);
      setTopLevelCategories([]);
    }
  }, [isActive]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const categoryFilterId = params.get(QUERY_NAME);

    if (!categoryFilterId && topLevelCategories) {
      setSelected();
      setDisplayedCategories(topLevelCategories);
    }
  }, [searchParams]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const categoryFilterId = params.get(QUERY_NAME);

    if (categoryFilterId) {
      // Find the level that contains the category
      const levelContainingCategory = allLevels.find((sector) => sector.level[0].categories.some((cat) => cat.id === categoryFilterId));

      // Find the category in the found level
      const selectedCategory = levelContainingCategory
        ? levelContainingCategory.level[0].categories.find((cat) => cat.id === categoryFilterId)
        : null;

      if (selectedCategory) {
        const parentCategory = findParentCategory(selectedCategory, allLevels);

        if (parentCategory) {
          setSelected(parentCategory);
          setDisplayedCategories(parentCategory.sub_categories);
        } else {
          setSelected();
          setDisplayedCategories(topLevelCategories);
        }

        setSelected({ name: selectedCategory.name, id: selectedCategory.id, sub_categories: selectedCategory.sub_categories });
        setDisplayedCategories(selectedCategory.sub_categories ? selectedCategory.sub_categories : null);
      }
    }
  }, [allLevels]);

  const getCategory = (category) => {
    setLoading(true);
    // Find the level that contains the category
    const levelContainingCategory = allLevels.find((sector) => sector.level[0].categories.some((cat) => cat.id === category.id));

    // Find the category in the found level
    const selectedCategory = levelContainingCategory
      ? levelContainingCategory.level[0].categories.find((cat) => cat.id === category.id)
      : null;

    setSelected({ name: selectedCategory.name, id: selectedCategory.id, sub_categories: selectedCategory.sub_categories });
    setDisplayedCategories(selectedCategory.sub_categories ? selectedCategory.sub_categories : null);

    if (location.pathname !== '/dashboard/suppliers') {
      navigate(`/dashboard/suppliers?${searchParams.toString()}`);
    }
  };

  const handleCategoryClick = (e, category) => {
    e.preventDefault();

    searchParams.set(QUERY_NAME, category.id);
    searchParams.set('p', 0);
    setSearchParams(searchParams);
  };

  const handlePreviousCategoryClick = (e, category) => {
    e.preventDefault();
    setLoading(true);
    const parentCategory = findParentCategory(category, allLevels);

    if (parentCategory) {
      setSelected(parentCategory);
      setDisplayedCategories(parentCategory.sub_categories);

      searchParams.set(QUERY_NAME, parentCategory.id);
      searchParams.set('p', 0);
      setSearchParams(searchParams);
    } else {
      setSelected();
      setDisplayedCategories(topLevelCategories);

      searchParams.set('p', 0);
      searchParams.delete(QUERY_NAME);
      setSearchParams(searchParams);
    }

    if (location.pathname !== '/dashboard/suppliers') {
      navigate(`/dashboard/suppliers?${searchParams.toString()}`);
    }
  };

  useEffect(() => {
    const categoryId = searchParams.get('cf');
    if (categoryId && allLevels.length > 0) {
      getCategory({ id: categoryId });
    }
  }, [searchParams.get('cf'), allLevels]);

  return (
    <div className="tw-w-full tw-h-full">
      <div type="button" className={`tw-w-full tw-flex tw-justify-between tw-p-3 ${isActive ? 'tw-text-greyOne tw-bg-greyEight' : 'tw-text-greyThree hover:tw-text-greyOne'} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
        <div className="tw-flex tw-items-center tw-gap-x-5">
          {isPrivatePartner &&
          <span style={getIconStyle(storeIcon, storeIconActive, isActive, 'suppliers')} />}
          <span className={`${isPrivatePartner ? 'tw-pl-0' : 'tw-pl-2'}`}>{t('PARTNER_DASHBOARD.SIDEBAR.SUPPLIERS')}</span>
        </div>
        <img src={dropdownArrow} className={`tw-transition-all tw-duration-500 ${isActive ? 'tw-rotate-180' : 'tw-rotate-0'}`} alt="Dropwdown arrow" />
      </div>
      <div className={`tw-overflow-hidden tw-transition-all tw-duration-1000 ${showCategories ? 'tw-max-h-[450px]' : 'tw-max-h-0'}`}>
        {categories.length > 0 && (
        <div className="tw-mt-4">
          {selected && (
            <button
              type="button"
              onClick={(e) => handlePreviousCategoryClick(e, selected)}
              className="tw-flex tw-w-full tw-bg-white tw-border-0 tw-p-2 tw-items-center tw-gap-2 tw-text-greyThree tw-text-sm tw-font-medium tw-mb-3 hover:tw-text-greyOne hover:tw-bg-greyEight"
            >
              <img src={previousArrow} alt="Previous arrow" />
              {selected.name}
            </button>
          )}
          <ul className="tw-space-y-3 tw-pl-7 tw-max-h-96 tw-overflow-auto">
            {displayedCategories?.sort((a, b) => a.name.localeCompare(b.name)).map((category) => (
              <li className="tw-list-none" key={category.id}>
                <button
                  type="button"
                  onClick={(e) => handleCategoryClick(e, category)}
                  className="tw-flex tw-w-full tw-bg-white tw-p-2 tw-border-0 tw-text-start tw-justify-between tw-text-greyThree hover:tw-text-greyOne tw-text-sm tw-font-medium hover:tw-bg-greyEight"
                >
                  <div className="tw-flex tw-gap-x-2 tw-items-center">
                    {(category.icon && !isPrivatePartner) && (<img src={categoryIcons.find((icon) => icon.value === category.icon)?.icon} alt="Next arrow" width={16} height={16} />)}
                    <span>
                      {category.name}
                    </span>
                  </div>
                  <img src={nextArrow} alt="Next arrow" />
                </button>
              </li>
            ))}
          </ul>
        </div>
        )}
      </div>
    </div>
  );
}

export default Suppliers;
