const getQuestions = async (api, profileId, domain, view, locale, questionType, typologyId, partnerId, hasWriteAccess) => {
  try {
    const response = await api.get(`/partner/${partnerId}/profiles/${profileId}/questions?domain=${domain}&type=${questionType}&view=${view}&locale=${locale}&typology_id=${typologyId}${hasWriteAccess ? '&has_write_access=true' : ''}`);

    if (api.response.ok) {
      return response;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default getQuestions;
