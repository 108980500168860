import React from 'react';
import { useTranslation } from 'react-i18next';

import handshake from '../../../assets/ui/landingPartner/handshake.svg';
import medal from '../../../assets/ui/landingPartner/medal-military.svg';
import cornersIn from '../../../assets/ui/landingPartner/corners-in.svg';
import chat from '../../../assets/ui/landingPartner/double-chat.svg';

import Card from './Card';

function Share() {
  const { t } = useTranslation();

  const cards = [
    {
      icon: medal,
      iconName: 'handshake',
      textOne: t('LANDING_LECLERC.SHARE.CARD_A.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_A.TEXT_PART_TWO'),
    },
    {
      icon: chat,
      iconName: 'medal',
      textOne: t('LANDING_LECLERC.SHARE.CARD_B.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_B.TEXT_PART_TWO'),
    },
    {
      icon: handshake,
      iconName: 'corners-in',
      textOne: t('LANDING_LECLERC.SHARE.CARD_C.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_C.TEXT_PART_TWO'),
    },
    {
      icon: cornersIn,
      iconName: 'git-diff',
      textOne: t('LANDING_LECLERC.SHARE.CARD_D.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_D.TEXT_PART_TWO'),
    },
  ];

  return (
    <section className="tw-bg-[#FBFBFB]">
      <div className="tw-container tw-flex tw-flex-col tw-items-center tw-px-[30px] xl:tw-px-20 tw-pt-20 tw-pb-[50px] tw-mx-auto">
        <h4 className="tw-font-bold tw-text-leclercOrange tw-text-[42px] xl:tw-text-3xl tw-text-center">
          {t('LANDING_LECLERC.SHARE.TITLE')}
        </h4>
        <div className="tw-text-primaryNewBlue tw-mt-10 tw-font-medium tw-text-lg xl:tw-text-center xl:tw-px-40">
          {t('LANDING_LECLERC.SHARE.TEXT_A')}
        </div>
        <div className="tw-w-full lg:tw-w-4/5 xl:tw-w-3/4 2xl:tw-w-full">
          <div className="tw-mt-[60px] xl:tw-mt-20 tw-gap-[30px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 2xl:tw-grid-cols-4 tw-justify-items-center ">

            {cards.map((card, index) => (
              <Card key={index} card={card} />
            ))}

          </div>
        </div>

        <div className="tw-text-center tw-mt-14 tw-flex tw-justify-center">
          <a href="#contact-form" className="tw-bg-primaryNewBlue tw-block tw-rounded-md tw-px-7 tw-py-2.5 tw-text-white tw-no-underline text-sm hover:tw-bg-primaryNewBlueHover">
            {t('LANDING_LECLERC.SHARE.CTA')}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Share;
