import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import getLocalLink from '../../../utils/getLocalLink';
import getLogout from '../../../utils/getLogout';
import Dashboard from '../../../assets/ui/dashboard.svg';
// import Settings from '../../../assets/ui/settings.svg';
import Logout from '../../../assets/ui/logout.svg';

import './Authenticated.scss';
import verifyAuthorization from '../../../utils/verifyAuthorization';

function Authenticated({ session, partner }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const listRef = useRef();

  const handleClickOutside = (e) => {
    if (!listRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div ref={listRef} id="navbarNavDarkDropdown">
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <button className={`nav-link font-14 dropdown-toggle ${show ? 'show' : ''}`} onClick={() => setShow(!show)} type="button">
            <span className="avatar me-2" style={{ background: partner.primary_color }}>{session.firstname[0]}{session.lastname[0]}</span>
            <span className="me-1">{session.firstname} {session.lastname}</span>
          </button>
          <ul className={`dropdown-menu dropdown-menu-light ${show ? 'show' : ''}`}>
            {verifyAuthorization(session, ['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']) ? (
              <li className="mb-1">
                <Link className="dropdown-item d-flex justify-content-start font-15" to={getLocalLink('/dashboard/home')}>
                  <img src={Dashboard} alt="dashboard" className="me-2" />
                  {t('DASHBOARD')}
                </Link>
              </li>
            ) : ('')}
            <li className="mb-1">
              <button className="dropdown-item d-flex justify-content-start align-items-center font-15" type="button" onClick={() => getLogout()}>
                <img src={Logout} alt="Logout" className="me-2" />
                {t('DISCONNECT')}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Authenticated;
