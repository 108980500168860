import React from 'react';
import parse from 'html-react-parser';
import { sanitize } from 'dompurify';

function Presentation({ text }) {
  return (
    <div className="tw-p-5 tw-text-greyThree tw-text-sm tw-bg-white tw-rounded tw-border tw-border-solid tw-border-greySeven">
      {parse(sanitize(text))}
    </div>
  );
}

export default Presentation;
