import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
// import Features from './features.json';

import App from './components/App';
import Login from './components/Login/Login';
import Check from './components/Login/Check/Check';
import Form from './components/Login/Form/Form';
import Category from './components/Dashboard/Category/Category';
import Dashboard from './components/Dashboard/Dashboard';
import Public from './components/Public/Public';
import Profile from './components/Public/Profile/Profile';
import Preview from './components/Public/Profile/Pages/Preview/Preview';
import Homepage from './components/Public/Homepage';
import NotFound from './components/NotFound/NotFound';
import InternalError from './components/InternalError/InternalError';
import Approach from './components/Public/Profile/Pages/Approach/Approach';
import ActionPlan from './components/Public/Profile/Pages/ActionPlan/ActionPlan';
import ResponsibleOffer from './components/Public/Profile/Pages/ResponsibleOffer/ResponsibleOffer';
import Climat from './components/Public/Profile/Pages/Climat/Climat';
import Environment from './components/Public/Profile/Pages/Environment/Environment';
import Governance from './components/Public/Profile/Pages/Governance/Governance';
import PrivateQuestions from './components/Public/Profile/Pages/PrivateQuestions/PrivateQuestions';
import DashboardHome from './components/Dashboard/DashboardHome/DashboardHome';
import Statistics from './components/Dashboard/Statistics/Statistics';
import OtherEsg from './components/Public/Profile/Pages/OtherEsg/OtherEsg';
import Suppliers from './components/Dashboard/Suppliers/Suppliers';
import Ressources from './components/Dashboard/Ressources/Ressources';
import ResourcePreview from './components/Dashboard/Ressources/ResourcePreview';

import getLocalLink from './utils/getLocalLink';
import getPartnerData from './utils/getPartnerData';
import getPartnerName from './utils/getPartnerName';
import getSession from './utils/getSession';
import getFeatures from './utils/getFeatures';
import setFavicon from './utils/setFavicon';
import getProfileData from './utils/getProfileData';
import getUserLocal from './utils/getUserLocal';
import verifyAuthorization from './utils/verifyAuthorization';
import Landing from './components/Landing/Landing';
import Features from './features.json';
import PrivacyPolicy from './components/Landing/PrivacyPolicy/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <InternalError />,
    loader: async () => {
      const session = await getSession(getPartnerName());
      const partner = await getPartnerData(getPartnerName());

      if (!partner) {
        return redirect('/404');
      }

      if (partner.private) {
        if (!session) {
          return redirect(getLocalLink('/login')); // redirect with following ?partner=xxx for dev env
        }
        if (session) {
          return redirect(getLocalLink('/dashboard/home'));
        }
      }

      const features = await getFeatures(partner.id);

      setFavicon(`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_favicon}`);
      document.title = `${partner.name}, espace climat`;

      return { partner, session, features };
    },
    shouldRevalidate: () => false,
    children: [
      {
        path: '/',
        element: <Public />,
        children: [
          {
            path: '/',
            element: <Homepage />,
          },
          {
            path: 'profile/:slug',
            element: <Profile />,
            loader: async ({ params }) => {
              const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

              if (!profile) {
                return redirect(getLocalLink('/')); // redirect with following ?partner=xxx for dev env
              }

              return { profile, params };
            },
            children: [
              {
                path: '',
                element: <Preview view="preview" />,
              },
              {
                path: 'approach',
                element: <Approach view="approach" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (!profile.is_managed) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'actions',
                element: <ActionPlan view="actions" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (!profile.is_managed) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'other_esg',
                element: <OtherEsg view="other_esg" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (!profile.is_managed) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'co2_by_product',
                element: <ResponsibleOffer view="co2_by_product" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (!profile.is_managed) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'climat',
                element: <Climat view="climat" />,
              },
              {
                path: 'environment',
                element: <Environment view="environment" />,
              },
              {
                path: 'governance',
                element: <Governance view="governance" />,
              },
              {
                path: 'questions',
                element: <PrivateQuestions view="private_questions" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (!profile.is_managed) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
            ],
          },
        ],
      },
    ],
  }, {
    path: 'dashboard',
    element: <Dashboard />,
    loader: async () => {
      const session = await getSession(getPartnerName());
      const partner = await getPartnerData(getPartnerName());

      if (!partner) {
        return redirect('/404');
      }

      if (!session || !verifyAuthorization(session, ['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS'])) {
        return redirect(getLocalLink('/login'));
      }

      const features = await getFeatures(partner.id);

      setFavicon(`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_favicon}`);
      document.title = `${partner.name}, espace climat`;

      const isDashboard = true;

      return { partner, session, features, isDashboard };
    },
    children: [
      {
        path: 'home',
        element: <DashboardHome />,
      },
      {
        path: 'suppliers',
        children: [
          {
            path: '',
            element: <Suppliers />,
          },
          {
            path: ':slug',
            element: <Profile />,
            loader: async ({ params }) => {
              const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

              return { profile, params };
            },
            children: [
              {
                path: '',
                element: <Preview view="preview" />,
              },
              {
                path: 'approach',
                element: <Approach view="approach" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (profile.type !== 2 && profile.is_managed === false) {
                    return redirect('/404');
                  }

                  if (profile.type === 2 && !profile.is_premium) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'actions',
                element: <ActionPlan view="actions" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (profile.type !== 2 && profile.is_managed === false) {
                    return redirect('/404');
                  }

                  if (profile.type === 2 && !profile.is_premium) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'other_esg',
                element: <OtherEsg view="other_esg" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (profile.type !== 2 && profile.is_managed === false) {
                    return redirect('/404');
                  }

                  if (profile.type === 2 && !profile.is_premium) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'co2_by_product',
                element: <ResponsibleOffer view="co2_by_product" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (profile.type !== 2 && profile.is_managed === false) {
                    return redirect('/404');
                  }

                  if (profile.type === 2 && !profile.is_premium) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
              {
                path: 'climat',
                element: <Climat view="climat" />,
              },
              {
                path: 'environment',
                element: <Environment view="environment" />,
              },
              {
                path: 'governance',
                element: <Governance view="governance" />,
              },
              {
                path: 'questions',
                element: <PrivateQuestions view="private_questions" />,
                loader: async ({ params }) => {
                  const profile = await getProfileData(params.slug, getPartnerName(), getUserLocal());

                  if (profile.type !== 2 && profile.is_managed === false) {
                    return redirect('/404');
                  }

                  if (profile.type === 2 && !profile.is_premium) {
                    return redirect('/404');
                  }

                  return null;
                },
              },
            ],
          },
        ],
      },
      {
        path: 'statistics',
        element: <Statistics />,
        loader: async () => {
          const session = await getSession(getPartnerName());

          if (!session || !verifyAuthorization(session, ['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER'])) {
            return redirect(getLocalLink('/dashboard/home'));
          }

          return null;
        },
      },
      {
        path: 'category',
        element: <Category />,
        loader: async () => {
          const session = await getSession(getPartnerName());

          if (!session || !verifyAuthorization(session, ['ROLE_ADMIN'])) {
            return redirect(getLocalLink('/dashboard/home'));
          }

          return null;
        },
      },
      {
        path: 'resources',
        element: <Ressources />,
        loader: async () => {
          const session = await getSession(getPartnerName());

          if (!session || !verifyAuthorization(session, ['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER'])) {
            return redirect(getLocalLink('/dashboard/home'));
          }

          return null;
        },
      },
    ],
  }, {
    path: 'invitation-adhesion-fournisseurs',
    children: [
      {
        path: '',
        element: <Landing />,
        loader: async () => {
          const partner = await getPartnerData(getPartnerName());

          if (!partner) {
            return redirect('/404');
          }

          const features = await getFeatures(partner.id);

          if (!features.includes(Features.landingLeclerc)) {
            return redirect('/404');
          }

          document.title = 'E.Leclerc, espace privé OpenClimat';

          return { partner };
        },
      },
      {
        path: 'politique-de-confidentialite',
        element: <PrivacyPolicy />,
        loader: async () => {
          const partner = await getPartnerData(getPartnerName());

          if (!partner) {
            return redirect('/404');
          }

          const features = await getFeatures(partner.id);

          if (!features.includes(Features.landingLeclerc)) {
            return redirect('/404');
          }

          document.title = 'Politique de confidentialité - E.Leclerc';

          return { partner };
        },
      },
    ],
  }, {
    path: 'login',
    element: <Login />,
    loader: async () => {
      const session = await getSession(getPartnerName());

      if (session) {
        return redirect(getLocalLink('/')); // redirect with following ?partner=xxx for dev env
      }

      const partner = await getPartnerData(getPartnerName());

      const features = await getFeatures(partner.id);

      setFavicon(`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_favicon}`);
      document.title = partner.name;

      return { partner, features };
    },
    children: [
      {
        path: '',
        element: <Form />,
      },
      {
        path: 'check',
        element: <Check />,
      },
    ],
  }, {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'resource-preview/:resource_id',
    element: <ResourcePreview />,
    loader: async () => {
      const session = await getSession(getPartnerName());
      const partner = await getPartnerData(getPartnerName());

      if (!session || !verifyAuthorization(session, ['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER'])) {
        return redirect(getLocalLink('/dashboard/home'));
      }

      setFavicon(`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_favicon}`);
      document.title = partner.name;

      return { partner };
    },
  },
]);

export default router;
