import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import tailwindConfig from '../../../tailwind.config';
import packageJson from '../../../../package.json';

import '../Dashboard.scss';

import powerIcon from '../../../assets/ui/power-icon.svg';
import doubleArrow from '../../../assets/ui/left-right-arrow.svg';
import homeIcon from '../../../assets/ui/sidebar/home-icon.svg';
import homeIconActive from '../../../assets/ui/sidebar/home-icon-active.svg';
import tagIcon from '../../../assets/ui/sidebar/tag-icon.svg';
import tagIconActive from '../../../assets/ui/sidebar/tag-icon-active.svg';
import filesIcon from '../../../assets/ui/sidebar/files-icon.svg';
import filesIconActive from '../../../assets/ui/sidebar/files-icon-active.svg';
import storeIcon from '../../../assets/ui/sidebar/store-logo.svg';
import storeIconActive from '../../../assets/ui/sidebar/store-logo-active.svg';
import exitIcon from '../../../assets/ui/sidebar/exit-icon.svg';
import chartBarActive from '../../../assets/ui/sidebar/chart-bar-active.svg';
import chartBar from '../../../assets/ui/sidebar/chart-bar.svg';
import fileCodeActive from '../../../assets/ui/sidebar/file-code-active.svg';
import fileCode from '../../../assets/ui/sidebar/file-code.svg';
import dropdownArrow from '../../../assets/ui/caret-down-s.svg';
import flagFr from '../../../assets/ui/flag/flag-fr.svg';
import flagUk from '../../../assets/ui/flag/flag-uk.svg';

import getLocalLink from '../../../utils/getLocalLink';
import getLogout from '../../../utils/getLogout';
import verifyAuthorization from '../../../utils/verifyAuthorization';
import Suppliers from './Suppliers/Suppliers';
import Tooltip from './Tooltip';

export default function Sidebar({ logo, session, partner, isSidebarOpen, setIsSidebarOpen }) {
  const [isOpen, setIsOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const isPrivatePartner = partner.private === 1;

  const handleButton = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const getIconStyle = (icon, activeIcon, isActive, iconName) => {
    let backgroundColor = `${tailwindConfig.theme.colors.greyThree}`; // Default color
    let maskImage = `url(${icon})`;

    if (isActive || hoveredIcon === iconName) {
      backgroundColor = `${tailwindConfig.theme.colors.greyOne}`; // Active color
      maskImage = `url(${activeIcon})`;
    }

    if (iconName === 'logout') {
      maskImage = `url(${icon})`;
    }

    return {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      backgroundColor,
      WebkitMaskImage: maskImage,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'center',
      transition: 'background-color 50ms',
    };
  };

  const languages = [
    {
      name: 'Français',
      shorthand: 'fr',
      flag: flagFr,
    },
    {
      name: 'English',
      shorthand: 'en',
      flag: flagUk,
    },
  ];

  const handleClickDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickLanguage = (language) => {
    i18n.changeLanguage(language);
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`tw-flex tw-min-h-full tw-border-y-0 tw-border-l-0 tw-border-r tw-border-solid tw-border-greyLight tw-bg-white tw-relative tw-transition-all tw-duration-300 tw-z-20 tw-shadow-[0_4px_4px_0px_rgba(215,215,214,0.25)] ${isSidebarOpen ? 'tw-w-[300px]' : 'tw-w-[80px]'}`}>
      <button type="button" className="tw-sticky tw-left-[300px] tw-top-[64px] tw-w-7 tw-h-7 tw-bg-white tw-z-30 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-greySeven -tw-mr-4" onClick={handleButton}>
        <img src={doubleArrow} alt="open sidebar button" />
      </button>
      <div className={`tw-w-full height-sidebar tw-flex tw-flex-col tw-justify-between tw-sticky tw-top-0 tw-overflow-y-auto ${isSidebarOpen ? '' : ' tw-overflow-x-visible'}`}>
        <div className=" tw-w-full tw-flex tw-flex-col tw-text-primaryNewBlue tw-text-sm tw-items-center">
          <div className="tw-py-10 tw-pr-[12px]">
            <img src={`${process.env.REACT_APP_FILES_DOMAIN}${logo}`} className="tw-transition-all tw-duration-300" alt="Logo" width={`${isSidebarOpen ? '145px' : '52px'}`} height="auto" />
          </div>
          <div className="tw-pl-2.5 tw-pr-[22px] tw-mb-7 tw-w-full tw-tex-greyThree tw-font-medium">
            {verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']) && (
              <NavLink
                to={getLocalLink('/dashboard/home')}
                className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
                onMouseEnter={() => handleMouseEnter('home')}
                onMouseLeave={handleMouseLeave}
              >
                {({ isActive }) => (
                  <>
                    {isSidebarOpen && (
                    <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                      <div className=" tw-flex tw-items-center tw-justify-center">
                        {isPrivatePartner && (
                        <span style={getIconStyle(homeIcon, homeIconActive, isActive, 'home')} className="tw-ml-3" />
                        )}
                      </div>
                      <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                        {t('PARTNER_DASHBOARD.SIDEBAR.HOME')}
                      </div>
                    </div>
                    )}
                    {!isSidebarOpen && (
                      <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.HOME')} />
                    )}
                    {!isSidebarOpen && (
                    <span style={getIconStyle(homeIcon, homeIconActive, isActive, 'home')} />
                    )}
                  </>
                )}
              </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']) && (
              <NavLink
                to={getLocalLink('/dashboard/suppliers')}
                className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
                onMouseEnter={() => handleMouseEnter('suppliers')}
                onMouseLeave={handleMouseLeave}
              >
                {({ isActive }) => (
                  <>
                    {isSidebarOpen && <Suppliers isActive={isActive} partner={partner} isSidebarOpen={isSidebarOpen} isPrivatePartner={isPrivatePartner} getIconStyle={getIconStyle} storeIcon={storeIcon} storeIconActive={storeIconActive} /> }
                    {!isSidebarOpen && <span style={getIconStyle(storeIcon, storeIconActive, isActive, 'suppliers')} />}
                    {!isSidebarOpen && (
                      <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.SUPPLIERS')} />
                    )}
                  </>
                )}
              </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']) && (
            <NavLink
              to={getLocalLink('/dashboard/statistics')}
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('statistics')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                    <div className=" tw-flex tw-items-center tw-justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(chartBar, chartBarActive, isActive, 'statistics')} className="tw-ml-3" />
                      )}
                    </div>
                    <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.OVERVIEW')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.OVERVIEW')} />
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(chartBar, chartBarActive, isActive, 'statistics')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <NavLink
              to={getLocalLink('/dashboard/category')}
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('category')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                    <div className=" tw-flex tw-items-center tw-justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(tagIcon, tagIconActive, isActive, 'category')} className="tw-ml-3" />
                      )}
                    </div>
                    <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.CATEGORIES')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.CATEGORIES')} />
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(tagIcon, tagIconActive, isActive, 'category')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']) && (
            <NavLink
              to={getLocalLink('/dashboard/resources')}
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('ressources')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                    <div className=" tw-flex tw-items-center tw-justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(filesIcon, filesIconActive, isActive, 'ressources')} className="tw-ml-3" />
                      )}
                    </div>
                    <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.RESSOURCES')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.RESSOURCES')} />
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(filesIcon, filesIconActive, isActive, 'ressources')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <div
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('api')}
              onMouseLeave={handleMouseLeave}
            >
              {isSidebarOpen && (
              <div className="tw-w-full tw-h-full tw-flex tw-gap-x-2 group-hover:tw-bg-greyEight tw-transition-all tw-delay-50">
                <div className=" tw-flex tw-items-center tw-justify-center">
                  {isPrivatePartner && (
                  <span style={getIconStyle(fileCode, fileCodeActive, false, 'api')} className="tw-ml-3" />
                  )}
                </div>
                <div className="tw-w-full tw-h-full tw-p-3 tw-text-greyThree hover:tw-text-greyOne tw-transition-all tw-delay-50">
                  {t('PARTNER_DASHBOARD.SIDEBAR.API')}
                </div>
              </div>
              )}
              {!isSidebarOpen && (
              <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.API')} />
              )}
              {!isSidebarOpen && (
              <span style={getIconStyle(fileCode, fileCodeActive, false, 'api')} />
              )}
            </div>
            )}
          </div>
        </div>
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-pl-2.5 tw-pr-[22px]">
          {!partner.private ? (
            <div className={`${isSidebarOpen ? 'tw-px-3' : ''} tw-py-7 tw-w-full tw-tex-greyThree tw-font-medium tw-border tw-border-solid tw-border-x-0 tw-border-b-0 tw-border-greySeven tw-text-sm`}>
              <NavLink
                to={getLocalLink('/')}
                className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center'}`}
                onMouseEnter={() => handleMouseEnter('public')}
                onMouseLeave={handleMouseLeave}
              >
                <>
                  <div className={`tw-w-full tw-h-full tw-text-greyThree hover:tw-text-greyOne tw-text-nowrap ${isSidebarOpen ? '' : 'tw-hidden'} tw-transition-all tw-delay-50`}>
                    {t('PARTNER_DASHBOARD.SIDEBAR.PUBLIC_SPACE_LINK')}
                  </div>
                  {!isSidebarOpen && <span style={getIconStyle(exitIcon, exitIcon, false, 'public')} />}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.PUBLIC_SPACE_LINK')} />
                  )}
                </>
              </NavLink>
            </div>
          ) : ('')}
          <button
            type="button"
            className={`tw-group tw-relative ${isSidebarOpen ? 'tw-p-3' : 'tw-py-3'} tw-bg-white tw-text-greyThree tw-font-medium tw-mb-7 tw-pt-7 tw-flex tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center'} tw-gap-x-2.5 tw-w-full tw-text-sm tw-border-0 hover:tw-text-greyOne tw-whitespace-nowrap tw-transition-all tw-delay-50`}
            onClick={getLogout}
            onMouseEnter={() => handleMouseEnter('logout')}
            onMouseLeave={handleMouseLeave}
          >
            <span style={getIconStyle(powerIcon, powerIcon, false, 'logout')} />
            <span className={`${isSidebarOpen ? '' : 'tw-hidden'}`}>
              {t('PARTNER_DASHBOARD.SIDEBAR.LOGOUT')}
            </span>
            {!isSidebarOpen && (
            <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.LOGOUT')} />
            )}
          </button>
          <div ref={dropdownRef} className="tw-relative tw-w-full tw-rounded-sm">
            <button
              type="button"
              className="tw-bg-greyEight tw-group tw-p-3 tw-w-full tw-flex tw-justify-between tw-cursor-pointer tw-text-greyThree border-0 hover:tw-text-greyOne"
              onClick={() => handleClickDropdown()}
            >
              <div className="tw-text-xs tw-flex tw-font-medium tw-gap-2.5 tw-whitespace-nowrap">
                <img
                  src={languages.find((item) => item.shorthand === i18next.language)?.flag || flagFr}
                  alt={`Flag ${languages.find((item) => item.shorthand === i18next.language)?.shorthand || flagFr}`}
                />
                {isSidebarOpen && t('PARTNER_DASHBOARD.SIDEBAR.CHANGE_LANGUAGE')}
              </div>
              {isSidebarOpen && (
                <img
                  src={dropdownArrow}
                  className={`${isOpen ? 'tw-rotate-180' : ''} tw-duration-500 tw-transition-all`}
                  alt="Dropwdown arrow"
                />
              )}
              {!isSidebarOpen && (
                <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.CHANGE_LANGUAGE')} customClass="tw-top-0" />
              )}
            </button>
            <div
              ref={contentRef}
              className={`tw-absolute tw-bottom-full tw-w-full tw-mb-2 tw-z-50 tw-transition-all tw-p-2 tw-duration-500 tw-overflow-hidden tw-rounded-[6px] tw-bg-white tw-border tw-border-solid ${isOpen ? 'tw-border-blueVeryLight tw-visible' : 'tw-border-[#00ffff00] tw-invisible'}`}
              style={{ height: isOpen ? `${contentRef.current.scrollHeight}px` : '0' }}
            >
              <div className="tw-flex tw-flex-col tw-text-xs">
                {languages.sort((a, b) => a.name.localeCompare(b.name)).map((language) => (
                  <button
                    type="button"
                    key={language.name}
                    className={`tw-p-2 tw-flex tw-w-full tw-gap-2 border-0 tw-rounded-md tw-text-greyThree tw-bg-white ${isOpen && isSidebarOpen ? 'hover:tw-bg-blueVeryLight' : ''} ${isSidebarOpen ? '' : 'tw-justify-center'}`}
                    onClick={() => handleClickLanguage(language.shorthand)}
                  >
                    <img src={language.flag} alt={`Flag ${language.name}`} />
                    {isSidebarOpen && language.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <p className="tw-text-xs tw-text-greyThree tw-mt-5">
            v{packageJson.version}
          </p>
        </div>
      </div>
    </div>
  );
}
