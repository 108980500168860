import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <footer className="tw-bg-[#000C23]">
      <div className="tw-container tw-mx-auto tw-px-[30px] md:tw-px-[60px] tw-py-5 tw-flex tw-gap-[60px] tw-no-underline tw-text-white">
        <a href="https://www.openclimat.com" className="hover:tw-text-greySeven">
          OpenClimat
        </a>
        <a href={`https://www.openclimat.com/${i18n.language}/contact`} className="hover:tw-text-greySeven">
          {t('LANDING_LECLERC.FOOTER.CONTACT')}
        </a>
      </div>
    </footer>
  );
}

export default Footer;
