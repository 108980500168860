import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { CKEditor } from 'ckeditor4-react';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';

function PartnerProfileCommentForm({ formContext }) {
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});
  const { action, modalTitle, title, profileId, partnerId, data } = formContext;
  const [editor, setEditor] = useState(null);

  const { close } = useModal();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/profiles/${profileId}/comment`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  useEffect(() => {
    if (editor) {
      editor.setData(formContext.data ? formContext.data.profileComment : null);
    }
    if (formContext.data) {
      reset(formContext.data);
    }
    register('profileComment', {
      required: false,
    });
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    if (action === 'create') {
      await api.post('', formData);
    } else if (action === 'edit') {
      await api.put(data.profileCommentId, formData);
    }
    if (api.response.ok) {
      data.getProfileComment();
      close();
    }
  };

  return (
    <TemplateFormModal
      modalId="partner-profile-comment-modal"
      formId="partner-profile-comment-form"
      title={modalTitle}
    >
      <form
        id="partner-profile-comment-form"
        onSubmit={handleSubmit(onSubmit)}
        className="needs-validation"
        noValidate
      >
        <div className="tw-p-4">
          <div className="mb-3">
            <label htmlFor="profileComment" className="form-label">
              {title}
            </label>
            <div className="has-validation">
              <div className={`${errors.profileComment ? 'is-invalid' : ''}`}>
                <CKEditor
                  initData={formContext.data ? formContext.data.profileComment : null}
                  onLoaded={onBeforeLoad}
                  onChange={(value) => setValue('profileComment', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
                  required={false}
                  config={{
                    toolbar: [
                      ['Bold', 'Italic', 'Underline', 'Strike'],
                      ['NumberedList', 'BulletedList'],
                    ],
                    versionCheck: false,
                  }}
                />
              </div>
              {errors.profileComment && (
              <div className="invalid-feedback">{errors.profileComment.message}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

export default PartnerProfileCommentForm;
