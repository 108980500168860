import React, { useRef } from 'react';

import blankSector from '../../../assets/ui/blank-sector.svg';

import handleSelectChange from './handleSelectChange';
import Description from './Description';
import categoryIcons from '../../../utils/categoryIcons';

function CategoryCardMobile({
  sector,
  index,
  sectors,
  setSelected,
  description,
  setDescription,
  setFilteredCategory,
  searchParams,
  setSearchParams,
  allLevels,
  setSectors,
  QUERY_NAME,
}) {
  const descriptionRef = useRef(null);

  return (
    <div className={`md:tw-hidden ${index > 0 ? 'tw-mt-6' : ''}`}>
      <h3 className="tw-text-sm tw-text-greyHeavy">
        {sector.level.length > 0 && sector.level[0].name ? (
          sector.level[0].name
        ) : (
          `Niveau ${sector.level[0].position}`
        )}
      </h3>
      <select
        name="sector-select"
        id="sector-select"
        value={description[index]?.id ? description[index]?.id : 0}
        style={{ backgroundImage: `url(${description[index]?.id && description[index]?.svg ? categoryIcons.find((icon) => icon.value === description[index]?.svg).icon : blankSector})` }}
        onChange={(e) => handleSelectChange(
          e,
          index,
          description,
          sector.level[0].id,
          allLevels,
          sectors,
          setSectors,
          setSelected,
          setDescription,
          setFilteredCategory,
          searchParams,
          setSearchParams,
          QUERY_NAME,
        )}
      >
        <option value={0} disabled>CHOISIR UN SECTEUR</option>
        {sector && sector.level[0].categories?.map((category) => (
          <option
            key={category.id}
            data-description={category.description}
            data-svg={category.icon}
            data-resources={JSON.stringify(category.resources)}
            value={category.id}
          >
            {category.name}
          </option>
        ))}
      </select>
      {sector.level.length > 0 && sector.level[0].categories?.sort((a, b) => a.name.localeCompare(b.name)).map((category, indexCategory) => (
        category.description || category.resources?.length > 0 ? (
          <Description key={indexCategory} sector={sector} category={category} index={index} description={description} descriptionRef={descriptionRef} />
        ) : ('')
      ))}
    </div>
  );
}

export default CategoryCardMobile;
