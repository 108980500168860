import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getLocalLink from '../../../../../utils/getLocalLink';
import getUserLocal from '../../../../../utils/getUserLocal';

function ProfileResults({ items, query }) {
  const { t } = useTranslation();
  const locale = getUserLocal();
  // Fonction pour mettre en évidence la partie du texte correspondat à la query
  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="tw-text-secondarySkyBlue">
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )))}
      </span>
    );
  };

  return (
    <div className="tw-absolute tw-left-0 tw-right-0 tw-top-11 tw-mt-1 tw-bg-white tw-border tw-border-solid tw-border-greyLight tw-rounded-md tw-shadow-customDropdown tw-max-h-60 tw-overflow-auto tw-flex tw-flex-col tw-z-10">
      <div className="tw-w-full tw-px-4 tw-py-3 tw-border-b tw-border-solid tw-border-blueVeryLight tw-border-x-0 tw-border-t-0 tw-text-greyOne tw-text-sm tw-font-medium">
        {locale === 'fr' && (
          `${t('PROFILE_FILTER_RESULTS_A')}${items.length < 2 ? '' : 's'} ${t('PROFILE_FILTER_RESULTS_B')} (${items.length})`
        )}
        {locale === 'en' && (
          `${t('PROFILE_FILTER_RESULTS_A')} ${t('PROFILE_FILTER_RESULTS_B')}${items.length < 2 ? '' : 's'} (${items.length})`
        )}
      </div>
      {items.map((item, index) => (
        <NavLink
          key={index}
          to={getLocalLink(`/dashboard/suppliers/${item.slug}`)}
          className="tw-px-4 tw-py-3 tw-text-sm tw-text-greyThree hover:tw-bg-blueVeryLight tw-no-underline tw-transition-all tw-delay-50"
        >
          {highlightText(item.name, query)}
        </NavLink>
      ))}
    </div>
  );
}

export default ProfileResults;
