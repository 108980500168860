import { sanitize } from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import toRemember from '../../../../../assets/ui/to-remember.svg';
import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash.svg';
import deleteQuestionAnswerItem from '../../../../../utils/deleteQuestionAnswerItem';
import useModal from '../../../../../hooks/useModal';

function HighlightList({ hasWriteAccess, answer, contentList, questionName, profileId, getAnswer, isPublished, setFormContext, partner }) {
  const { t } = useTranslation();

  const { open } = useModal();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/item`, {
    credentials: 'include',
  });

  return (
    <div className="col-12 col-md-4 col-xl-5 p-0 font-14 fw-light">
      <div className="p-4 h-100" style={{ background: '#FEF8E8', borderRadius: '0 18px 18px 0' }}>
        <h3 className="font-16 fw-600 align-middle mb-4 mt-2">
          <img src={toRemember} className="mb-1 me-1" alt="to remember" />
          {t('PROFILE_ACTIONS_TEXT_4')}
        </h3>
        <div className="d-flex flex-column align-items-center gap-2">
          {answer && (isPublished || hasWriteAccess) && contentList.length > 0 && contentList.map((item, index) => (
            item.type === 3 && (
            <div key={index} className="content-type-3 mb-1">
              <div className="content-list-item border-10 align-middle my-auto">
                <div className="fw-400 font-14">
                  <span
                    dangerouslySetInnerHTML={{ __html: sanitize(item.name) }}
                    className="fw-400 font-14"
                  />
                </div>
              </div>
              {hasWriteAccess && (
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="profile-modal-button me-1 border-0 bg-white"
                    onClick={() => {
                      setFormContext({
                        action: 'edit',
                        modalTitle: `${t('EDIT_A_KEY_POINT')} ${questionName}`,
                        profileId,
                        size: 'modal-lg',
                        data: {
                          question: 'Point clé',
                          media: item.name,
                          type: item.type,
                          content_list_id: item.id,
                          answer_id: answer.id,
                          getAnswer,
                        },
                      });
                      open('actions-item-modal');
                    }}
                  >
                    <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
                  </button>
                  <button type="button" className="profile-modal-button border-0 bg-white" onClick={() => deleteQuestionAnswerItem(item.id, api, profileId, getAnswer)}>
                    <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${trashBin})`, maskImage: `url(${trashBin})` }} />
                  </button>
                </div>
              )}
            </div>
            )
          ))}
          {(answer && hasWriteAccess && (contentList.filter((arr) => { if (arr.type === 3) { return true; } return false; }).length < 5)) ? (
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-sm white-color"
                onClick={() => {
                  setFormContext({
                    action: 'create',
                    modalTitle: `${t('ADD_A_KEY_POINT')} ${questionName}`,
                    profileId,
                    size: 'modal-lg',
                    data: {
                      question: 'Point clé',
                      media: null,
                      type: 3,
                      id: null,
                      answer_id: answer.id,
                      getAnswer,
                    },
                  });
                  open('actions-item-modal');
                }}
                style={{ backgroundColor: partner.primary_color }}
              >{t('ADD_A_KEY_POINT')}
              </button>
            </div>
          ) : ('')}
          {(answer && hasWriteAccess && (contentList.filter((arr) => { if (arr.type === 3) { return true; } return false; }).length >= 5)) ? (
            <div className="text-end">
              <button type="button" className="btn btn-sm disabled white-color" style={{ backgroundColor: partner.primary_color }}>
                {t('ADD_A_KEY_POINT')}
              </button>
              <div className="text-danger text-end">
                <small>{t('MAX_KEY_POINT')}</small>
              </div>
            </div>
          ) : ('')}
        </div>
      </div>
    </div>
  );
}

export default HighlightList;
