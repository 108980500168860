/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import pen from '../../../../../../assets/ui/pen.svg';
import circleQuestion from '../../../../../../assets/ui/circle-question.svg';
import circleQuestionScope3 from '../../../../../../assets/ui/circle-question-scope-3.svg';
import chevronDownPadded from '../../../../../../assets/ui/chevron-down-padded.svg';

import NoAnswer from '../../../NoAnswer';
import CarbonFootprintDistributionPie from './Chart';
import chartDataBuilder from '../../../../../../utils/getChartData';
import useModal from '../../../../../../hooks/useModal';

function QuestionTypeCarbonDistribution({ isPublished, hasWriteAccess, answer, noAnswerText, question, setFormContext, profile, fetchData }) {
  const [answeredModel, setAnsweredModel] = useState();
  const [parsedAnswer, setParsedAnswer] = useState();
  const [data, setData] = useState();
  const [dataFiltered, setDataFiltered] = useState();
  const [displayTable, setDisplayTable] = useState(false);
  const { t } = useTranslation();

  const { open } = useModal();

  useEffect(() => {
    if (answer && answer.distributions?.length > 0) {
      const placeholderAnsweredModel = question.carbonModel;

      placeholderAnsweredModel.forEach((element) => {
        answer.distributions.forEach((item) => {
          if (element.id === item.id) {
            element.percent = item.percent;
            element.id = item.answer_id;
          }
        });
      });

      setAnsweredModel(placeholderAnsweredModel);
      setParsedAnswer(answer.answer && JSON.parse(answer.answer));

      const [data, dataFiltered] = chartDataBuilder(placeholderAnsweredModel, t);
      setData(data);
      setDataFiltered(dataFiltered);
    }
  }, [answer]);

  const toggleTable = (e) => {
    e.preventDefault();
    setDisplayTable(!displayTable);
  };

  return (
    <>
      {(isPublished || hasWriteAccess) && (
        (answeredModel)
          && (
            <>
              <div className="row mt-4">
                <div className="col-xl-4">
                  <div className="carbon-footprint-chart-container">
                    <CarbonFootprintDistributionPie
                      totalCarbonFootprintValue={parsedAnswer ? parseFloat(parsedAnswer.emissionsScope12) + parseFloat(parsedAnswer.emissionsScope3) : 0}
                      data={dataFiltered}
                      carbonFootprintYear={parsedAnswer ? parsedAnswer.carbonMixBalanceYear : null}
                    />
                  </div>
                </div>
                <div className="col-xl-8 my-auto row ps-md-5 pe-md-0">
                  <div className="d-flex mt-4 d-md-none">
                    <div className="align-self-center tooltip-container-scope12 font-13 fw-400 d-flex">
                      <div>
                        Scope 1 & 2
                      </div>
                      <img className="ms-1" src={circleQuestion} alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                    </div>
                    <div className="ms-2 align-self-center tooltip-container-scope3 font-13 fw-400 d-flex">
                      <div>
                        Scope 3
                      </div>
                      <img className="ms-1" src={circleQuestionScope3} alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                    </div>
                  </div>
                  <div className="col-md-8 px-md-0 d-flex mt-4 mt-md-0">
                    <div className="align-self-center carbon-footprint-circle text-center text-white font-16 fw-500 d-flex" style={{ backgroundColor: '#00E0FF' }}>
                      <span className="align-self-center mx-auto">1</span>
                    </div>
                    <div className="align-self-center ms-2 font-14 fw-400">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10')}
                    </div>
                  </div>
                  <div className="col-4 d-md-flex d-none">
                    <div className="align-self-center vertical-separator-scope12" />
                    <div className="ms-1 ms-md-4 align-self-center tooltip-container-scope12 font-13 fw-400 d-flex flex-nowrap">
                      <div>
                        Scope 1 & 2
                      </div>
                      <img className="ms-1" src={circleQuestion} alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="carbon-footprint-tooltip" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                    </div>
                  </div>
                  <div className="col-md-8 pe-0 ps-md-0">
                    {(dataFiltered) ? (
                      dataFiltered.filter((item) => item.scope === 3).map((item, index) => (
                        <div key={index} className="d-flex mt-4">
                          <div className="align-self-center carbon-footprint-circle text-center text-white font-16 fw-500 d-flex" style={{ backgroundColor: item.color }}>
                            <span className="align-self-center mx-auto">{index + 2}</span>
                          </div>
                          <div className="align-self-center ms-2 font-14 fw-400">
                            {t(item.id)}
                          </div>
                        </div>
                      ))
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-4 d-md-flex d-none mt-4 ms-auto pe-0">
                    <div className="align-self-center vertical-separator-scope3" />
                    <div className="ms-1 ms-md-4 align-self-center tooltip-container-scope3 font-13 fw-400 d-flex">
                      <div>
                        Scope 3
                      </div>
                      <img className="ms-1" src={circleQuestionScope3} alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="carbon-footprint-tooltip" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                    </div>
                  </div>
                </div>
              </div>
              {answeredModel.length > 0 ? (
                <>
                  <button type="button" onClick={toggleTable} className="primary-color fw-500 font-14 mt-4 text-decoration-none bg-white border-0">
                    <img src={chevronDownPadded} alt="chevron down" className="anim-all" style={{ transform: `rotate(${displayTable ? '-180deg' : '0'})` }} /> {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_15')}
                  </button>
                  <div style={{ maxHeight: (displayTable) ? '999px' : '0px', overflow: 'hidden' }} className="anim-all-1s me-0 me-md-4">
                    {data.sort((a, b) => b.value - a.value).map((item, index) => (
                      item.value ? (
                        <div key={index} className="mt-3 row">
                          <div className="font-14 fw-400 col-md-6">
                            {t(item.id)}
                          </div>
                          <div className="d-flex col-md-6 mt-1 mt-md-0">
                            <div style={{ minHeight: '21px', maxHeight: '21px', width: `${item.value ? item.value : 0}%`, backgroundColor: item.color, borderRadius: '6px' }} />
                            <div className={`font-14 fw-600 ${item.value ? 'ms-2' : ''}`}>
                              {item.value ? `${parseFloat(item.value).toFixed(1)}%` : t('NOT_COMMUNICATED')}
                            </div>
                          </div>
                        </div>
                      ) : ('')
                    ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </>
          )
      )}
      {(!hasWriteAccess && !answeredModel) && (
        <div className="mt-2">
          <NoAnswer text={t(noAnswerText)} />
        </div>
      )}
      {hasWriteAccess && (
        <div className="bottom-0 d-flex mb-4 justify-content-end">
          <button
            type="button"
            className="profile-modal-button me-1 border-0 bg-white"
            onClick={() => {
              setFormContext({
                action: answer && answer.id ? 'edit' : 'create',
                modalTitle: `${t('EDIT_')}${question.info}`,
                profileId: profile.id,
                answerId: answer && answer.id,
                size: 'modal-lg',
                data: {
                  title: question.info,
                  carbonModel: answeredModel || question.carbonModel,
                  distributions: answer && answer.distributions,
                  question_id: question.id,
                  question_type: question.type,
                  emissionsScope12: answer && answer.answer && JSON.parse(answer.answer).emissionsScope12,
                  emissionsScope3: answer && answer.answer && JSON.parse(answer.answer).emissionsScope3,
                  carbonMixBalanceYear: answer && answer.answer && JSON.parse(answer.answer).carbonMixBalanceYear,
                  fetchData,
                },
              });
              open('content-questions-carbon-model-modal');
            }}
          >
            <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
          </button>
        </div>
      )}
    </>
  );
}

export default QuestionTypeCarbonDistribution;
