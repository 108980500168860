import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import getLocalLink from '../../../../utils/getLocalLink';

function GroupInformations({ profile }) {
  const { t } = useTranslation();
  const { partner } = useOutletContext();
  const [partnerProfilesList, setPartnerProfilesList] = useState([]);

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/simple-profiles-list`);

  useEffect(() => {
    const getProfilesList = async () => {
      try {
        const data = await get('');

        if (response.ok) {
          setPartnerProfilesList(data);
        }
        return null;
      } catch (error) {
        throw error;
      }
    };

    getProfilesList();
  }, [profile]);

  const isSubsidiaryCompany = profile.type === 1;

  if (!isSubsidiaryCompany) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-text-sm tw-font-poppins tw-max-w-[33%]">
      <span className="tw-text-greyFour tw-mb-1">{t('HEADER_LABEL_GROUP')}</span>
      {partnerProfilesList.includes(profile.profile_group_id) ? (
        <NavLink to={getLocalLink(`/dashboard/suppliers/${profile.profile_group_slug}`)} className="text-primaryNewBlue text-inherit underline">{profile.profile_group_name}</NavLink>
      ) : (
        <span className="text-greyTwo">{profile.profile_group_name}</span>
      )}
    </div>
  );
}

export default GroupInformations;
