/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { ResponsivePie } from '@nivo/pie';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import circleQuestionScope3 from '../../../../../../assets/ui/circle-question-scope-3.svg';
import loadBootstrapTooltips from '../../../../../../utils/loadBootstrapTooltips';

function CarbonFootprintDistributionPie({ totalCarbonFootprintValue, data, carbonFootprintYear }) {
  const { t } = useTranslation();

  const tooltip = (e) => (
    <div
      style={{
        display: 'flex',
        background: 'white',
        alignItems: 'center',
        borderRadius: '2px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
        padding: '5px 9px',
        flexWrap: 'nowrap',
      }}
    >
      <span
        style={{
          width: 12,
          minWidth: 12,
          height: 12,
          minHeight: 12,
          backgroundColor: e.datum.color,
          display: 'block',
          marginRight: 7,
        }}
      />
      <span style={{ whiteSpace: 'nowrap' }}>
        {e.datum.id} : <strong>{e.datum.value}%</strong>
      </span>
    </div>
  );

  function CenteredText({ centerX, centerY }) {
    return (
      <svg>
        <text
          x={centerX}
          y={centerY}
        >
          {totalCarbonFootprintValue === 0
            ? (
              <>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  fill="#171725"
                  style={{
                    fontWeight: '600',
                    fontSize: '22px',
                  }}
                >
                  -- kt
                </tspan>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  dy="1.2em"
                  fill="#747474"
                  style={{
                    color: '#747474',
                    fontWeight: '500',
                    fontSize: '12px',
                  }}
                >
                  CO2e {carbonFootprintYear || ''}
                </tspan>
              </>
            )
            : (
              <>
                <tspan
                  x={totalCarbonFootprintValue < 1 ? '47%' : (
                    (totalCarbonFootprintValue >= 1 && totalCarbonFootprintValue < 1000) ? '48%' : '48%'
                  )}
                  textAnchor="middle"
                  fill="#171725"
                  style={{
                    fontWeight: '600',
                    fontSize: '22px',
                  }}
                >
                  {totalCarbonFootprintValue ? `${totalCarbonFootprintValue >= 1000 ? `${(totalCarbonFootprintValue / 1000).toFixed(2).replace('.', ',')} Mt` : `${totalCarbonFootprintValue} kt`}` : ''}
                </tspan>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  dy="1.6em"
                  fill="#747474"
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                  }}
                >{carbonFootprintYear ? `${t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_18')} ${carbonFootprintYear}` : totalCarbonFootprintValue ? 'CO2e' : ''}
                </tspan>
              </>
            )}
        </text>
        <image
          x={centerX + (totalCarbonFootprintValue < 1 ? 32 : (
            totalCarbonFootprintValue >= 1 && totalCarbonFootprintValue < 10 ? 20 : (
              totalCarbonFootprintValue >= 10 && totalCarbonFootprintValue < 100 ? 25 : (
                totalCarbonFootprintValue >= 100 && totalCarbonFootprintValue < 1000) ? 30 : (
                  totalCarbonFootprintValue >= 1000 && totalCarbonFootprintValue < 10000 ? 35
                    : totalCarbonFootprintValue < 100000 ? 42 : 48
                )
            )
          ))}
          y={centerY - 14}
          width="15"
          height="15"
          xlinkHref={circleQuestionScope3}
          alt="circle question scope 3"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-custom-class="carbon-footprint-tooltip"
          title={totalCarbonFootprintValue >= 1000 ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_20') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_21')}
        />
      </svg>
    );
  }

  useEffect(() => {
    loadBootstrapTooltips();
  }, [data]);

  return (
    <ResponsivePie
      data={data}
      layers={['arcs', 'arcLabels', CenteredText]}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors={(data) => data.data.color}
      innerRadius={0.6}
      padAngle={1}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLabel={(d) => `${d.value}%`}
      enableArcLinkLabels={false}
      enableArcLabels={
        data[0]
          ? !(data[0].id === 'Données non disponibles' || data[0].id === 'Data not available')
          : false
      }
      isInteractive={
        data[0]
          ? !(data[0].id === 'Données non disponibles' || data[0].id === 'Data not available')
          : false
      }
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={data.length <= 1 ? '#333333' : '#ffffff'}
      theme={{
        labels: {
          text: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
      }}
      tooltip={(e) => tooltip(e)}
    />
  );
}

export default CarbonFootprintDistributionPie;
