import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function ClimatActionHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'climate_action';

  useEffect(() => {
    if (searchParams.get('climate_action')) {
      setSort(searchParams.get('climate_action'));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div>
          {t('CLIMATE_ACTION')}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
          <br />
          <span id="climate-action-subtitle" className="font-10 text-muted">
            {t('CURRENT_PARIS_AGREEMENT_ALIGNMENT')}
          </span>
        </div>
      </div>
    </th>
  );
}

function ClimatActionBody({ profile, cellStyle }) {
  const { t } = useTranslation();
  const styleScore = ['score-one', 'score-two', 'score-three', 'score-four', 'score-five', 'score-six'];

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySix tw-border" style={cellStyle}>
      <div className="d-flex">
        <div className={`elipse align-self-center ${styleScore[profile.score - 1]}`} />
        <div className="ps-1 climate-action-text">
          {t(profile.score_label)}
        </div>
      </div>
    </td>
  );
}

export { ClimatActionHead, ClimatActionBody };
