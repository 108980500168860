import React from 'react';

import clipboard from '../../../assets/ui/clipboard-question.svg';

function NoAnswer({ text }) {
  return (
    <div className="grey-LLLL-bg border-8 p-3 d-flex">
      <div className="align-self-center">
        <img src={clipboard} alt="no content" />
      </div>
      <div className="font-14 align-self-center grey-L-color" style={{ marginLeft: '10px' }}>
        {text}
      </div>
    </div>
  );
}

export default NoAnswer;
