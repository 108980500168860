const deletePartnerSectorResource = async (id, api, getSectors) => {
  try {
    const response = await api.delete(id);

    if (api.response.ok) {
      getSectors();
      return response;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default deletePartnerSectorResource;
