import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';

function SectorResourcesForm({ formContext }) {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const { action, modalTitle, sectorId, partnerId, userId, data } = formContext;

  const { t } = useTranslation();

  const { close } = useModal();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/sectors`, {
    credentials: 'include',
  });

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    const finalData = formData;
    finalData.user_id = userId;
    if (action === 'create') {
      await api.post(`${sectorId}/resource`, finalData);
    } else if (action === 'edit') {
      await api.put(`${sectorId}/resource/${data.resourceId}`, finalData);
    }
    if (api.response.ok) {
      data.getSectors();
      close();
    }
  };

  return (
    <TemplateFormModal
      modalId="sector-resources-modal"
      formId="sector-resources-form"
      title={modalTitle}
    >
      <form
        id="sector-resources-form"
        onSubmit={handleSubmit(onSubmit)}
        className="needs-validation"
        noValidate
      >
        <div className="tw-p-4">
          <div className="mb-3">
            <div className="mb-3">
              <label htmlFor="urlTitle" className="form-label">
                {t('TITLE_LINK')}
              </label>
              <div className="has-validation">
                <input
                  type="text"
                  id="urlTitle"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.name : null}
                  {...register('name', {
                    required: t('TITLE_LINK_REQUIRED'),
                  })}
                />
                {errors.name && (
                <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="url" className="form-label">
                {t('LINK')}
              </label>
              <div className="has-validation">
                <input
                  type="url"
                  id="url"
                  className={`form-control ${errors.url ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.url : null}
                  {...register('url', {
                    required: 'Le lien est requis',
                    pattern: {
                      value: /^(ftp|http|https):\/\/[^ "]+$/,
                      message: t('LINK_INVALID'),
                    },
                  })}
                />
                {errors.url && (
                <div className="invalid-feedback">{errors.url.message}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

export default SectorResourcesForm;
