import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig';

import questionMarkCircled from '../../../../assets/ui/question-mark-circled.svg';

import tailwindConfig from '../../../../tailwind.config';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import getClimateActions from '../../../../utils/getClimateActions';
import getOpenClimatComment from '../../../../utils/getOpenClimatComment';
import NoCimateActions from './NoClimateActions';
import Details from './Details';
import DetailsMobile from './DetailsMobile';
import getUserLocal from '../../../../utils/getUserLocal';
import getCompleteColorByScore from '../../../../utils/getCompleteColorByScore';

function ClimateActions({ profile }) {
  const [openClimatComment, setOpenCimatComment] = useState();
  const [scope12, setScope12] = useState();
  const [scope3, setScope3] = useState();
  const [scope12Color, setScope12Color] = useState();
  const [scope3Color, setScope3Color] = useState();
  const [note, setNote] = useState(null);

  const fullConfig = resolveConfig(tailwindConfig);

  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${fullConfig.theme.screens.lg})`,
  });

  useEffect(() => {
    getClimateActions(profile.id).then((data) => {
      if (data.parent_company && data.parent_company.direct_score_data) {
        setScope12({
          percentDelta: data.parent_company.direct_score_data.percent_delta,
          reduceType: data.parent_company.direct_score_data.reduce_type,
          startYear: data.parent_company.direct_score_data.start_year,
          endYear: data.parent_company.direct_score_data.end_year,
          degreeScore: data.parent_company.direct_score_data.degree_score,
          dataUsable: data.parent_company.direct_data_usable,
          dataNotComparable: data.parent_company.direct_data_not_comparable,
        });
      }
      if (data.parent_company && data.parent_company.complete_score_data) {
        setScope3({
          percentDelta: data.parent_company.complete_score_data.percent_delta,
          reduceType: data.parent_company.complete_score_data.reduce_type,
          startYear: data.parent_company.complete_score_data.start_year,
          endYear: data.parent_company.complete_score_data.end_year,
          degreeScore: data.parent_company.complete_score_data.degree_score,
          dataUsable: data.parent_company.complete_data_usable,
          dataNotComparable: data.parent_company.complete_data_not_comparable,
        });
      }
      if (data.parent_company) {
        setScope12Color(data.parent_company.direct_data_usable || data.parent_company.direct_data_not_comparable ? fullConfig.theme.colors[getCompleteColorByScore(1)] : fullConfig.theme.colors[getCompleteColorByScore(data.parent_company.direct_score_data.degree_score)]);
        setScope3Color(data.parent_company.complete_data_usable || data.parent_company.complete_data_not_comparable ? fullConfig.theme.colors[getCompleteColorByScore(1)] : fullConfig.theme.colors[getCompleteColorByScore(data.parent_company.complete_score_data.degree_score)]);
        if (data.parent_company.note) {
          if (getUserLocal() === 'en') {
            setNote(data.parent_company.note_en);
          } else {
            setNote(data.parent_company.note);
          }
        }
      }
      if (profile.type !== 2) {
        const comment = getOpenClimatComment(data.score);
        setOpenCimatComment(comment);
      }
    });
  }, [profile]);

  const unitText = {
    1: 'IN_ABSOLUTE_VALUE',
    2: 'PER_VOLUME',
    3: 'PER_EUROS',
  };

  const getClimateAlignementInfos = (level) => {
    const roundedLevel = Math.floor(level);
    switch (roundedLevel) {
      case 6:
        return { text: '1.5°C', textColor: 'noteTresForte', bgColor: 'noteTresForte', border: true };
      case 5:
        return { text: 'WB 2°C', textColor: 'noteForte', bgColor: 'noteForte', border: true };
      case 4:
        return { text: '2°C', textColor: 'noteIntermediaire', bgColor: 'noteIntermediaire', border: true };
      case 3:
        return { text: t('CLIMATE_ACTIONS.NOT_SUFFICIENT'), textColor: 'noteEncoreInsufissante', bgColor: 'noteEncoreInsufissante', border: false };
      case 2:
        return { text: t('CLIMATE_ACTIONS.RISING'), textColor: 'noteTresInsuffisante', bgColor: 'noteTresInsuffisante', border: false };
      case 1:
        return { text: t('CLIMATE_ACTIONS.NOT_MEASURED'), textColor: 'notePasDengagement', bgColor: 'notePasDengagement', border: false };
      default:
        return { text: t('CLIMATE_ACTIONS.NOT_MEASURED'), textColor: 'notePasDengagement', bgColor: 'notePasDengagement', border: false };
    }
  };

  return (
    <div className="tw-px-6 lg:tw-px-7 tw-py-6 tw-mb-4 tw-rounded tw-bg-white tw-border tw-border-solid tw-border-greySix">
      <h3 className="tw-text-greyOne tw-text-base">
        {t('CLIMATE_ACTIONS.DECARBONISATION_RESULTS')}
      </h3>
      <div className="tw-mt-4 tw-p-4 tw-font-medium tw-text-sm tw-text-primaryNewBlue tw-border tw-border-solid tw-border-greySix tw-bg-backgroundCleanBlue tw-rounded-sm">
        {t(openClimatComment)}
      </div>
      {isDesktop ? (
        <>
          <div className="tw-mt-5 tw-grid tw-grid-cols-4 tw-text-greyFour tw-text-xs tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix tw-py-2.5">
            <div className="tw-col-span-1 tw-col-start-2 tw-text-center">
              {t('CLIMATE_ACTIONS.ACTUAL_REDUCTION')}
            </div>
            <div className="tw-col-span-1 tw-col-start-3 tw-text-center">
              {t('CLIMATE_ACTIONS.REDUCTION_SPEED')}
            </div>
            <div className="tw-col-span-1 tw-col-start-4 tw-text-center">
              {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
            </div>
          </div>
          <div className="tw-py-4 tw-grid tw-grid-cols-4 tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
            <div className="tw-text-primaryNewBlue tw-col-span-1 tw-flex tw-items-center">
              <div className="tw-flex">
                {scope12Color && (
                  <div key={scope12Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-center tw-bg-${getCompleteColorByScore(scope12?.degreeScore)} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope12Color, 0.1)}`, backgroundClip: 'padding-box' }} />
                )}
                <div className="tw-ml-2.5 tw-self-center tw-font-moskauGrotesk tw-font-semibold tw-text-sm tw-mt-0.5">
                  {t('CLIMATE_ACTIONS.RESULTS')} Scope 1&2
                </div>
              </div>
            </div>
            {(scope12?.dataUsable || scope12?.dataNotComparable) ? (
              <NoCimateActions dataUsable={scope12?.dataUsable} dataNotComparable={scope12?.dataNotComparable} />
            ) : (
              <Details
                scope={scope12}
                scopeColor={scope12Color}
                unitText={unitText}
                getColorByScore={getCompleteColorByScore}
                getClimateAlignementInfos={getClimateAlignementInfos}
                getHexColorWithOpacity={getHexColorWithOpacity}
              />
            )}
          </div>
          <div className="tw-py-4 tw-grid tw-grid-cols-4 tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
            <div className="tw-text-primaryNewBlue tw-col-span-1 tw-flex tw-items-center">
              <div className="tw-flex">
                {scope3Color && (
                  <div key={scope3Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 ${note ? 'tw-self-start' : 'tw-self-center'} tw-bg-${getCompleteColorByScore(scope3?.degreeScore)} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope3Color, 0.1)}`, backgroundClip: 'padding-box' }} />
                )}
                <div className={`tw-ml-2.5 tw-self-center tw-font-moskauGrotesk tw-font-semibold tw-text-sm tw-align-center ${note ? '' : 'tw-mt-0.5'}`}>
                  <div>
                    {t('CLIMATE_ACTIONS.RESULTS')} Scope 3
                  </div>
                  {note && (
                    <div className="tw-text-[10px] tw-text-greyFour tw-font-normal tw-inline-block tw-relative">
                      <span className="tw-mr-1">
                        {t('CLIMATE_ACTIONS.PERFORMANCE_KPI_TRACKED')}
                      </span>
                      <div className="tw-inline-block tw-align-middle tw-group">
                        <img src={questionMarkCircled} alt="Question Mark" />
                        <div className="tw-absolute tw-top-full tw-invisible group-hover:tw-visible tw-max-w-44 tw-min-w-44 tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-my-10 tw-ml-5">
                          {note}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(scope3?.dataUsable || scope3?.dataNotComparable) ? (
              <NoCimateActions dataUsable={scope3?.dataUsable} dataNotComparable={scope3?.dataNotComparable} />
            ) : (
              <Details
                scope={scope3}
                scopeColor={scope3Color}
                unitText={unitText}
                getColorByScore={getCompleteColorByScore}
                getClimateAlignementInfos={getClimateAlignementInfos}
                getHexColorWithOpacity={getHexColorWithOpacity}
              />
            )}
          </div>
          <div className="tw-mt-6">
            <a href={`https://www.openclimat.com/${getUserLocal()}/methodology`} className="tw-text-sm tw-text-greyFour tw-underline">
              {t('CLIMATE_ACTIONS.SBTI_METHODOLOGY')}
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="tw-py-7 tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
            <div className="tw-flex">
              {scope12Color && (
                <div key={scope12Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-center tw-bg-${getCompleteColorByScore(scope12?.degreeScore)} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope12Color, 0.1)}`, backgroundClip: 'padding-box' }} />
              )}
              <div className="tw-ml-2.5 tw-self-center tw-font-moskauGrotesk tw-font-semibold tw-text-sm">
                {t('CLIMATE_ACTIONS.RESULTS')} Scope 1&2
              </div>
            </div>
            <DetailsMobile
              scope={scope12}
              unitText={unitText}
              scopeColor={scope12Color}
              dataUsable={scope12?.dataUsable}
              dataNotComparable={scope12?.dataNotComparable}
              getColorByScore={getCompleteColorByScore}
              getClimateAlignementInfos={getClimateAlignementInfos}
              getHexColorWithOpacity={getHexColorWithOpacity}
            />
          </div>
          <div className="tw-py-7 tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
            <div className="tw-flex">
              {scope3Color && (
                <div key={scope3Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-start tw-bg-${getCompleteColorByScore(scope3?.degreeScore)} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope3Color, 0.1)}`, backgroundClip: 'padding-box' }} />
              )}
              <div className="tw-ml-2.5 tw-self-center tw-font-moskauGrotesk tw-font-semibold tw-text-sm">
                <div>
                  {t('CLIMATE_ACTIONS.RESULTS')} Scope 3
                </div>
                {note && (
                  <div className="tw-text-[10px] tw-text-greyFour tw-font-normal tw-inline-block tw-relative">
                    <span className="tw-mr-1">
                      {t('CLIMATE_ACTIONS.PERFORMANCE_KPI_TRACKED')}
                    </span>
                    <div className="tw-inline-block tw-align-middle tw-group">
                      <img src={questionMarkCircled} alt="Question Mark" />
                      <div className="tw-absolute tw-top-full -tw-right-8 sm:-tw-right-[115%] tw-invisible group-hover:tw-visible md:tw-top-full tw-min-w-44 tw-max-w-44 tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-mb-10 sm:-tw-my-10 sm:-tw-ml-10">
                        {note}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <DetailsMobile
              scope={scope3}
              unitText={unitText}
              scopeColor={scope3Color}
              dataUsable={scope3?.dataUsable}
              dataNotComparable={scope3?.dataNotComparable}
              getColorByScore={getCompleteColorByScore}
              getClimateAlignementInfos={getClimateAlignementInfos}
              getHexColorWithOpacity={getHexColorWithOpacity}
            />
          </div>
          <div className="tw-mt-6">
            <a href={`https://www.openclimat.com/${getUserLocal()}/methodology`} className="tw-text-sm tw-text-greyFour tw-underline">
              {t('CLIMATE_ACTIONS.SBTI_METHODOLOGY')}
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default ClimateActions;
