import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import Header from '../../Header/Header';
import getQuestions from '../../../../../utils/getQuestions';
import getUserLocal from '../../../../../utils/getUserLocal';
import TableOfContents from './TableOfContents';
import ContentQuestionForm from '../../Modal/Forms/ContentQuestionForm';
import PublishingSwitch from '../PublishingSwitch';
import ContentQuestionsTypeCarbonModelForm from '../../Modal/Forms/ContentQuestionsTypeCarbonModelForm';
import Category from '../Template/Category';
import getVisibility from '../../../../../utils/getVisibility';

function Approach({ view }) {
  const [categories, setCategories] = useState([]);
  const [optionalCategories, setOptionalCategories] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [advancedSummary, setAdvancedSummary] = useState([]);
  const [isPublished, setIsPublished] = useState();
  const [showOptional, setShowOptional] = useState(false);
  const [formContext, setFormContext] = useState({});

  const { profile, partner, hasWriteAccess } = useOutletContext();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });

  const { t } = useTranslation();

  const TYPE_QUESTION = 1;
  const TYPE_ADVANCED = 3;

  const getAnsweredQuestionsCount = (categories) => {
    let answeredQuestions = 0;
    categories.forEach((category) => {
      category.individualQuestions.forEach((question) => {
        if (question.answer) {
          answeredQuestions += 1;
        }
      });
    });
    return answeredQuestions;
  };

  const fetchData = async () => {
    let answeredNormal = 0;
    let answeredAdvanced = 0;
    let totalQuestions = 0;

    try {
      const normalQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), TYPE_QUESTION, profile.typology_id, partner.id, hasWriteAccess);

      if (api.response.ok) {
        setCategories(normalQuestionsResponse.categories);
        setIsPublished(normalQuestionsResponse.is_published_tab);
        setSummary(normalQuestionsResponse.table_contents);
        answeredNormal = getAnsweredQuestionsCount(normalQuestionsResponse.table_contents);
        normalQuestionsResponse.categories.forEach((category) => {
          totalQuestions += category.category[0].questions.length;
        });
      }
    } catch (error) {
      throw new Error(error);
    }

    try {
      const advancedQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), TYPE_ADVANCED, profile.typology_id, partner.id);

      if (api.response.ok) {
        setOptionalCategories(advancedQuestionsResponse.categories);
        setAdvancedSummary(advancedQuestionsResponse.table_contents);
        answeredAdvanced = getAnsweredQuestionsCount(advancedQuestionsResponse.table_contents);
        advancedQuestionsResponse.categories.forEach((category) => {
          totalQuestions += category.category[0].questions.length;
        });
      }
    } catch (error) {
      throw new Error(error);
    }

    setNumberOfAnsweredQuestions(answeredNormal + answeredAdvanced);
    setNumberOfQuestions(totalQuestions);

    const progressionBar = (totalQuestions > 0) ? ((answeredNormal + answeredAdvanced) * 100) / totalQuestions : 0;
    setProgressionBar(parseInt(progressionBar, 10));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="profile-page">
      <Header view={view} />
      {hasWriteAccess ? (
        <PublishingSwitch
          isViewPublishedInThisLocale={isPublished}
          view={view}
          publishing={profile.publishing}
          locale={getUserLocal()}
          profilId={profile.id}
        />
      ) : ('')}
      <div className="container-fluid container-lg mt-3 pb-5">
        <div className="row">
          <div className="col-12 col-md-4 px-md-3">
            <TableOfContents
              hasWriteAccess={hasWriteAccess}
              progressionBar={progressionBar}
              numberOfQuestions={numberOfQuestions}
              numberOfAnsweredQuestions={numberOfAnsweredQuestions}
              summary={summary}
              advancedSummary={advancedSummary}
              isPublished={isPublished}
              showOptional={showOptional}
            />
          </div>
          <div className="col-12 col-md-8 px-md-3">
            <h2 className="fw-600 font-24 mb-4">
              {t('TABLE_OF_CONTENTS_TEXT_2')}
            </h2>
            {categories && categories.map((category, index) => {
              const show = getVisibility(category, hasWriteAccess, isPublished);

              return (
                show
                && (
                <Category
                  key={index}
                  category={category.category[0]}
                  profile={profile}
                  isPublished={isPublished}
                  partner={partner}
                  view={view}
                  hasWriteAccess={hasWriteAccess}
                  setFormContext={setFormContext}
                  fetchData={fetchData}
                />
                )
              );
            })}
            {optionalCategories.length > 0 && showOptional === true && (
            <h3 className="mb-4 mt-5 font-24 fw-6tw00">
              {t('TO_GO_FURTHER')}
            </h3>
            )}
            {optionalCategories && optionalCategories.length > 0 && optionalCategories.map((category, index) => {
              const show = getVisibility(category, hasWriteAccess, isPublished);

              return (
                show
              && (
              <Category
                key={index}
                category={category.category[0]}
                profile={profile}
                isPublished={isPublished}
                partner={partner}
                view={view}
                hasWriteAccess={hasWriteAccess}
                setFormContext={setFormContext}
                setShowOptional={setShowOptional}
                fetchData={fetchData}
              />
              )
              );
            })}
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <ContentQuestionForm formContext={formContext} />
            <ContentQuestionsTypeCarbonModelForm formContext={formContext} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default Approach;
