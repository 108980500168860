import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionTypeCarbonDistribution from '../Template/QuestionTypeCarbonDistribution/QuestionTypeCarbonDistribution';
import QuestiontTypeQuantitative from '../Template/QuestionTypeQuantitative/QuestiontTypeQuantitative';
import QuestionTypeQualitative from '../Template/QuestionTypeQualitative/QuestionTypeQualitative';

function Question({ index, question, setFormContext, profile, partner, hasWriteAccess, setChecked, fetchData }) {
  const [answer, setAnswer] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    if (question.question.length > 0 && question.question[0].answer) {
      setAnswer(question.question[0].answer);

      if (question.question[0].answer.is_partner_published) {
        setChecked(question.question[0].answer.is_partner_published);
      }
    }
  }, [fetchData]);

  return (
    (((answer && answer.answer) || question.question[0].is_required || hasWriteAccess) ? (
      <div className={`${index === 0 ? 'mt-3' : 'mt-4'}`}>
        <div id={`${question.question[0].info}`} className="d-flex justify-content-between answer-block">
          <div className="font-14 fw-500 align-self-center">
            {question.question[0].info}
          </div>
        </div>
        <div className="mt-2">
          {question.question[0].type === 3 ? (
            <QuestionTypeCarbonDistribution
              question={question.question[0]}
              isPublished={answer && answer.is_partner_published}
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              noAnswerText={t('PRIVATE_ANSWERS.QUESTIONS.NO_ANSWER')}
              setFormContext={setFormContext}
              fetchData={fetchData}
              profile={profile}
            />
          ) : ('')}
          {question.question[0].type === 2 ? (
            <QuestiontTypeQuantitative
              question={question.question[0]}
              isPublished={answer && answer.is_partner_published}
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              noAnswerText={t('PRIVATE_ANSWERS.QUESTIONS.NO_ANSWER')}
              setFormContext={setFormContext}
              fetchData={fetchData}
              profile={profile}
            />
          ) : ('')}
          {question.question[0].type === null || question.question[0].type === 1 ? (
            <QuestionTypeQualitative
              question={question.question[0]}
              isPublished={answer && answer.is_partner_published}
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              noAnswerText={t('PRIVATE_ANSWERS.QUESTIONS.NO_ANSWER')}
              setFormContext={setFormContext}
              partner={partner}
              fetchData={fetchData}
              profile={profile}
            />
          ) : ('')}
        </div>
      </div>
    ) : (''))
  );
}

export default Question;
