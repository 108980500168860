import React from 'react';

function Card({ card }) {
  return (
    <div className="tw-bg-white tw-w-[300px] tw-h-[300px] tw-max-w-[300px] tw-max-h-[300px] tw-px-[22px] tw-py-[50px] tw-border tw-border-solid tw-border-greySeven tw-rounded-[20px]">
      <div className="tw-flex tw-justify-center">
        <img src={card.icon} alt={card.iconName} />
      </div>
      <div className="tw-text-lg tw-font-medium tw-mt-6 text-center">
        <span className="tw-text-leclercOrange">
          {card.textOne}
        </span>
        <span className="tw-text-primaryNewBlue">
          {card.textTwo}
        </span>
      </div>
    </div>
  );
}

export default Card;
