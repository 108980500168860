/**
 * Formats the target SBT value into a string
 *
 * @param {number} target - The target SBT value to format
 * @returns {string} The formatted target SBT
 */
const formatTargetSBT = (target) => {
  let text = 'n.a.';

  if (target === 1) {
    text = 'ONE_POINT_FIVE_DEGREES';
  } else if (target === 2) {
    text = 'WELL_BELOW_TWO_DEGREES';
  } else if (target === 3) {
    text = 'TWO_DEGREES';
  } else if (target === 4) {
    text = 'VALIDATION_ONGOING';
  } else if (target === 5) {
    text = 'NO_COMMITMENT';
  }

  return text;
};

export default formatTargetSBT;
