import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import handleResponse from '../../../../utils/handleResponse';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function PartnerTurnoverHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_turnover';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div>
          {t('TURNOVER_WITH')} {partner.name}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
        </div>
      </div>
    </th>
  );
}

function PartnerTurnoverBody({ profile, cellStyle, categoryFilter, partnerId, loading }) {
  const [turnoverAmount, setTurnoverAmount] = useState();

  const { t, i18n } = useTranslation();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getTurnoverAmount = async () => {
    try {
      let turnoverAmount;
      const responseData = await get(`/profiles/${profile.id}/categories-carbon-weight${categoryFilter ? `?filter_id=${categoryFilter}` : ''}`);

      if (response.ok) {
        if (responseData) {
          turnoverAmount = responseData.turnoverAmount ? responseData.turnoverAmount : null;
        }
      }

      return turnoverAmount;
    } catch (error) {
      handleResponse(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchTurnoverData = async () => {
      const turnoverData = await getTurnoverAmount();
      setTurnoverAmount(turnoverData);
    };

    if (!loading) {
      fetchTurnoverData();
    }
  }, [profile, loading]);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySix tw-border" style={cellStyle}>
      <div className="tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-flex tw-justify-center">
        <div>
          {turnoverAmount ? `${(turnoverAmount / 1000000).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${t('MILLION_€')}` : (
            <img src={minus} alt="minus" />
          )}
        </div>
      </div>
    </td>
  );
}

export { PartnerTurnoverHead, PartnerTurnoverBody };
