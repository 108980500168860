import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import isProductionEnvironement from '../../../utils/isProductionEnvironement';

import pdfLogo from '../../../assets/ui/pdf-logo.svg';
import threeDots from '../../../assets/ui/three-dots.svg';

function ResourceCard({ resource, onDelete, onEdit, openMenuId, setOpenMenuId }) {
  const [searchParams] = useSearchParams();
  const cardRef = useRef(null);
  const menuRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !cardRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenMenuId]);

  const handleToggleMenu = (e) => {
    e.stopPropagation();
    setOpenMenuId(openMenuId === resource.id ? null : resource.id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(resource.id, resource.attachment_id);
    setOpenMenuId(null);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(resource);
    setOpenMenuId(null);
  };

  const handleCardInteraction = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && (e.key === 'Enter' || e.key === ' '))) {
      e.preventDefault();
      window.open(`/resource-preview/${resource.id}${!isProductionEnvironement() ? `?partner=${searchParams.get('partner')}` : ''}`, '_blank', 'rel=noopener noreferrer');
    }
  };

  return (
    <div
      ref={cardRef}
      className="tw-relative tw-w-40 tw-min-h-[150px] tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-between tw-rounded tw-border tw-border-solid tw-border-blueBorder tw-p-4 tw-cursor-pointer"
      onClick={handleCardInteraction}
      onKeyDown={handleCardInteraction}
      role="button"
      tabIndex="0"
      aria-label={`Open PDF: ${resource.file_name}`}
    >
      <button
        type="button"
        className="tw-absolute tw-top-1 tw-right-0 tw-cursor-pointer tw-p-2 tw-flex tw-items-center tw-justify-center tw-border-none tw-bg-white"
        onClick={handleToggleMenu}
        aria-label="Toggle menu"
      >
        <img src={threeDots} alt="" aria-hidden="true" />
      </button>
      {openMenuId === resource.id && (
      <div ref={menuRef} className="tw-absolute -tw-right-20 -tw-top-2 tw-z-50">
        <div className=" tw-flex tw-flex-col tw-px-[3px] tw-py-2 tw-rounded tw-shadow-resourcePdfShadow tw-bg-white ">
          <button
            type="button"
            className="tw-text-greyThree tw-text-sm tw-font-medium tw-font-moskauGrotesk tw-bg-white hover:tw-bg-greyEight tw-border-none tw-text-left tw-px-[7px] tw-py-[2px] tw-rounded-sm tw-transition-transform tw-duration-150 tw-z-10"
            onClick={handleEdit}
          >
            {t('PARTNER_DASHBOARD.RESOURCES.EDIT_BUTTON')}
          </button>
          <button
            type="button"
            className="tw-text-greyThree tw-text-sm tw-font-medium tw-font-moskauGrotesk tw-mt-2 tw-bg-white hover:tw-bg-greyEight tw-border-none tw-text-left tw-px-[7px] tw-py-[2px] tw-rounded-sm tw-transition-transform tw-duration-150"
            onClick={handleDelete}
          >
            {t('PARTNER_DASHBOARD.RESOURCES.DELETE_BUTTON')}
          </button>
        </div>
        <div className="tw-absolute -tw-left-[6px] tw-top-4 tw-w-3 tw-h-3 tw-rotate-45 tw-z-50 tw-bg-white" />
      </div>
      )}
      <img src={pdfLogo} alt="" aria-hidden="true" />
      <span className="tw-w-full tw-text-xs tw-text-greyTwo tw-overflow-hidden tw-text-ellipsis tw-line-clamp-2 tw-webkit-line-clamp-2 tw-webkit-box tw-mb-1" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical' }}>
        {resource.file_name}
      </span>
      <span className="tw-w-full tw-text-[10px] tw-text-greyFour tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
        {resource.category_name}
      </span>
    </div>
  );
}

export default ResourceCard;
