import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import crossModal from '../../../../assets/ui/cross-modal.svg';

function DrawerTemplate(props) {
  const { title, drawerVisibility, setDrawerVisibility, className, children, onSubmit, handleSubmit } = props;
  const modalRef = useRef(null);

  const { t } = useTranslation();

  const closeModal = () => {
    setDrawerVisibility(false);
  };

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    document.body.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (drawerVisibility) {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClickOutside);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drawerVisibility]);

  return (
    <td className={className} onClick={(e) => e.stopPropagation()}>
      <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-backdrop-blur-sm tw-bg-backgroundBlur tw-z-40" />

      <div id="category-modal" className="tw-h-full tw-w-full tw-right-0 tw-fixed tw-flex tw-z-40 tw-top-0 tw-overflow-y-auto flex-row-reverse">
        <div ref={modalRef} className="tw-w-full lg:tw-w-1/2 tw-bg-white tw-relative">
          <div className=" tw-h-20 tw-flex tw-justify-between tw-items-center tw-py-7 tw-px-6 tw-border-solid tw-border-0 tw-border-b tw-border-greyBorderLight tw-sticky tw-top-0 tw-bg-white tw-z-40">
            <div>
              <div className="tw-text-xl tw-font-moskauGrotesk tw-font-semibold">
                {title}
              </div>
            </div>
            <button
              type="button"
              className="tw-border-0 tw-bg-white tw-z-50"
              onClick={closeModal}
            >
              <img src={crossModal} alt="Cross" />
            </button>
          </div>
          <div className="tw-flex tw-flex-col tw-pb-12  tw-bg-white">
            <form
              id="labels-modal-form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="tw-flex tw-flex-col  tw-px-10 tw-py-11">
                {children}
              </div>
              <div className="tw-z-20 tw-w-full lg:tw-w-1/2 tw-h-20 tw-px-6 tw-border-solid tw-border-0 tw-border-t tw-border-greyBorderLight tw-flex tw-justify-end tw-fixed  tw-bottom-0 tw-bg-white tw-py-4">
                <button
                  type="button"
                  className="tw-py-3 tw-px-12 tw-text-primaryNewBlue tw-bg-white tw-border-solid tw-border tw-border-primaryNewBlue tw-rounded-[100px]"
                  onClick={closeModal}
                >
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.CANCEL')}
                </button>
                <button
                  type="submit"
                  className="tw-py-3 tw-px-12 tw-text-white tw-bg-primaryNewBlue tw-border-0 tw-rounded-[100px] tw-ml-6"
                >
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBMIT')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </td>
  );
}

export default DrawerTemplate;
