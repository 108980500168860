import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import chevronDown from '../../../../../assets/ui/chevron-down-grey.svg';
import closeIcon from '../../../../../assets/ui/cross-modal.svg';

import CreateTagForm from './CreateTagForm';
import RenderTagDropdown from './RenderTagDropdown';

function RenderTagSection({ partner, handleCancelCreateTag, handleTagCreated, handleCancelEditTag, handleTagEdited, partnerTags, getPartnerTags, tagToEdit, control, selectedTags, handleTagRemoval, isTagDropdownOpen, toggleTagDropdown, tagDropdownRef, tagTriggerRef, handleTagKeyDown, isCreatingTag, isEditingTag, tailwindConfig, hoveredTagId, setHoveredTagId, handleTagSelection, handleCreateTag, handleToggleMenu, openMenuTagId, handleEditTag, handleDeleteTag }) {
  const { t } = useTranslation();

  if (isCreatingTag) {
    return (
      <CreateTagForm
        partner={partner}
        onCancel={handleCancelCreateTag}
        onCreate={handleTagCreated}
        partnerTags={partnerTags}
      />
    );
  }

  if (isEditingTag) {
    return (
      <CreateTagForm
        partner={partner}
        onCancel={handleCancelEditTag}
        onCreate={handleTagEdited}
        partnerTags={partnerTags}
        getPartnerTags={getPartnerTags}
        tagToEdit={tagToEdit}
      />
    );
  }

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-border tw-border-solid tw-border-greySix tw-rounded-[4px] tw-p-4 tw-mt-5">
      <span className="tw-text-xl tw-font-medium tw-text-greyTwo tw-font-moskauGrotesk">{t('TAGS')}</span>
      <span className="tw-text-greyTwo tw-font-moskauGrotesk tw-mt-5">{t('TAGS_DESCRIPTION')}</span>

      <Controller
        name="tags"
        control={control}
        render={() => (
          <div className="tw-flex tw-flex-col tw-mt-[30px]" ref={tagDropdownRef}>
            <label htmlFor="tags" className="tw-block tw-text-base tw-font-moskauGrotesk tw-font-medium tw-text-greyOne tw-mb-2.5">
              {t('TAGS')}
            </label>
            <div
              ref={tagTriggerRef}
              tabIndex="0"
              className={`tw-min-h-[46px] tw-rounded-t-md ${isTagDropdownOpen ? 'tw-rounded-b-none' : 'tw-rounded-b'} tw-rounded-b-none tw-p-2 tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-border tw-border-solid tw-border-greySix focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none`}
              onClick={toggleTagDropdown}
              onKeyDown={handleTagKeyDown}
              role="button"
              aria-haspopup="listbox"
              aria-expanded={isTagDropdownOpen}
            >
              <div className="tw-flex tw-flex-wrap tw-gap-2">
                {selectedTags.length > 0 ? (
                  selectedTags.map((tag, index) => (
                    <div key={`selected-${tag.id}-${index}`} className="tw-flex tw-items-center tw-rounded tw-py-[6px] tw-px-3" style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}>
                      <span className="tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne -tw-mb-[4px]">{tag.name}</span>
                      <button
                        type="button"
                        className="tw-border-none tw-p-0"
                        style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTagRemoval(tag);
                        }}
                      >
                        <img
                          src={closeIcon}
                          alt="remove"
                          className="tw-w-[18px] tw-h-[18px] tw-ml-1 tw-cursor-pointer"
                        />
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="tw-text-greyFour tw-font-moskauGrotesk">{t('TAGS_SELECT_OR_CREATE')}</span>
                )}
              </div>
              <img
                src={chevronDown}
                alt="chevron"
                className={`tw-transition-transform tw-duration-300 ${isTagDropdownOpen ? 'tw-transform tw-rotate-180' : ''}`}
              />
            </div>
            {isTagDropdownOpen && (
              <RenderTagDropdown
                availableTags={partnerTags}
                hoveredTagId={hoveredTagId}
                setHoveredTagId={setHoveredTagId}
                handleTagSelection={handleTagSelection}
                handleCreateTag={handleCreateTag}
                tailwindConfig={tailwindConfig}
                handleToggleMenu={handleToggleMenu}
                openMenuTagId={openMenuTagId}
                handleEditTag={handleEditTag}
                handleDeleteTag={handleDeleteTag}
              />
            )}
          </div>
        )}
      />
    </div>
  );
}

export default RenderTagSection;
