import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Dropdown from './Dropdown';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function Sbti() {
  const { setLoading } = useContext(TableLoadingContext);
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const options = [
    {
      name: 'ONE_POINT_FIVE_DEGREES',
      value: 1,
    },
    {
      name: 'WELL_BELOW_TWO_DEGREES',
      value: 2,
    },
    {
      name: 'TWO_DEGREES',
      value: 3,
    },
    {
      name: 'VALIDATION_ONGOING',
      value: 4,
    },
    {
      name: 'NO_COMMITMENT',
      value: 5,
    },
  ];

  const handleSbtiFilter = (value) => {
    setLoading(true);
    if (value === '') {
      searchParams.delete('sbti_filter');
    } else {
      searchParams.set('sbti_filter', value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('sbti_filter')) {
      setValue(searchParams.get('sbti_filter'));
    } else {
      setValue('');
    }
  }, [searchParams.get('sbti_filter')]);

  return (
    <Dropdown
      name={t('SBT_TARGET_DETAILED')}
      handleChange={handleSbtiFilter}
      options={options}
      value={value}
    />
  );
}

export default Sbti;
