import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import rightArrow from '../../../../assets/ui/right-arrow-m.svg';

import getLabelsRse from '../../../../utils/getLabelsRse';
import getUserLocal from '../../../../utils/getUserLocal';

function LabelsRse({ profile }) {
  const [items, setItems] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    getLabelsRse(profile.id, getUserLocal()).then((response) => {
      setItems(response);
    });
  }, [profile]);

  return (
    items.length > 0 ? (
      <div className="p-3 p-xl-4 white-bg mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
        <h3 className="tw-text-greyOne tw-text-base align-middle">{t('LABELS_CHART_RSE_TITLE')}</h3>
        {items.map((item, index) => {
          if (item.url) {
            return (
              <a key={index} href={item.url} className={`row primary-LLL-hover text-decoration-none font-14 ${items.length !== (index + 1) ? 'py-3 border-bottom' : 'pt-3'}`} target="_blank" rel="noreferrer">
                <div className="col" style={{ lineHeight: '18px' }}>
                  <div className="content-data fw-600 primary-HH-color">
                    {item.name}
                  </div>
                  <div className="content-name grey-L-color pt-2">
                    {item.data}
                  </div>
                </div>
                <div className="col-1 content-url p-0 my-auto">
                  <div className="primary-color text-decoration-none fw-600">
                    <img src={rightArrow} alt="Right arrow" />
                  </div>
                </div>
              </a>
            );
          }
          return (
            <div key={index} className={`row font-14 ${items.length !== (index + 1) ? 'border-bottom py-3' : 'pt-3'}`}>
              <div className="col" style={{ lineHeight: '18px' }}>
                <div className="content-data fw-600 primary-HH-color">
                  {item.name}
                </div>
                <div className="content-name grey-L-color pt-2">
                  {item.data}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : ('')
  );
}

export default LabelsRse;
