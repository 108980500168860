const getVisibility = (category, hasWriteAccess, isPublished) => {
  let show = false;

  category.category[0].questions.forEach((question) => {
    if ((question.answer?.answer && isPublished) || question.is_required || hasWriteAccess) {
      show = true;
    }
  });

  return show;
};

export default getVisibility;
