import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

function SectorInformations({ profile }) {
  const [searchParams] = useSearchParams();
  const [categories, setCategories] = useState(['--']);
  const { t } = useTranslation();
  const MAX_CATEGORIES = 3; // 0, 1, 2 (3 valeurs)
  const selectedCategory = searchParams.get('cf');
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (!selectedCategory) {
      if (profile && profile.categories) {
        const categoriesSorted = profile?.categories?.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        setCategories(categoriesSorted);
      }
    } else {
      const category = profile?.categories.find((category) => category.id === selectedCategory);
      setCategories([category]);
    }
  }, [profile]);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.relatedTarget)) {
      setShowTooltip(false);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-max-w-[33%]">
      <div className="tw-text-sm tw-text-greyFour tw-mb-1">{t('HEADER_LABEL_SECTOR')}</div>
      <div className="tw-relative tw-text-sm tw-text-greyTwo">
        {categories.length <= 1 ? categories[0]?.name : categories.slice(0, MAX_CATEGORIES).map((category, index, array) => (
          <React.Fragment key={category.id}>
            {category.name}
            {index < array.length - 1 && ' | '}
          </React.Fragment>
        ))}
        <span>&nbsp;&nbsp;</span>
        {(categories.length > MAX_CATEGORIES && !selectedCategory) && (
          <span
            className="tw-underline"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            +&nbsp;{parseInt(categories.length, 10) - MAX_CATEGORIES}
          </span>
        )}
        {showTooltip && (
          <ul
            ref={tooltipRef}
            className="tw-absolute tw-left-6 tw-top-10 tw-bg-greyThree tw-py-1 tw-px-2 tw-rounded-sm tw-text-xs tw-text-white tw-z-30 tw-list-none"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {categories.slice(MAX_CATEGORIES, categories.length).map((category) => <li key={category.id}>{` ${category.name}`}</li>)}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SectorInformations;
