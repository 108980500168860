import React from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../Template/TableOfContents/Content';

function TableOfContents({ hasWriteAccess, progressionBar, numberOfQuestions, numberOfAnsweredQuestions, summary, advancedSummary, isPublished, showOptional }) {
  const { t } = useTranslation();

  return (
    <div className="summary bg-white black-color sticky-top tw-rounded tw-border tw-border-solid tw-border-greySix">
      <h3 className="summary-title tw-text-base">
        {t('TABLE_OF_CONTENTS_TITLE')}
      </h3>
      <div className={`${showOptional === true ? 'summary-first-question pb-3' : 'summary-second-section'} mt-3`}>
        {hasWriteAccess && (
          <div className="d-flex justify-content-between pb-3">
            <div className="summary-progression-bar-container position-relative align-self-center">
              <div className="summary-progression-bar position-absolute" />
              <div className="summary-progression-bar-fill  position-absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 font-12">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
        )}
        <h4 className="fw-600 font-14">
          {t('TABLE_OF_CONTENTS_TEXT_2')}
        </h4>
        {summary && summary.map((category, index) => (
          <Content
            key={index}
            category={category}
            hasWriteAccess={hasWriteAccess}
            isPublished={isPublished}
          />
        ))}
      </div>
      {advancedSummary.length > 0 && advancedSummary && showOptional === true ? (
        <div className="summary-second-question mt-3">
          <h4 className="fw-600 font-14 d-flex justify-content-between">
            <span>{t('TABLE_OF_CONTENTS_TEXT_3')}</span>
            {hasWriteAccess && <span className="fw-400 font-12">{t('TABLE_OF_CONTENTS_TEXT_6')}</span>}
          </h4>
          {advancedSummary && advancedSummary.map((category, index) => (
            <Content
              key={index}
              category={category}
              hasWriteAccess={hasWriteAccess}
              isPublished={isPublished}
            />
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default TableOfContents;
